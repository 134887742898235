import React, { useState, useEffect, Suspense } from "react";
import $ from "jquery";
import { apiCalling, awsAPIUrlcommon } from "../apiService";
import Edit from "../image/icons/edit.png";
import tick from "../image/icons/tick.png";
import DoctorDashboardSettingspopup from "./doctorDashboardSettingsPopUp";
import Deactivate from "../image/icons/deactvate.png";
import { useNavigate } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import Pagination from "react-js-pagination";

function DoctorDashboardSettingsList() {
    const navigate = useNavigate();
    const [DoctordashboardSettingsData, setDoctordashboardSettingsData] = useState([]);
    const [showMessage, setShowMessage] = useState("Waiting for data...");
    const [showPopUp, setShowPopUp] = useState(false);
    const [imagePathData, setImagePathData] = useState("");
    const [totalCount, setTotalCount] = useState(0);
    const [showPopUpData, setShowPopUpData] = useState({});
    const [activePage, setActivePage] = useState(1);
    const [max, setMax] = useState(10);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        // imageUploadHandler();
        setImagePathData(
            process.env.REACT_APP_s3BucketRelianceAddressPath + "/" +
            process.env.REACT_APP_RHealthS3bucket + "/" +
            "rhealthfiles" + "/"+"RHADashboard" + "/"
        );
    }, [])

    useEffect(() => {
        journalListing();
        // imageUploadHandler();
    }, [activePage, searchTerm]); // Reload data on page or search change.

    // const imageUploadHandler = () => {
    //     const apiJson = {
    //         method: "POST",
    //         body: JSON.stringify({
    //             functionName: "imagePath",
    //         }),
    //     };

    //     apiCalling(apiJson)
    //         .then((data) => {
    //             if (data.success === "1") {
    //                 const bucketPath =
    //                     data.configPathDetails[0].s3BucketRelianceAddressPath +
    //                     "/" +
    //                     data.configPathDetails[0].S3BucketMainFolder +
    //                     "/" +
    //                     "doctorDashboard" +
    //                     "/";
    //                 setImagePathData(bucketPath);
    //             }
    //         })
    //         .catch((error) => {
    //             console.error("An error occurred while getting image path details");
    //         });
    // };

    const journalListing = () => {
        const offset = (activePage - 1) * max;
        const dataList = {
            method: "POST",
            body: JSON.stringify({
                functionName: "doctorDashboardSettingsList",
                site_id: "",
                // contact_person_name: searchTerm, // Add search parameter
                offset: offset.toString(),
                max: max.toString(),
            }),
        };

        // $(".loader").show();
        awsAPIUrlcommon(dataList)
            .then((data) => {
                // $(".loader").hide();
                
                if (data.success === "1" && data.doctorDashboardSettingsListData.result.length > 0) {
                    setDoctordashboardSettingsData(data.doctorDashboardSettingsListData.result);
                    setTotalCount(data.totalCount);
                } else {
                    setDoctordashboardSettingsData([]);
                    setShowMessage("No Data Found");
                }
            })
            .catch((error) => {
                console.error("An error occurred while fetching the data");
            });
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setActivePage(1); // Reset to first page on new search.
    };

    const doctorDashBoardSetting = (data) => {
        setShowPopUpData(data);
        setShowPopUp(true);
    };

    return (
        <div className="purpleWrap">
            <Suspense fallback={<div>Waiting...</div>}>
                <section>
                    <Header />
                    <div className="loader"></div>
                </section>
            </Suspense>
            <div className="container mb-5 mt-4" id="feedList">
                <h3 className="text-left journlHead"> Doctor Dashboard Listing </h3>
                <div>
                    <ul className="breadcrum-adminMangmnt">
                        <li>
                            <a href={"/admin/doctor-dashboard"}>Dash Board</a>
                        </li>
                        <li>
                            <a href="/#" className="current">
                                Doctor Dashboard Listing
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="col-md-12 pl-0 mb-3">
                    <div className="d-flex justify-content-between">
                        {/* <input
                            type="text"
                            placeholder="Search by Contact Person Name"
                            value={searchTerm}
                            onChange={handleSearch}
                            className="form-control w-50"
                        /> */}
                        <button
                            className="addNew-btn-journel addNew-btn-journel-border"
                            onClick={(e) => {
                                setShowPopUp(true);
                                setShowPopUpData({});
                            }}
                        >
                            Add New Doctor Dashboard
                        </button>
                    </div>
                </div>
                {DoctordashboardSettingsData.length > 0 ? (
                    <div className="table-responsive table-bordered-hsp">
                        <table className="table mb-0">
                            <thead>
                                <tr>
                                    <th className="col-sm-1">Site Name</th>
                                    <th className="col-sm-1">Company Logo</th>
                                    <th className="col-sm-1">Project Logo</th>
                                    <th className="col-sm-2">Contact Person</th>
                                    <th className="col-sm-2">Email</th>
                                    <th className="col-sm-2">Mobile</th>
                                    <th className="col-sm-1">Purple Health Logo Status</th>
                                    <th className="col-sm-1">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {DoctordashboardSettingsData.map((data) => (
                                    <tr key={data.id}>
                                        <td>{data.site_name}</td>
                                        <td>
                                            {data.company_logo ? (
                                                <img
                                                    src={imagePathData+data?.company_logo}
                                                    alt="Company Logo"
                                                    width="50"
                                                />
                                            ) : (
                                                "No Logo"
                                            )}
                                        </td>
                                        <td>
                                            {data.project_logo ? (
                                                <img
                                                    src={imagePathData+data?.project_logo}
                                                    alt="Project Logo"
                                                    width="50"
                                                />
                                            ) : (
                                                "No Logo"
                                            )}
                                        </td>
                                        <td>{data.contact_person_name}</td>
                                        <td>{data.contact_person_email}</td>
                                        <td>{data.contact_person_mobile}</td>
                                        <td>
                                            <div className="row m-0">
                                                <div className="col-sm-6">
                                                    {data.purple_logo_required ? (
                                                        <img
                                                            src={tick}
                                                            width="22"
                                                            title="Purple Health logo Required"
                                                            className="mr-2 conf-cursorpointer"
                                                            alt="Activate"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={Deactivate}
                                                            alt="Deactivate"
                                                            title="Purple Health logo Not Required"
                                                            className="mr-2 conf-cursorpointer"
                                                            width="20"
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="row m-0">
                                                <div className="col-sm-6">
                                                    <img
                                                        onClick={(e) => doctorDashBoardSetting(data)}
                                                        src={Edit}
                                                        className="img-fluid"
                                                        width="20px"
                                                        alt="Edit"
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <p className="error-msg text-center mb-0">{showMessage}</p>
                )}
            </div>
            {showPopUp ? (
                <DoctorDashboardSettingspopup
                    cancelPopup={setShowPopUp}
                    journalListing={journalListing}
                    showPopUpData={showPopUpData}
                />
            ) : (
                ""
            )}
            {totalCount > max && (
                <div className="paginationSection orderm-paginationSection pagination-HspList pagination-HspListwidth p-3">
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={max}
                        totalItemsCount={totalCount}
                        pageRangeDisplayed={10}
                        onChange={handlePageChange}
                    />
                </div>
            )}
            <Suspense fallback={<div></div>}>
                <section>
                    <Footer />
                </section>
            </Suspense>
        </div>
    );
}

export default DoctorDashboardSettingsList;
