import React, { Component } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import leftarrow from "../image/media/left-arrow-hsp.png";
import { apiCalling } from "../apiService";
var mapKey = "";
export default class DashboardAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    window.localStorage.setItem("pageNumber", 1);
    window.localStorage.setItem("offset", 0);
  }
  componentDidMount() {
    this.getMapKey();
  }
  getMapKey() {
    const apiData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "imagePath",
      }),
    };
    apiCalling(apiData).then((data) => {
      if (data.success === "1") {
        mapKey = data.configPathDetails[0].googleMapKey;
        window.localStorage.setItem("googleMapKey", mapKey);
      }
    });
  }
  DoctorOffsetPageNumberReset = () => {
    window.localStorage.setItem("doctorpageNumber", 1);
    window.localStorage.setItem("Doctoroffset", 0);
  };
  HospitalOffsetPageNumberReset = () => {
    window.localStorage.setItem("hospitalpageNumber", 1);
    window.localStorage.setItem("hospitaloffset", 0);
  };

  render() {
    return (
      <div>
        <Header></Header>
        <div className="dashboardAdminOuter">
          <div className="container">
            <div className="backArowdImgdiv">
              <a href="/admin/dashboard">
                <img src={leftarrow} alt="leftarrow" width="18" />
                <span className="ml-2">Back</span>
              </a>
            </div>
            <div className="sectionItemDiv-dashboard">
              <div className="adminMangemnt-dashboard">
                <div
                  className="admin-dc-dashBtns"
                  onClick={() => this.DoctorOffsetPageNumberReset()}
                >
                  <a href="/adminManagement/doctorManagementList/doctorManagementDashboard">
                    <div>Doctor Management</div>
                  </a>
                </div>
                <div
                  className="admin-dc-dashBtns"
                  onClick={() => this.HospitalOffsetPageNumberReset()}
                >
                  <a href="/adminManagement/hospitalManagementList/hospitalManagementList">
                    <div>Hospital Management</div>
                  </a>
                </div>
                <div className="admin-dc-dashBtns">
                  <a href="/adminManagement/smsSettings/sms-settings">
                    <div>SMS Settings</div>
                  </a>
                </div>
                <div className="admin-dc-dashBtns">
                  <a href="/adminManagement/SiteSettings">
                    <div>Site Settings</div>
                  </a>
                </div>
                <div className="admin-dc-dashBtns">
                  <a href="/adminManagement/messaging">
                    <div>Messaging</div>
                  </a>
                </div>
                <div className="admin-dc-dashBtns">
                  <a href="/adminDashboard/doctorWorkdashboard">
                    <div>Doctor Work</div>
                  </a>
                </div>
                <div className="admin-dc-dashBtns">
                  <a href="/adminManagement/medicineBranch">
                    <div>Medicine Branch</div>
                  </a>
                </div>
                <div className="admin-dc-dashBtns">
                  <a href="/adminDashboard/pharmaDashboard">
                    <div>Pharma Ad Module</div>
                  </a>
                </div>
                <div className="admin-dc-dashBtns">
                  <a href="/adminDashboard/mobileappdashboard">
                    <div>Mobile App Dashboard</div>
                  </a>
                </div>
                <div className="admin-dc-dashBtns">
                  <a href="/adminManagement/healthInformation">
                    <div>Health Information</div>
                  </a>
                </div>
                <div className="admin-dc-dashBtns">
                  <a href="/adminManagement/productDashboard">
                    <div>Product</div>
                  </a>
                </div>
                <div className="admin-dc-dashBtns">
                  <a href="/adminManagement/manageLocations">
                    <div>Manage Locations</div>
                  </a>
                </div>
                <div className="admin-dc-dashBtns">
                  <a href="/adminManagement/analyticsManagement/analyticsList">
                    <div> Analytics</div>
                  </a>
                </div>
                <div className="admin-dc-dashBtns">
                  <a href="/adminManagement/imageUpload">
                    <div> Image Upload </div>
                  </a>
                </div>
                <div className="admin-dc-dashBtns">
                  <a href="/adminManagement/reports">
                    <div> Reports </div>
                  </a>
                </div>
                <div className="admin-dc-dashBtns">
                  <a href="/adminManagement/patientReports">
                    <div>Patient Reports upload </div>
                  </a>
                </div>
                <div className="admin-dc-dashBtns">
                  <a href="/adminManagement/doctorRegistrationReportThroughApp">
                    <div>Doctor Registration Report Through App</div>
                  </a>
                </div>
                <div className="admin-dc-dashBtns">
                  <a href="/adminManagement/pollList">
                    <div>Poll List</div>
                  </a>
                </div>
                <div className="admin-dc-dashBtns">
                  <a href=" /adminManagement/doctorLoginReport">
                    <div>Doctor Login Details</div>
                  </a>
                </div>
                <div className="admin-dc-dashBtns">
                  <a href=" /adminManagement/websiteanalyticsofDoctors">
                    <div>Website Analytics of Doctors</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}
