import React, { useEffect, Suspense, useState } from "react";
import { useParams } from "react-router-dom";
import $ from "jquery";
import { apiCalling } from "../../apiService";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import { uploadToS3 } from "../../redux/actions/UploadFile";
import Logoimg from "../../image/icons/image-preview.png";
import AWS from "aws-sdk";
var s3;
var imageFile;

const RepAdd = () => {
  const { repId } = useParams();
  const [pharmaResult, setpharmaResult] = useState([]);
  const [mobileNumber, setmobileNumber] = useState('');
  const [emailIdTxt, setemailIdTxt] = useState('');
  const [nameTxt, setnameTxt] = useState('');
  const [descriptionTxt, setdescriptionTxt] = useState('');
  const [companyId, setcompanyId] = useState('');
  const [secUserId, setsecUserId] = useState('');
  // const [s3bucketAccessKey, sets3bucketAccessKey] = useState('');
  // const [AWSAccessKeyId, setAWSAccessKeyId] = useState('');
  // const [region, setregion] = useState("us-east-1");
  // const [bucket, setbucket] = useState('');
  // const [bucketFolder, setbucketFolder] = useState('');
  // const [pharmaImagePath, setpharmaImagePath] = useState('');
  const [fileNameOnly, setfileNameOnly] = useState('');
  const [fileNameWithPath, setfileNameWithPath] = useState('');
  const [state, setState] = useState({
    emailIdTxt: "",
    nameTxt: "",
    companyType: "",
    descriptionTxt: ""
  });

  useEffect(() => {
    // imageUploadHandler();
    companyDetails();
    if (repId) {
      editApprovalResult();
    }
  }, []);

  const handleChange = (event) => {
    const { value, name } = event.target;
    // setState({
    //   [name]: value,
    // });
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const textType = (e) => {
    var code = "charCode" in e ? e.charCode : e.keyCode;
    if (!(code > 47 && code < 58)) {
      // lower alpha (a-z)
      alert("Only Numbers Accepted");
      e.preventDefault();
    }
  }

  const numberHandleChange = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setmobileNumber(event.target.value);
    }
  }

  const companyDetails = () => {
    const companyList = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getCompanyData",
      }),
    };
    apiCalling(companyList).then((data) => {
      if (data.success === "1") {
        setpharmaResult(data.pharmaCompanyData);
      }
    });
  }

  const editApprovalResult = () => {
    const approvalData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "editRepApproval",
        repId: repId,
      }),
    };
    $(".loader").show();
    apiCalling(approvalData).then((data) => {
      if (data.success === "1") {
        setmobileNumber(data.repApprovalData[0].phoneNumber);
        setemailIdTxt(data.repApprovalData[0].emailId);
        setnameTxt(data.repApprovalData[0].repName);
        setcompanyId(data.repApprovalData[0].company);
        setsecUserId(data.repApprovalData[0].secUserId);
        setdescriptionTxt(data.repApprovalData[0].profileDescription);
        setfileNameOnly(data.repApprovalData[0].pharmaLogoOnly);
        setfileNameWithPath(data.repApprovalData[0].pharmaLogoWithPath);

        if (data.repApprovalData[0].hasWritePermission === true) {
          $("#PermissionStatus").prop("checked", true);
        } else {
          $("#PermissionStatus").prop("checked", false);
        }
      }
      $(".loader").hide();
    });
  }

  const validateForm = () => {
    let companyId = $("#companyType").val();
    let emailid = $("#emailIdTxt").val();
    let name = $("#nameTxt").val();
    if (mobileNumber === "") {
      alert("Phone Number Required");
      $("#mobileNumber").focus();
    } else if (!mobileNumber.match(/^[0-9.\b]{10}$/)) {
      alert("Please enter valid mobile number");
      $("#mobileNumber").focus();
    } else if (emailid.length <= 0) {
      alert("Email Id Required");
      $("#emailIdTxt").focus();
      return false;
    } else if (!emailid.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
    ) {
      alert("Please Enter Valid Email Id");
      $("#emailIdTxt").focus();
    } else if (name.length <= 0) {
      alert("Rep Name Required");
      $("#nameTxt").focus();
      return false;
    } else if (companyId === "") {
      alert("Company Name Required");
      $("#companyType").focus();
      return false;
    } else if (fileNameWithPath === "") {
      alert("Image required.");
      return false;
    } else {
      return true;
    }
  }

  const saveHandle = () => {
    if (validateForm()) {
      let companyId = $("#companyType").val();
      let hasWritePermission =
        $("#PermissionStatus").is(":checked") === true ? "true" : "false";
      const saveField = {
        method: "POST",
        body: JSON.stringify({
          functionName: "saveRep",
          repId:
            repId === "null"
              ? ""
              : repId,
          secUserId: secUserId,
          phoneNumber: mobileNumber,
          emailId: state.emailIdTxt ? state.emailIdTxt : emailIdTxt,
          repName: state.nameTxt ? state.nameTxt : nameTxt,
          companyId: companyId,
          hasWritePermission: hasWritePermission,
          profileDescription: state.descriptionTxt ? state.descriptionTxt : descriptionTxt,
          pharmaLogo: fileNameOnly,
        }),
      };
      $(".loader").show();
      apiCalling(saveField).then((data) => {
        $(".loader").hide();
        if (data.success === "1") {
          let message =
            repId > 0
              ? "Updated successfully "
              : "Save successfully";
          alert(message);
          cancelHandle();
        } else if (data.success === "0") {
          alert(data.errorMessage);
        }
      });
    }
  };

  // const imageUploadHandler = () => {
  //   $(".loader").show();
  //   const apiJson = {
  //     method: "POST",
  //     body: JSON.stringify({
  //       functionName: "imagePath",
  //       siteId: "",
  //     }),
  //   };
  //   apiCalling(apiJson).then((data) => {
  //     if (data.success === "1") {
  //       $(".loader").hide();
  //       sets3bucketAccessKey(data.configPathDetails[0].s3bucketAccessKey);
  //       setAWSAccessKeyId(data.configPathDetails[0].s3bucketSecretKey);
  //       setbucket(data.configPathDetails[0].PurpleHealthS3bucket);
  //       setbucketFolder(data.configPathDetails[0].S3BucketMainFolder);
  //       setpharmaImagePath(data.configPathDetails[0].pharmaImagePath);
  //     }
  //   });
  // }

  const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  const fileChange = (e) => {
    imageFile = e.target.files[0];
    if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
      alert("Add file format with only JPG,JPEG,PNG format");
      return false;
    }
    if (imageFile.size > 3145728) {
      alert("File size must under 3 MB!");
      return false;
    } else {
      $(".loader").show();
      var fileObj = e.target.files[0];
      setfileNameWithPath(window.URL.createObjectURL(fileObj));
      fileUpload(imageFile);
    }
  };

  const fileUpload = async (file) => {
    let fileName = uuidv4() + file.name;
    imageFile = fileName;
    const s3Url = await uploadToS3(file,`${process.env.REACT_APP_S3BucketMainFolder}/pharmaImages`,fileName.replaceAll(/ /g,""));
    if (s3Url) {
      setfileNameWithPath(s3Url)
      setfileNameOnly(fileName);
      return null;
    } else { alert("There was an error uploading your photo") }
    $(".loader").hide();
    // AWS.config.update({
    //   accessKeyId: s3bucketAccessKey,
    //   secretAccessKey: AWSAccessKeyId,
    //   region:region,
    // });
    // var bucketParams = {
    //   Bucket:
    //     bucket +
    //     "/" +
    //     bucketFolder +
    //     "/" +
    //     pharmaImagePath,
    // };
    // s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    // let S3BucketMainFolder = "";
    // let fileName = uuidv4() + file.name;
    // let fullFilePath = S3BucketMainFolder + fileName;
    // imageFile = fileName;
    // s3.upload(
    //   {
    //     Key: fullFilePath,
    //     Body: file,
    //     ACL: "public-read",
    //   },
    //   (err, data) => {
    //     if (err) {
    //       alert("There was an error uploading your photo: ", err.message);
    //       $(".loader").hide();
    //     } else if (data) {
    //       $(".loader").hide();
    //       setfileNameWithPath(data.Location);
    //         setfileNameOnly(fileName);
    //       return null;
    //     }
    //   }
    // );
  }

  const cancelHandle = () => {
    window.location.href =
      "/adminManagement/pharmadashboardList/repApprovalList";
  }

  const srcTypeImage = (ev) => {
    ev.target.src = Logoimg;
  }

  return (
    <div className="purpleWrap bg-clr-wthHead">
      <Suspense fallback={<div></div>}>
        <section>
          <Header /> <div className="loader"></div>
        </section>
      </Suspense>
      <div className="container">
        <div>
          <ul className="breadcrum-adminMangmnt">
            <li>
              <a href="/admin/dashboardAdmin">Dashboard</a>
            </li>
            <li>
              <a href="/adminDashboard/pharmaDashboard">Pharma Ad Approval</a>
            </li>
            <li>
              <a href="" className="current">
                Rep Approval List
              </a>
            </li>
          </ul>
        </div>
        <div className="addrepwrapperborder">
          <div className="addrepOuter">
            <div className="addrep-contentdiv">
              <label>
                Phone No.<span className="hspifoMantory">*</span>
              </label>
              <input
                type="text"
                className="addrepfieldinput"
                id="mobileNumber"
                maxLength="10"
                name="mobileNumber"
                value={mobileNumber}
                placeholder="Phone No."
                onChange={numberHandleChange}
                onKeyPress={textType}
              />
            </div>
            <div className="addrep-contentdiv">
              <label>
                Email Id<span className="hspifoMantory">*</span>
              </label>
              <input
                type="text"
                className="addrepfieldinput"
                placeholder="Email Id"
                id="emailIdTxt"
                name="emailIdTxt"
                value={state.emailIdTxt ? state.emailIdTxt : emailIdTxt}
                onChange={handleChange}
              />
            </div>
            <div className="addrep-contentdiv">
              <label>
                Rep Name<span className="hspifoMantory">*</span>
              </label>
              <input
                type="text"
                className="addrepfieldinput"
                placeholder="Rep Name"
                id="nameTxt"
                name="nameTxt"
                value={state.nameTxt ? state.nameTxt : nameTxt}
                onChange={handleChange}
              />
            </div>
            <div className="addrep-contentdiv">
              <label>
                Company<span className="hspifoMantory">*</span>
              </label>
              <select
                id="companyType"
                name="companyType"
                onChange={handleChange}
                className="addrepfieldinput"
              >
                <option value="">Select Company</option>
                {pharmaResult.map((obj) =>
                  obj.companyId === companyId ? (
                    <option
                      key={obj.companyId}
                      value={obj.companyId}
                      selected
                    >
                      {obj.companyName}
                    </option>
                  ) : (
                    <option value={obj.companyId}>{obj.companyName}</option>
                  )
                )}
              </select>
            </div>
            <div className="addrep-contentdiv addrep-contentdivmob">
              <label>Has Write Permission</label>
              <input
                type="checkbox"
                className="mt-2"
                id="PermissionStatus"
                name="PermissionStatus"
              />
            </div>

            <div className="addrep-contentdiv">
              <label>Profile Description</label>
              <input
                type="text"
                className="addrepfieldinput"
                placeholder="Profile Description"
                id="descriptionTxt"
                name="descriptionTxt"
                value={state.descriptionTxt ? state.descriptionTxt : descriptionTxt}
                onChange={handleChange}
              />
            </div>
            <div className="addrep-contentdiv">
              <label>
                Image<span className="hspifoMantory">*</span>
              </label>
              <div className="hspinfoimgaeuploadDiv addrepimgdivmob">
                <div className="hspinfo-btnupload">
                  <span>Upload</span>
                  <input
                    type="file"
                    className="hspinfoInputfieldupload"
                    name="fileText"
                    id="fileText"
                    onChange={(e) => {
                      fileChange(e);
                      e.target.value = "";
                    }}
                  />
                </div>
                {fileNameWithPath.length > 0 ? (
                  <div className="fileUpload-img fileUpload-imgreptop">
                    <img
                      id="fileNameWithPath"
                      onError={srcTypeImage}
                      src={fileNameWithPath}
                      width="50"
                    ></img>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="smssetng-btn addrep-btn">
            <input
              type="submit"
              value={repId > 0 ? "Update" : "Save"}
              className="smssetng-svebtn"
              id="saveType"
              onClick={saveHandle}
            />
            <input
              type="submit"
              value="Cancel"
              className="smssetng-cancelbtn"
              onClick={() => cancelHandle()}
            />
          </div>
        </div>
      </div>
      <Suspense fallback={<div></div>}>
        <section>
          <Footer />
        </section>
      </Suspense>
    </div>
  );
}

export default RepAdd;