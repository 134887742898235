import React, { Component } from 'react';

export default class DateRangePopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: '',
            endDate: ''
        };
    }

    getCurrentMonth = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        return `${year}-${month}`;
    }

    handleDateChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        });
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const { startDate, endDate } = this.state;
        await this.props.onConfirm({ startDate, endDate });
        this.setState({
            startDate: '',
            endDate: ''
        });
    }

    handleClose = () => {
        this.setState({
            startDate: '',
            endDate: ''
        }, () => {
            this.props.onClose();
        });
    }

    resetDates = () => {
        this.setState({
            startDate: '',
            endDate: ''
        });
    }

    render() {
        const { isOpen } = this.props;
        const { startDate, endDate } = this.state;
        const currentMonth = this.getCurrentMonth();

        if (!isOpen) return null;

        return (
            <div className="popup-overlay" style={{ display: "block" }}>
                <div className="modal-dialog" style={{ maxWidth: "400px", minHeight: "450px" }} role="document">
                    <div className="modal-content">
                        <div className="modal-header modal-popup">
                            <h5 className="modal-title">Month-Year Range</h5>
                        </div>

                        <div className="content p-4">
                            <form onSubmit={this.handleSubmit}>
                                <div className="row">
                                    <div className="col-12 mb-4">
                                        <label className="label-text" htmlFor="startDate">
                                            From Month
                                        </label>
                                        <input
                                            type="month"
                                            id="startDate"
                                            name="startDate"
                                            className="input-design"
                                            value={startDate}
                                            onChange={this.handleDateChange}
                                            max={currentMonth}
                                            required
                                        />
                                    </div>

                                    <div className="col-12 mb-4">
                                        <label className="label-text" htmlFor="endDate">
                                            To Month
                                        </label>
                                        <input
                                            type="month"
                                            id="endDate"
                                            name="endDate"
                                            className="input-design"
                                            value={endDate}
                                            min={startDate}
                                            max={currentMonth}
                                            onChange={this.handleDateChange}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="text-center mt-4">
                                    <button
                                        type="submit"
                                        className="sitesetng-svebtn"
                                    >
                                        Download
                                    </button>
                                    <button
                                        type="button"
                                        className="sitesetng-cancelbtn"
                                        onClick={this.handleClose}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}