import React, { Component, Suspense, useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import $ from "jquery";
import Header from "../../layout/header"
import Footer from "../../layout/footer"
import moment from "moment";
import { apiCalling } from "../../apiService";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Logoimg from "../../image/icons/image-preview.png";
import AWS from "aws-sdk";
import { uploadToS3 } from "../../redux/actions/UploadFile";
var s3;
var imageFile;
const Addbanner = () => {
    const { hospitalbannerId } = useParams();
    const [txtTitle, settxtTitle] = useState('');
    const [txtName, settxtName] = useState('');
    const [hospitalId, sethospitalId] = useState('');
    const [txtDescription, settxtDescription] = useState('');
    const [txtRank, settxtRank] = useState('');
    const [s3bucketAccessKey, sets3bucketAccessKey] = useState('');
    const [AWSAccessKeyId, setAWSAccessKeyId] = useState('');
    const [hospitalData, sethospitalData] = useState([]);
    const [region, setregion] = useState("us-east-1");
    const [bucket, setbucket] = useState('');
    const [bucketFolder, setbucketFolder] = useState('');
    const [hospitalImagesS3, sethospitalImagesS3] = useState("us-east-1");
    const [fileNameOnly, setfileNameOnly] = useState('');
    const [fileNameWithPath, setfileNameWithPath] = useState('');
    const [formData, setFormData] = useState({
        txtTitle: '',
        txtName: '',
        txtDescription: '',
        txtRank: ''
    });


    const handleChange = (event) => {
        const { value, name } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    }

    useEffect(() => {
        hospitalList();
        imageUploadHandler();
        if (hospitalbannerId) {
            editHospitalBannerResult();
        }
    }, []);

    const hospitalList = () => {

        const getDetails = {
            method: "POST",
            body: JSON.stringify({
                functionName: "getHospitalList"
            }),
        }
        apiCalling(getDetails).then((data) => {
            if (data.success === "1") {
                sethospitalData(data.HospitalData);
            }
        });
    }

    const editHospitalBannerResult = () => {

        const approvalData = {
            method: "POST",
            body: JSON.stringify({
                functionName: "hospitalBannerUploadDetails",
                hospitalBannerId: hospitalbannerId,
            }),
        };
        $(".loader").show();
        apiCalling(approvalData).then((data) => {
            if (data.success === "1") {
                let bannerFromDate = moment(data.hospitalBannerData[0].fromDate, "DD-MM-YYYY").format("YYYY-MM-DD");
                let bannerToDate = moment(data.hospitalBannerData[0].toDate, "DD-MM-YYYY").format("YYYY-MM-DD");

                settxtName(data.hospitalBannerData[0].bannerName)
                sethospitalId(data.hospitalBannerData[0].hospitalId);
                settxtTitle(data.hospitalBannerData[0].title);
                settxtDescription(data.hospitalBannerData[0].description);
                settxtRank(data.hospitalBannerData[0].rank);
                setfileNameWithPath(data.hospitalBannerData[0].imageFullpath);
                //  console.log('fileNameWithPath',fileNameWithPath);
                setfileNameOnly(data.hospitalBannerData[0].imageNameOnly);
                $("#bannerFromDate").val(bannerFromDate)
                $("#bannerToDate").val(bannerToDate)
                if (data.hospitalBannerData[0].isappbanner === true) {
                    $("#chkIsApp").prop('checked', true);
                }
                else {
                    $("#chkIsApp").prop('checked', false);
                }
                if (data.hospitalBannerData[0].isActive === true) {
                    $("#chkIsActive").prop('checked', true);
                }
                else {
                    $("#chkIsActive").prop('checked', false);
                }
            }
            $(".loader").hide();
        });
    }


    const validateForm = () => {

        let txtHospital = $("#txtHospital").val();
        let txtDescription = $("#txtDescription").val();
        let txtTitle = $("#txtTitle").val();
        let txtName = $("#txtName").val();
        let txtRank = $('#txtRank').val();
        if (txtTitle === "") {
            alert("Enter Title ");
            $("#txtTitle").focus();
        }
        else if (txtName === "") {
            alert("Enter Name ");
            $("#txtName").focus();
            return false;
        }
        else if (txtHospital === "") {
            alert("Select Hospital ");
            $("#txtHospital").focus();
            return false;
        }
        else if (txtDescription === "") {
            alert("Enter Description ");
            $("#txtDescription").focus();
            return false;
        }
        else if (txtRank === "") {
            alert("Enter Rank  ");
            $("#txtRank").focus();
            return false;
        } else if (fileNameWithPath === "") {
            alert("Upload Image");
            return false;
        } else {
            return true;
        }
    }

    const saveHandle = () => {

        if (validateForm()) {

            let txtHospital = $("#txtHospital").val();
            let isAppBanner = ($("#chkIsApp").is(":checked") === true ? '1' : '0')
            let isActive = ($("#chkIsActive").is(":checked") === true ? '1' : '0')
            let fromdate = moment($("#bannerFromDate").val(), "YYYY-MM-DD").format("DD/MM/YYYY");
            let todate = moment($("#bannerToDate").val(), "YYYY-MM-DD").format("DD/MM/YYYY");
            const saveField = {
                method: "POST",
                body: JSON.stringify({
                    functionName: "hospitalBannerUploadAddUpdate",
                    hospitalBannerId: hospitalbannerId === "null" ? "" : hospitalbannerId,
                    fromdate: fromdate,
                    todate: todate,
                    description: formData.txtDescription ? formData.txtDescription : txtDescription,
                    imagePath: fileNameOnly,
                    isAppBanner: isAppBanner,
                    isActive: isActive,
                    name: formData.txtName ? formData.txtName : txtName,
                    rank: formData.txtRank ? formData.txtRank : txtRank,
                    title: formData.txtTitle ? formData.txtTitle : txtTitle,
                    hospitalId: txtHospital
                }),
            };
            $(".loader").show();
            apiCalling(saveField).then((data) => {
                $(".loader").hide();
                if (data.success === "1") {
                    let message =
                        hospitalbannerId > 0
                            ? "Updated successfully "
                            : "Save successfully";
                    alert(message);
                    cancelHandle();
                } else if (data.success === "0") {
                    alert(data.errorMessage);
                }
            });
        }
    };

    const imageUploadHandler = () => {
        $(".loader").show();
        const apiJson = {
            method: "POST",
            body: JSON.stringify({
                functionName: "imagePath",
                siteId: "",
            }),
        };
        apiCalling(apiJson).then((data) => {
            console.log(data);
            if (data.success === "1") {
                $(".loader").hide();
                sets3bucketAccessKey(data.configPathDetails[0].s3bucketAccessKey);
                setAWSAccessKeyId(data.configPathDetails[0].s3bucketSecretKey);
                setbucket(data.configPathDetails[0].PurpleHealthS3bucket);
                setbucketFolder(data.configPathDetails[0].S3BucketMainFolder);
                sethospitalImagesS3(data.configPathDetails[0].S3BucketHospitalImages);
            }
        });
    }

    const uuidv4 = () => {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
            /[xy]/g,
            function (c) {
                var r = (Math.random() * 16) | 0,
                    v = c == "x" ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            }
        );
    };

    const fileChange = (e) => {
        imageFile = e.target.files[0];
        if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
            alert("Add file format with only JPG,JPEG,PNG format");
            return false;
        }
        if (imageFile.size > 3145728) {
            alert("File size must under 3 MB!");
            return false;
        } else {
            var fileObj = e.target.files[0];
            setfileNameWithPath(window.URL.createObjectURL(fileObj));
            fileUpload(imageFile);
        }
    };

    const fileUpload = async (file) => {
        // AWS.config.update({
        //     accessKeyId: s3bucketAccessKey,
        //     secretAccessKey:AWSAccessKeyId,
        //     region:region,
        // });
        // var bucketParams = {
        //     Bucket:
        //         bucket +
        //         "/" +
        //        bucketFolder +
        //         "/" +
        //         hospitalImagesS3,
        // };
        // s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
        // let S3BucketMainFolder = "";
        let fileName = uuidv4() + file.name;
        // let fullFilePath = S3BucketMainFolder + fileName;
        imageFile = fileName;
        const s3Url = await uploadToS3(file, `${process.env.REACT_APP_S3BucketMainFolder}/pharmaImages`, fileName.replaceAll(/ /g,""));
        if (s3Url) {
            $(".loader").show();
            setfileNameWithPath(s3Url)
            setfileNameOnly(fileName);
            return null;
        } else {
            $(".loader").show();
            alert("There was an error uploading your photo")
        }
        // s3.upload(
        //     {
        //         Key: fullFilePath,
        //         Body: file,
        //         ACL: "public-read",
        //     },
        //     (err, data) => {
        //         if (err) {
        //             alert("There was an error uploading your photo: ", err.message);
        //         } else if (data) {
        //             setfileNameWithPath(data.Location)
        //             setfileNameOnly(fileName);
        //             return null;
        //         }
        //     }
        // );
    }

    const cancelHandle = () => {
        window.location.href = "/adminManagement/mobileAppdashboard/hospitalbannerdetails";
    }

    const srcTypeImage = (ev) => {
        ev.target.src = Logoimg;
    }


    return (
        <div className="purpleWrap bg-clr-wthHead">
            <Suspense fallback={<div></div>}>
                <section>
                    <Header />{" "}
                    <div className='loader'></div>
                </section>
            </Suspense>
            <div className="container">
                <div>
                    <ul className="breadcrum-adminMangmnt">
                        <li><a href="/admin/dashboardAdmin">Dashboard</a></li>
                        <li><a href="/adminDashboard/mobileappdashboard">Hospital Banner Listing</a></li>
                        <li><a href="" className="current">Hospital Details</a></li>
                    </ul>
                </div>
                <div className="hspbannerdetlPage-Wrapper">
                    <Tabs>
                        <TabList>
                            <Tab>Banner Detail</Tab>
                        </TabList>
                        <TabPanel>
                            <div className="banerdetlwrapperborder">
                                <div className="addbanerhspOuterDiv">
                                    <div className="bannerdetlhead-hsp">
                                        <h2>Add Hospital Banner</h2>
                                    </div>
                                    <div className="bannerhspcontent-wraper">
                                        <div className="addbannerhsp-contentdiv ">
                                            <label>Title<span className="hspifoMantory">*</span></label>
                                            <input type="text" id="txtTitle" name="txtTitle" value={formData.txtTitle ? formData.txtTitle : txtTitle} className="addbanerhspfieldinput" placeholder="Title" onChange={handleChange} />
                                        </div>
                                        <div className="addbannerhsp-contentdiv">
                                            <label>Name<span className="hspifoMantory">*</span></label>
                                            <input type="text" id="txtName" name="txtName" value={formData.txtName ? formData.txtName : txtName} className="addbanerhspfieldinput" placeholder="Name" onChange={handleChange} />
                                        </div>

                                        <div className="addbannerhsp-contentdiv">
                                            <label>Hospital<span className="hspifoMantory">*</span></label>
                                            <select name="txtHospital" id="txtHospital" className="addbanerhspfieldinput" onChange={handleChange}>
                                                <option value="">Hospital</option>
                                                {hospitalData.map((obj) =>
                                                    obj.hospitalId === hospitalId ? (
                                                        <option key={obj.hospitalId} value={obj.hospitalId} selected>
                                                            {obj.hospitalName}
                                                        </option>
                                                    ) : (
                                                        <option value={obj.hospitalId}>{obj.hospitalName}</option>
                                                    )
                                                )}
                                            </select>
                                        </div>
                                        <div className="addbannerhsp-contentdiv addbannerhsp-contentdiv-upload">
                                            <label>Image<span className="hspifoMantory">*</span></label>
                                            <div className="addbanr-hspimgaeuploadDiv">
                                                <div className="hspaddbanr-btnupload">
                                                    <span>Upload</span>
                                                    <input type="file" name="fileText" id="fileText" onChange={fileChange} className="hspinfoInputfieldupload" />
                                                </div>
                                            </div>
                                            <div>
                                                {fileNameWithPath.length > 0 ?

                                                    <div className="fileUpload-img fileUpload-imgreptop uplod-imge-inlinebaner">
                                                        <img
                                                            id="fileNameWithPath"
                                                            onError={srcTypeImage}
                                                            src={fileNameWithPath}
                                                            // id="blogImage"
                                                            width="50"
                                                        ></img>
                                                    </div>
                                                    : ""}
                                            </div>
                                        </div>
                                        <div className="addbannerhsp-contentdiv addbannerhsp-contentdiv-clear">
                                            <label>Description<span className="hspifoMantory">*</span></label>
                                            <input type="text" id="txtDescription" name="txtDescription" value={formData.txtDescription ? formData.txtDescription : txtDescription} className="addbanerhspfieldinput" placeholder="Description" onChange={handleChange} />
                                        </div>
                                        <div className="addbannerhsp-contentdiv">
                                            <label>Rank<span className="hspifoMantory">*</span></label>
                                            <input type="text" id="txtRank" name="txtRank" value={formData.txtRank ? formData.txtRank : txtRank} className="addbanerhspfieldinput" placeholder="Rank" onChange={handleChange} />
                                        </div>
                                        <div className="addbannerhsp-contentdiv">
                                            <label className="pt-0">From Date</label>
                                            <input type="date" id="bannerFromDate" name="bannerFromDate" onChange={handleChange} className="addbanerhspfieldinput text-uppercase" />
                                        </div>
                                        <div className="addbannerhsp-contentdiv">
                                            <label className="pt-0">To Date</label>
                                            <input type="date" id="bannerToDate" name="bannerToDate" onChange={handleChange} className="addbanerhspfieldinput text-uppercase" />

                                        </div>
                                        <div className="superadminDivBoxcover">
                                            <div className="addbannerhsp-contentdiv addbannerhsp-contentdiv-check">
                                                <div className="doctrcheckboxDivhsp hspbnradddiv-mob">
                                                    <input type="checkbox" name="chkIsApp" id="chkIsApp" />
                                                    <label className="pt-0">Is App</label>
                                                </div>
                                                <div className="doctrcheckboxDivhsp hspbnradddiv-mob">
                                                    <input type="checkbox" name="chkIsActive" id="chkIsActive" />
                                                    <label className="pt-0">Is Active</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="smssetng-btn addrep-btn">
                                            <input type="submit" value={hospitalbannerId > 0 ? "Update" : "Save"} className="smssetng-svebtn" onClick={saveHandle} />
                                            <input type="submit" value="Cancel" className="smssetng-cancelbtn" onClick={() => cancelHandle()} />
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </TabPanel>
                    </Tabs>
                </div>

            </div>
            <Suspense fallback={<div></div>}>
                <section>
                    <Footer />
                </section>
            </Suspense>
        </div>
    );
}


export default Addbanner;