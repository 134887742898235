
import { UploadImage } from "../../service";
const generateFileName = () => {
    return `${uuidv4()}-${Date.now()}.webp`;
};
const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};
export const uploadToS3 = async (blob,path,fileName = false,value=process.env.REACT_APP_PurpleHealthS3bucket) => {
    // console.log(path,"2222222222222222222");
    // console.log(fileName,"3333333333333");
    // console.log(value,"44444444444444444444444444");
    
    
    try {
        const file =fileName?fileName:uuidv4();
        const formData = new FormData();
        formData.append("image", blob, file);
        const data = await UploadImage(formData,path,value);
        return data.imageUrl;
    } catch (error) {
        console.error('Upload failed:', error);
        throw error;
    }
};