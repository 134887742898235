import React, { Component, Suspense, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { apiCalling } from "../../apiService";
import Edit from "../../image/icons/edit.png";
import tick from "../../image/icons/tick.png";
import Deactivate from "../../image/icons/deactvate.png";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import Logoimg from "../../image/icons/image-preview.png";
import Plus from "../../image/icons/plus.png";
import Closebtn from "../../image/icons/close-sm.png";
import { uploadToS3 } from "../../redux/actions/UploadFile";
import DatePicker from "react-datepicker";
import moment from "moment";
import AWS from "aws-sdk";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import { FormSelect } from "semantic-ui-react";
var s3;
var imageFile;
var copyBranches
var copystate
const AddapprovalEdit = () => {
  const { pharmaRepAdsId } = useParams();
  const [adApprovalResult, setadApprovalResult] = useState([]);
  const [adCompanyList, setadCompanyList] = useState([]);
  const [adName, setadName] = useState('');
  const [description, setdescription] = useState('');
  const [imageLogo, setimageLogo] = useState('');
  const [brochureLinkName, setbrochureLinkName] = useState('');
  const [companyList, setcompanyList] = useState([]);
  const [displaySpeciality, setdisplaySpeciality] = useState([]);
  const [startDate, setstartDate] = useState('');
  const [displayStates, setdisplayStates] = useState([]);

  const [expiryDate, setexpiryDate] = useState('');
  const [tempCompanyId, settempCompanyId] = useState(0);
  const [tempCompanyData, setTempCompanyData] = useState({ companyId: "", companyName: "" });
  const [companyData, setCompanyData] = useState([]);
  const [companyLogoWithPath, setcompanyLogoWithPath] = useState('');
  // const [imagePath, setimagePath] = useState('');
  // const [s3bucketAccessKey, sets3bucketAccessKey] = useState('');
  // const [pharmaImagePath, setpharmaImagePath] = useState('');
  // const [companyLogoName, setcompanyLogoName] = useState('');
  // const [AWSAccessKeyId, setAWSAccessKeyId] = useState('');

  // const [region, setregion] = useState('us-east-1');
  // const [bucket, setbucket] = useState('');
  // const [bucketFolder, setbucketFolder] = useState('');
  const [statesDropdown, setstatesDropdown] = useState([]);
  const [specialityDropdown, setspecialityDropdown] = useState([]);
  const [tempStateId, settempStateId] = useState(0);
  const [stateData, setstateData] = useState([]);
  const [branchesDropdown, setbranchesDropdown] = useState([]);
  const [tempBranchId, settempBranchId] = useState(0);
  const [branchData, setbranchData] = useState([]);

  const [tempSpecializationId, settempSpecializationId] = useState(0);
  const [tempSpecializationData, setTempSpecializationData] = useState({ specializationId: "", specialityName: "" });
  const [specializationData, setSpecializationData] = useState([]);
  const [displayForSpecialities, setdisplayForSpecialities] = useState(false);
  const [displayForStates, setdisplayForStates] = useState(false);
  const [displayForBranches, setdisplayForBranches] = useState(false);
  const [showMessage, setshowMessage] = useState('');
  const [formData, setFormData] = useState({});
  const [dformData, setdFormData] = useState({});
  const [bformData, setbFormData] = useState({});

  const endDate = (date) => {
    setexpiryDate(date);
  };
  const fromDate = (date) => {
    setstartDate(date);
  };

  useEffect(() => {
    if (pharmaRepAdsId) {
      adApprovalList(pharmaRepAdsId);
    }
    stateDropdownList();
    dropdownSpecializationData();
    adApprovalCompanyList();
    // imageUploadHandler();
    branchDropdownList();
  }, []);

  const stateDropdownList = () => {
    const statesDropdown = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getDropdownLists",
      }),
    };
    $(".loader").show();
    apiCalling(statesDropdown).then((data) => {
      if (data.success == "1") {
        $(".loader").hide();

        setstatesDropdown(data.result[0].stateData);
      }
      if (data.success == "0") {
        $(".loader").hide();
        setstatesDropdown([]);
      }
    });
  }
  const branchDropdownList = () => {
    const branchDropdown = {
      method: "POST",
      body: JSON.stringify({
        "functionName": "branchListing",
        "keyWord": ""
      }),
    };
    $(".loader").show();
    apiCalling(branchDropdown).then((data) => {
      if (data.success == "1") {
        $(".loader").hide();
        setbranchesDropdown(data.result);
      }
      if (data.success == "0") {
        $(".loader").hide();
        setbranchesDropdown([]);
      }
    });
  }

  const companyDataList = (companyList) => {
    for (let i = 0; i < companyList.length; i++) {
      const newTempData = {
        companyId: companyList[i].siteId,
        companyName: companyList[i].siteName
      };
      setTempCompanyData(newTempData);
      setCompanyData(prevData => [...prevData, newTempData]);
      setTempCompanyData({ companyId: "", companyName: "" });
    }
  }

  const specialityDataList = (displaySpeciality) => {
    setSpecializationData([]);
    for (let i = 0; i < displaySpeciality.length; i++) {
      const newTempData1 = {
        specializationId: displaySpeciality[i].specialityId,
        specialityName: displaySpeciality[i].specialityName
      };
      setTempSpecializationData(newTempData1);
      setSpecializationData(prevData => [...prevData, newTempData1]);
      setTempSpecializationData({ specializationId: "", specialityName: "" });
    }
  }

  const adApprovalList = (pharmaRepAdsId) => {

    const approvalData = {
      method: "POST",
      body: JSON.stringify(

        {
          functionName: "editAdApproval",
          pharmaRepAdsId: pharmaRepAdsId,
        }
      ),
    };
    $(".loader").show();
    apiCalling(approvalData).then((data) => {
      if (data.success === "1") {
        setCompanyData([]);
        $(".loader").hide();
        var lmsds = data.pharmaRepAdsData[0].companyList
        setadName(data.pharmaRepAdsData[0].adName);
        setdescription(data.pharmaRepAdsData[0].description);
        setcompanyLogoWithPath(data.pharmaRepAdsData[0].imageLogo);
        setbrochureLinkName(data.pharmaRepAdsData[0].brochureLinkName);
        setcompanyList(data.pharmaRepAdsData[0].companyList);
        setdisplaySpeciality(data.pharmaRepAdsData[0].displaySpeciality);
        // setCompanyData(data.pharmaRepAdsData[0].companyList);
        // console.log('SpecializationData........',specializationData);
        // setSpecializationData(data.pharmaRepAdsData[0].displaySpeciality);
        //console.log('loggggoo',data.pharmaRepAdsData[0].imageLogo);
        // console.log('companyLogoWithPath--upda',companyLogoWithPath);
        setstateData(data.pharmaRepAdsData[0].displayStates);
        setdisplayForSpecialities(data.pharmaRepAdsData[0].displayForSpecialities);
        setdisplayForStates(data.pharmaRepAdsData[0].displayForStates)
        setbranchData(data.pharmaRepAdsData[0].branchList);
        setdisplayForBranches(data.pharmaRepAdsData[0].displayForBranches)
        //console.log("SpecializationData---",displaySpeciality)
        if (data.pharmaRepAdsData[0].startDate !== "") {
          var startDate = data.pharmaRepAdsData[0].startDate
            .toString()
            .split("/");
          var startDateConcat =
            startDate[2].toString().trim() +
            "," +
            startDate[1].toString().trim() +
            "," +
            startDate[0].toString().trim();
          var finalStartDate = new Date(startDateConcat);
        } else finalStartDate = "";
        if (data.pharmaRepAdsData[0].expiryDate !== "") {
          var endDate = data.pharmaRepAdsData[0].expiryDate
            .toString()
            .split("/");
          var endDateConcat =
            endDate[2].toString().trim() +
            "," +
            endDate[1].toString().trim() +
            "," +
            endDate[0].toString().trim();
          var finalEndDate = new Date(endDateConcat);
        } else finalEndDate = "";
        setstartDate(finalStartDate);
        setexpiryDate(finalEndDate);
        if (data.pharmaRepAdsData[0].isMedicalAd === true) {
          $("#isMedicalAd").prop("checked", true);
        } else {
          $("#isMedicalAd").prop("checked", false);
          setstatesDropdown([]);
        }




        if (displayForStates === true) {
          $("#displayForStates").prop("checked", true);
          $("#StatesList").hide();
          setspecialityDropdown(true)
        } else {
          $("#displayForStates").prop("checked", false);
          setdisplayStates(data.pharmaRepAdsData[0].displayStates);
          $("#StatesList").show();
        }


        if (displayForSpecialities === true) {
          $("#displayForSpecialities").prop("checked", true);
          setspecialityDropdown(true);
        } else {
          $("#displayForSpecialities").prop("checked", false);
          setdisplaySpeciality(data.pharmaRepAdsData[0].displaySpeciality);
          $("#displayForSpecialities").show();
        }
        if (displayForBranches === true) {
          $("#displayForBranches").prop("checked", true);
          setdisplayForBranches(true);
        } else {
          $("#displayForBranches").prop("checked", false);
          setdisplayForBranches(data.pharmaRepAdsData[0].displayForBranches)
        }

        companyDataList(data.pharmaRepAdsData[0].companyList)
        specialityDataList(data.pharmaRepAdsData[0].displaySpeciality);
      }
      if (data.success === "0") {
        $(".loader").hide();
        setshowMessage(data.errorMessage)
      }
    });

  }
  const dropdownSpecializationData = () => {

    const specializationData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getHospitalDropdown",
      }),
    };
    apiCalling(specializationData).then((data) => {
      if (data.success === "1") {
        setspecialityDropdown(data.hospitalData[0].specializationData);
      }
    });
  }
  const adApprovalCompanyList = () => {
    const companyData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getAdApprovalCompanyList",
      }),
    };
    apiCalling(companyData).then((data) => {
      if (data.success === "1") {
        setadCompanyList(data.adApprovalCompanyListData);
      }
    });
  }
  const handleStatesAutoFill = (e) => {
    if (e.target.value) {
      copystate = statesDropdown.filter(x => x.stateId == e.target.value)
    }

  };
  const handleAddStates = () => {
    if (copystate) {
      setstateData(prevState => ([
        ...prevState,
        { stateId: copystate[0].stateId, stateName: copystate[0].stateName }
      ]));
    }

  }
  const handleCancelStates = (id, i) => {
    let copyStateData = [...stateData];
    copyStateData.splice(i, 1);
    setstateData(copyStateData);
  }
  const handleBranchesAutoFill = (e) => {
    if (e.target.value) {
      copyBranches = [...branchData];
      copyBranches.push({ branchName: e.target.value })
    }
  };
  const handleAddBranches = () => {
    if (copyBranches) {
      setbranchData(copyBranches);
    }
  }
  const handleCancelBranch = (id, i) => {
    let copyBranchData = [...branchData];
    copyBranchData.splice(i, 1);
    setbranchData(copyBranchData);
  }
  const handleSpecializationAutoFill = (e) => {
    settempSpecializationId(e.target.value);
    var value = e.target.value;
    var intValue = parseInt(value);
    for (let i = 0; i < specialityDropdown.length; i++) {
      if (specialityDropdown[i].specializationId === intValue) {
        var loopId = i;
        break;
      }
    }
    var tempData = { ...tempSpecializationData };
    tempData.specializationId = e.target.value;
    tempData.specialityName = specialityDropdown[loopId].specialityName;
    setTempSpecializationData(tempData);

  };
  const handleAddSpecialization = () => {
    let index = specializationData.findIndex(x => x.specializationId === tempSpecializationData.specializationId);
    let updatedSpecializationData = [...specializationData];

    if (tempSpecializationData.specializationId !== "" && index < 0) {
      updatedSpecializationData.push(tempSpecializationData);
    }

    for (let i = 0; i < specialityDropdown.length; i++) {
      if (specialityDropdown[i].specializationId === tempSpecializationData.specializationId) {
        specialityDropdown.splice(i, 1);
      }
    }

    setSpecializationData(updatedSpecializationData);
    setTempSpecializationData({ specializationId: "", stateName: "" });
  }
  const handleCancelSpecialization = (id) => {
    const updatedSpecializationData = specializationData.filter((e) => e.specializationId !== id);
    setSpecializationData(updatedSpecializationData);
  }
  const handleCompanyAutoFill = (e) => {
    settempCompanyId(e.target.value);
    var value = e.target.value;
    var intValue = parseInt(value);
    for (let i = 0; i < adCompanyList.length; i++) {
      if (adCompanyList[i].companyId === intValue) {
        var loopId = i;
        break;
      }
    }
    var tempData = { ...tempCompanyData };
    tempData.companyId = e.target.value;
    tempData.companyName = adCompanyList[loopId].companyName;
    setTempCompanyData(tempData);
  };

  const handleAddAdApproval = () => {
    let index = companyData.findIndex(x => x.companyId === tempCompanyData.companyId);
    let updatedCompanyData = [...companyData];
    if (tempCompanyData.companyId !== "" && index < 0) {
      updatedCompanyData.push(tempCompanyData);
    }
    for (let i = 0; i < adCompanyList.length; i++) {
      if (adCompanyList[i].companyId === tempCompanyData.companyId) {
        adCompanyList.splice(i, 1);
      }
    }
    setCompanyData(updatedCompanyData);
    setTempCompanyData({ companyId: "", companyName: "" });
  }
  const handleCancelCompany = (id) => {
    const updatedCompanyData = companyData.filter((e) => e.companyId !== id);
    setCompanyData(updatedCompanyData);
  }
  const descChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }
  const descChangeb = (e) => {
    const { name, value } = e.target;
    setbFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }
  const descChanged = (e) => {
    const { name, value } = e.target;
    setdFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }
  const cancelHandle = () => {
    window.location.href = "/adminManagement/pharmadashboardList/adApproval";
  }
  const srcTypeImage = (ev) => {
    ev.target.src = Logoimg;
  }
  const fileChange = (e) => {
    imageFile = e.target.files[0];
    //console.log('imageFile',imageFile);
    if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
      alert("Add file format with only JPG,JPEG,PNG format");
      return false;
    }
    if (imageFile.size > 3145728) {
      alert("File size must under 3 MB!");
      return false;
    } else {
      fileUpload(imageFile);
    }
  };
  // const imageUploadHandler = () => {
  //   $(".loader").show();
  //   const apiJson = {
  //     method: "POST",
  //     body: JSON.stringify({
  //       functionName: "imagePath",
  //     }),
  //   };
  //   apiCalling(apiJson).then((data) => {
  //     if (data.success === "1") {
  //       $(".loader").hide();
  //       sets3bucketAccessKey(data.configPathDetails[0].s3bucketAccessKey);
  //       setAWSAccessKeyId(data.configPathDetails[0].s3bucketSecretKey);
  //       setbucket(data.configPathDetails[0].PurpleHealthS3bucket);
  //       setbucketFolder(data.configPathDetails[0].S3BucketMainFolder);
  //       setpharmaImagePath(data.configPathDetails[0].pharmaImagePath);

  //     }
  //   });
  // }
  const fileUpload = async (file) => {
    $(".loader").show();
    let fileName = uuidv4() + file.name;
    imageFile = fileName;
    const s3Url = await uploadToS3(file,`${process.env.REACT_APP_S3BucketMainFolder}/pharmaImages`,fileName.replaceAll(/ /g,""));
    if (s3Url) {
      $(".loader").hide();
      setcompanyLogoWithPath(s3Url)
      // setcompanyLogoName(fileName);
      return null;
    } else {  $(".loader").hide(); 
      alert("There was an error uploading your photo") }
    // AWS.config.update({
    //   accessKeyId: s3bucketAccessKey,
    //   secretAccessKey: AWSAccessKeyId,
    //   region: region,
    // });
    // var bucketParams = {
    //   Bucket:
    //     bucket +
    //     "/" +
    //     bucketFolder +
    //     "/" +
    //    pharmaImagePath,
    // };
    // s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    // let S3BucketMainFolder = "";
    // let fileName = uuidv4() + file.name;
    // let fullFilePath = S3BucketMainFolder + fileName;
    // imageFile = fileName;
    // setcompanyLogoWithPath(fullFilePath);
    // s3.upload(
    //   {
    //     Key: fullFilePath,
    //     Body: file,
    //     ACL: "public-read",
    //   },
    //   (err, data) => {
    //     if (err) {
    //       alert("There was an error uploading your photo: ", err.message);
    //     } else if (data) {

    //      setcompanyLogoName(fileName);
    //       $(".loader").hide();
    //       return null;
    //     }
    //   }
    // );
  }
  const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
  const handleStatesChange = (e) => {
    if (!e.target.checked) {
      $("#StatesList").fadeOut("fast");
      setdisplayForStates(false);
    } else {
      $("#StatesList").fadeIn("fast");
      setdisplayForStates(true);
    }
  };
  const handleSpecializationChange = (e) => {
    if (!e.target.checked) {
      $("#SpecializationList").fadeIn("fast");
      setdisplayForSpecialities(false);
    } else {
      $("#SpecializationList").fadeOut("fast");
      setdisplayForSpecialities(true);
    }
  };
  const handleBranchesChange = (e) => {
    if (!e.target.checked) {
      $("#BranchList").fadeIn("fast");
      setdisplayForBranches(false);
    } else {
      $("#BranchList").fadeOut("fast");
      setdisplayForBranches(true);
    }
  };
  const saveHandle = () => {
    var brochureLinkName = $('#brochureLinkName').val();
    var description = $('#description').val();
    var adName = $('#adName').val();

    let companyId = [];
    let stateId = [];
    let branchId = []
    let specializationId = [];
    var startDate = moment(startDate).format("DD/MM/YYYY");
    var endDate = moment(expiryDate).format("DD/MM/YYYY");
    for (let i = 0; i < companyData.length; i++) {
      companyId.push(companyData[i].companyId ? companyData[i].companyId : companyData[i].siteId);
    }

    if (displayForStates === false) {
      for (let i = 0; i < stateData.length; i++) {
        stateId.push(stateData[i].stateId);
      }
    }
    if (displayForBranches === false) {
      for (let i = 0; i < branchData.length; i++) {
        branchId.push(branchData[i].branchName);
      }
    }
    if (displayForSpecialities === false) {
      for (let i = 0; i < specializationData.length; i++) {
        specializationId.push(
          specializationData[i].specializationId
        );
      }
    }
    let companyString = companyId.toString();
    if (companyString === "") {
      alert("Companies List Required");
      return false;
    }
    let branchString = branchId.toString();
    let stateString = stateId.toString();
    let specializationString = specializationId.toString();

    let isMedicalAd =
      $("#isMedicalAd").is(":checked") === true ? "true" : "false";
    $(".loader").show();
    var companyLogoName = companyLogoName;
    if (companyLogoName === "" || companyLogoName === undefined) {
      companyLogoName = /[^/]*$/.exec(companyLogoWithPath)[0];
    }

    const saveData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "updatePharmaRepAds",
        pharmaRepAdsId: pharmaRepAdsId,
        adName: adName ? adName : formData.adName,
        description: description ? description : dformData.description,
        imageLogo: companyLogoName ? companyLogoName : companyLogoWithPath,
        // companyLogoWithPath?companyLogoWithPath:companyLogoName,
        brochureLink: brochureLinkName ? brochureLinkName : bformData.brochureLinkName,
        isMedicalAd: isMedicalAd,
        displayForStates: displayForStates,
        displayForSpecialities: displayForSpecialities,
        displayForBranches: displayForBranches,
        startDate: startDate,
        expiryDate: endDate,
        companyList: companyString,
        specialities: specializationString.toString(),
        targetStates: stateId.toString(),
        branches: branchId.toString(),
      }),
    };
    // return false
    apiCalling(saveData).then((data) => {
      if (data.success === "1") {
        $(".loader").hide();
        alert("Updated successfully");
        cancelHandle();
      } else if (data.success === "0") {
        alert(data.errorMessage);
        $(".loader").hide();
        cancelHandle();
      }
    });
  };
  return (
    <div className="purpleWrap bg-clr-wthHead">
      <Suspense fallback={<div></div>}>
        <section>
          <Header /> <div className="loader"></div>
        </section>
      </Suspense>
      <div className="loader"></div>
      <div className="container mb-5">
        <div>
          <ul className="breadcrum-adminMangmnt">
            <li>
              <a href="/admin/dashboardAdmin">Dashboard</a>
            </li>
            <li>
              <a href="/adminDashboard/pharmaDashboard">Pharma Ad Approval</a>
            </li>
            <li>
              <a href="/adminManagement/pharmadashboardList/adApproval">
                Ad Approval List
              </a>
            </li>
            <li>
              <a href="" className="current">
                Ad Approval Edit
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="addrepwrapperborder container pt-5 m-auto text-left purple_edit">
        <div className="row">
          <div className="col-md-6 mb-3">
            <div className="row">
              <div className="col-md-4">
                Ad Name<span className="text-danger">*</span>
              </div>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control"
                  name="adName"
                  id="adName"
                  value={formData.adName ? formData.adName : adName}
                  onChange={(e) => descChange(e)}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12 mb-3">
            <div className="row">
              <div className="col-md-2">
                Description<span className="text-danger">*</span>
              </div>
              <div className="col-md-10">
                <textarea
                  className="form-control"
                  name="description"
                  id="description"
                  value={dformData.description ? dformData.description : description}
                  onChange={(e) => descChanged(e)}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="row">
              <div className="col-md-4">
                Logo<span className="text-danger">*</span>
              </div>
              <div className="col-md-8 d-flex align-items-center">
                <div className="hspinfo-btnupload mb-auto">
                  <span>Upload</span>
                  <input
                    type="file"
                    className="hspinfoInputfieldupload"
                    onChange={fileChange}
                  />
                </div>
                {companyLogoWithPath ? (
                  <div className="file_img">
                    <img
                      id="companyLogoWithPath"
                      src={companyLogoWithPath}
                      onError={srcTypeImage}
                      width="50"
                      alt="Upload"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="row">
              <div className="col-md-4">
                Broucher Link<span className="text-danger">*</span>
              </div>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control"
                  name="brochureLinkName"
                  id="brochureLinkName"
                  value={bformData.brochureLinkName ? bformData.brochureLinkName : brochureLinkName}
                  onChange={(e) => descChangeb(e)}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex my-3">
            <label className="d-flex align-items-center mb-0">
              Medical Ad
            </label>
            <input
              type="checkbox"
              id="isMedicalAd"
              name="isMedicalAd"
              className="mx-3"
            />
          </div>
          <div className="col-md-6 d-flex my-3">
            <label className="d-flex align-items-center mb-0">
              Display For All States
              <input
                type="checkbox"
                name="displayForStates"
                id="displayForStates"
                className="mx-3"
                onChange={handleStatesChange}
              />
            </label>
            <label className="d-flex align-items-center m-auto mb-0">
              Display For All Specialities
              <input
                type="checkbox"
                name="displayForSpecialities"
                id="displayForSpecialities"
                className="mx-3"
                onChange={handleSpecializationChange}
              />
            </label>
            <label className="d-flex align-items-center mb-0">
              Display Branches
              <input
                type="checkbox"
                name="displayForBranches"
                id="displayForBranches"
                className="mx-3"
                onChange={handleBranchesChange}
              />
            </label>
          </div>
          <div className="col-md-6 mb-3">
            <div className="row ccccccccccc">
              <div className="col-md-4">
                Company<span className="text-danger">*</span>
              </div>
              <div className="col-md-8 d-flex align-items-center">
                <select
                  className="form-control"
                  onChange={(e) => handleCompanyAutoFill(e)}
                >
                  <option defaultChecked>Select Company</option>
                  {adCompanyList.map((data) => (
                    <option key={data.companyId} value={data.companyId}>{data.companyName}</option>
                  ))}
                </select>
                <div className="input_icon">
                  <a
                    onClick={() => handleAddAdApproval()}
                  >
                    <img src={Plus} />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 mb-3">

            {companyData.length > 0 ? (
              <div className="row cmpylist">
                {companyData.map((data) => (
                  <div className="col-md-3">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        key={data.siteId ? data.siteId : data.companyId}
                        readOnly
                        className="form-control"
                        value={data.siteName ? data.siteName : data.companyName}
                      />
                      <div className="input_icon">
                        <a
                          onClick={() =>
                            handleCancelCompany(data.siteId ? data.siteId : data.companyId)
                          }
                        >

                          <img src={Closebtn} />
                        </a>
                      </div>
                    </div>
                  </div>

                ))}
              </div>
            ) : (
              <span hidden></span>
            )}
          </div>
          {displayForStates === false ? (
            <div className="col-md-12">
              <div id="StatesList" className="row">
                <div className="col-md-6 mb-3">
                  <div className="row">
                    <div className="col-md-4">
                      States List<span className="text-danger">*</span>
                    </div>
                    <div className="col-md-8 d-flex align-items-center">
                      <select
                        className="form-control"
                        onChange={(e) => handleStatesAutoFill(e)}
                      >
                        <option defaultChecked>Select States</option>
                        {statesDropdown.map((data) => (
                          <option value={data.stateId}>{data.stateName}</option>
                        ))}
                      </select>
                      <div className="input_icon">
                        <a onClick={() => handleAddStates()}>
                          <img src={Plus} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">


                  <div className="row">
                    {stateData.map((data, i) => (
                      <div className="col-md-3">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            key={data.stateId}
                            readOnly
                            className="form-control"
                            value={data.stateName}
                          />
                          <div className="input_icon">
                            <a
                              onClick={() =>
                                handleCancelStates(data.stateId, i)
                              }
                            >
                              <img src={Closebtn} />
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* // ) : (
                  //   <span hidden></span>
                  // )} */}
                </div>
              </div>
            </div>) : ""}
          {displayForBranches === false ? (
            <div className="col-md-12">
              <div id="BranchList" className="row">
                <div className="col-md-6 mb-3">
                  <div className="row">
                    <div className="col-md-4">
                      Branch List<span className="text-danger">*</span>
                    </div>
                    <div className="col-md-8 d-flex align-items-center">
                      <select
                        className="form-control"
                        onChange={(e) => handleBranchesAutoFill(e)}
                      >
                        <option value="" defaultChecked>Select Branches</option>
                        {branchesDropdown.map((data) => (
                          <option key={data.branchId} value={data.branchName}>{data.branchName}</option>
                        ))}
                      </select>
                      <div className="input_icon">
                        <a onClick={() => handleAddBranches()}>
                          <img src={Plus} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">

                  <div className="row">
                    {branchData.map((data, i) => (
                      <div className="col-md-3">
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            key={data.branchId}
                            readOnly
                            className="form-control"
                            value={data.branchName}
                          />
                          <div className="input_icon">
                            <a
                              onClick={() =>
                                handleCancelBranch(data.branchName, i)
                              }
                            >
                              <img src={Closebtn} />
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* // ) : (
                  //   <span hidden></span>
                  // )} */}
                </div>
              </div>
            </div>) : ""}
          {displayForSpecialities === false ?
            <div className="col-md-12">
              <div id="SpecializationList" className="row mb-3">
                <div className="col-md-6 mb-3">
                  <div className="row">
                    <div className="col-md-4">
                      Specialization List<span className="text-danger">*</span>
                    </div>
                    <div className="col-md-8 d-flex align-items-center">
                      <select
                        className="form-control"
                        onChange={(e) => handleSpecializationAutoFill(e)}
                      >
                        <option defaultChecked>Select Specialization</option>
                        {specialityDropdown.map((data) => (
                          <option value={data.specializationId}>
                            {data.specialityName}
                          </option>
                        ))}
                      </select>
                      <div className="input_icon">
                        <a
                          onClick={() => handleAddSpecialization()}
                        >
                          <img src={Plus} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">

                  {specializationData.length > 0 ? (
                    <div className="row">
                      {specializationData.map((data) => (
                        <div className="col-md-3">
                          {/* <label className="invisible">Specialization list</label> */}
                          <div className="d-flex align-items-center">
                            <input
                              type="text"
                              key={data.specializationId ? data.specializationId : data.specialityId}
                              readOnly
                              className="form-control"
                              value={data.specialityName}
                            />
                            <div className="input_icon">
                              <a
                                onClick={() =>
                                  handleCancelSpecialization(
                                    data.specializationId
                                  )
                                }
                              >
                                <img src={Closebtn} />
                              </a>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <span hidden></span>
                  )}
                </div>
              </div>
            </div> : ""}
          <div className="col-md-6 mb-3">
            <div className="row">
              <div className="col-md-4">Start Date</div>
              <div className="col-md-8">
                <DatePicker
                  selected={startDate}
                  className="mb-0 form-control"
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown={true}
                  peekNextMonth
                  showYearDropdown
                  showMonthDropdown
                  onSelect={fromDate}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="row">
              <div className="col-md-4">End Date</div>
              <div className="col-md-8">
                <DatePicker
                  selected={expiryDate}
                  onChange={endDate}
                  className="mb-0 form-control"
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown={true}
                  showYearDropdown
                  showMonthDropdown
                />
              </div>
            </div>
          </div>
          <div className="col-md-12 text-right mb-3">
            <input
              type="submit"
              className="smssetng-svebtn"
              onClick={() => saveHandle()}
            />
            <input
              type="submit"
              value="Cancel"
              className="smssetng-cancelbtn"
              onClick={cancelHandle}
            />
          </div>
        </div>
      </div>
      <Suspense fallback={<div></div>}>
        <section>
          <Footer />
        </section>
      </Suspense>
    </div>
  );
}

export default AddapprovalEdit;