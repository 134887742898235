import React, { useEffect, useState } from 'react';
import Header from "../layout/header";
import Footer from "../layout/footer";
import Micorlabs from "../image/media/micro.png"
import Application from "../image/media/application.png"
import Transaction from "../image/media/transaction.png"
import Video from "../image/media/video-message.png"
import People from "../image/media/two-people.png"
import Direction from "../image/media/direction.png"
import Booking from "../image/media/booking.png"
import Telephone from "../image/media/telephone.png"
import Record from "../image/media/medical-record.png"
import Interface from "../image/media/interface.png"
import Speech from "../image/media/speech-bubble.png"
import PrimeLogo from "../image/media/prime logo1.jpg"
import Purple1 from "../image/media/purple-health.png"
import Linechart from "./doctor-report-chart"
import PDFIcon from "../image/icons/ht-pdficon.png"
import Whatsapp from "../image/whatsapp-img.png"
import $ from "jquery";
import DatePicker from "react-datepicker";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useNavigate, Link, useLocation } from "react-router-dom";
import { apiCalling, awsAPIUrlcommon } from "../apiService";
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import { isArray } from 'lodash';
import WhatsappPopup from "./whatsapp-popup";
import AWS from "aws-sdk";
import { uploadToS3 } from '../redux/actions/UploadFile';
function DoctorReport() {
    const location = useLocation();
    const siteId = location.state?.siteId;
    const [doctorReportList, setDoctorReportList] = useState(null);
    const [sendPdfUrl, setSendPdfUrl] = useState("");
    const [uploaded, setUploaded] = useState(false)
    const [fromMonthYear, setFromMonthYear] = useState(moment().format("MMMM YYYY"));
    const [toMonthYear, setToMonthYear] = useState("");
    const [CompanyLogo, setCompanyLogo] = useState("");
    const [projectLogo, setProjectLogo] = useState("");
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [mobileNumber, setmobileNumber] = useState("73061045559");
    const [imageDeatils, setimageDeatils] = useState({
        AWSAccessKeyId: "",
        s3bucketAccessKey: "",
        region: "us-east-1",
        bucket: "",
        bucketFolder: "",
    })
    const [loading, setLoading] = useState(false);
    const searchDate = () => {
        if (fromMonthYear && toMonthYear && (moment(fromMonthYear).diff(moment(toMonthYear), "milliseconds") > 0)) {
            toast.error("To date is greater than or equal to from date.")
            return false
        }
        else {
            getDoctorSiteDashboard();
        }
    }
    useEffect(() => {
        // getImagePath();
        if (fromMonthYear) {
            getDoctorSiteDashboard();
        }
        if (!window.sessionStorage.getItem("doctorDashBId")) {
            window.location.href = "/admin/doctor-dashboard-list"
        }
    }, []);
    const downloadAndUploadPDF = async () => {
        try {
            const element = document.getElementById("pdf-content");
            const options = { background: 'white', scale: 2, useCORS: true };
            const canvas = await html2canvas(element, options);
            const pdf = new jsPDF("p", "mm", "a4");
            const imgData = canvas.toDataURL("image/jpeg", 1.0);
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
            const pdfBlob = pdf.output("blob");

            const fileName = `doctor-dashboard-${window.sessionStorage.getItem("doctorDashBName")
                .trim()
                .replaceAll(/\s|_/g, "-")}-${moment(fromMonthYear)
                    .format("MM YYYY")
                    .trim()
                    .replaceAll(/\s|_/g, "-")}.pdf`;
            const path = `rhealthfiles/rhealthfiles/RHADashboard`;
            // Upload to S3 and return the URL
            return await uploadPDFToS3(pdfBlob, path, fileName, "rhealthfiles");
        } catch (error) {
            console.error("Error generating PDF:", error);
            return null;
        }
    };

    // const uploadPDFToS3 = async (file, fileName) => {
    //     return new Promise((resolve, reject) => {
    //         try {
    //             const bucketName = imageDeatils.bucket;
    //             const folderPath = imageDeatils.bucketFolder ? `${imageDeatils.bucketFolder}/pdfs/` : "pdfs/";
    //             const fullFilePath = `${folderPath}${fileName}`;

    //             AWS.config.update({
    //                 accessKeyId: imageDeatils.s3bucketAccessKey,
    //                 secretAccessKey: imageDeatils.AWSAccessKeyId,
    //                 region: imageDeatils.region,
    //             });

    //             const s3 = new AWS.S3();

    //             const params = {
    //                 Bucket: bucketName,
    //                 Key: fullFilePath,
    //                 Body: file,
    //                 ACL: "public-read",
    //                 ContentType: "application/pdf",
    //                 ContentDisposition: "inline"
    //             };

    //             s3.upload(params, (err, data) => {
    //                 if (err) {
    //                     console.error("Error uploading PDF:", err);
    //                     reject(err);
    //                 } else {
    //                     console.log("File uploaded successfully:", data.Location);
    //                     resolve(data.Location);
    //                 }
    //             });
    //         } catch (error) {
    //             console.error("S3 upload error:", error);
    //             reject(error);
    //         }
    //     });
    // };
    const uploadPDFToS3 = async (file) => {
        try {
            const doctorDashBName = window.sessionStorage.getItem("doctorDashBName").trim().replaceAll(/\s|_/g, "-");
            const doctorDashBId = window.sessionStorage.getItem("doctorDashBId");
            const formattedMonthYear = moment(fromMonthYear).format("MM-YYYY").trim().replaceAll(/\s|_/g, "-");
            // Define the S3 path and file name
            const path = `rhealthfiles/rhealthfiles/RHADashboard`;
            const fileName = `doctor-dashboard-${doctorDashBName}-${formattedMonthYear}-${doctorDashBId}.pdf`;
            // Upload to S3
            const s3Url = await uploadToS3(file, path, fileName, "rhealthfiles");

            if (s3Url) {
                console.log("File uploaded successfully:", s3Url);
                return s3Url;
            }
        } catch (error) {
            console.error("S3 upload error:", error);
            throw error;
        }
    };
    const downloadPDF = () => {
        const element = document.getElementById("pdf-content");
        const options = {
            background: 'white',
            scale: 2,
            useCORS: true,
        };
        html2canvas(element, options).then((canvas) => {
            const pdf = new jsPDF("p", "mm", "a4");
            const imgData = canvas.toDataURL("image/jpeg", 1.0);
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
            pdf.save(`doctor-dashboard-${window.sessionStorage.getItem("doctorDashBName").trim().replaceAll(/\s|_/g, "-")}-${moment(fromMonthYear).format("MM YYYY").trim().replaceAll(/\s|_/g, "-")}.pdf`);
        });
    };
    const convertImageToBase64 = async (imgUrl) => {
        try {
            if (!imgUrl) return null;

            // Add cache busting to prevent cached images
            const cacheBustedUrl = `${imgUrl}?${new Date().getTime()}`;
            const response = await fetch(cacheBustedUrl);

            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

            const blob = await response.blob();
            return await new Promise((resolve) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.readAsDataURL(blob);
            });
        } catch (error) {
            console.error("Error converting image:", error);
            return null;
        }
    };
    // const getImagePath = () => {
    // const apiJson = {
    //     method: "POST",
    //     body: JSON.stringify({
    //         functionName: "imagePath",
    //     }),
    // };
    // apiCalling(apiJson).then((data) => {
    // if (data.success === "1") {
    // setimageDeatils((prev) => ({
    //     ...prev,
    //     s3bucketAccessKey: data.configPathDetails[0].s3bucketAccessKey,
    //     AWSAccessKeyId: data.configPathDetails[0].s3bucketSecretKey,
    //     bucket: data.configPathDetails[0].PurpleHealthS3bucket,
    //     bucketFolder: data.configPathDetails[0].S3BucketMainFolder,
    // }))
    // let bucketPath =
    //     data.configPathDetails[0].s3BucketRelianceAddressPath +
    //     "/" +
    //     data.configPathDetails[0].S3BucketMainFolder +
    //     "/" +
    //     'doctorDashboard' +
    //     "/";
    // setImagePathData(bucketPath);

    //     getDoctorSiteDashboard(imagePathData);
    //     let s3PdfFilePath =
    //    process.env.REACT_APP_s3BucketpurplehealthfilesAddressPath+
    //         "/" +
    //         process.env.REACT_APP_S3BucketMainFolder +
    //         "/" +
    //         'pdfs' +
    //         "/";
    //     console.log(s3PdfFilePath);
    //     setImagePathData1(s3PdfFilePath);

    // }
    // }).catch((error) => {
    //     setLoading(false);
    //     toast.error("An error occurred while getting details");
    // });
    // };
    const whatsappClick = async () => {
        try {
            setLoading(true); // Show a loading state if needed

            // Generate and upload PDF
            const fileUrl = await downloadAndUploadPDF();

            if (fileUrl) {
                console.log("PDF Uploaded:", fileUrl);
                setSendPdfUrl(fileUrl); // Store the uploaded file URL
                setIsPopupOpen(true);   // Now open the popup
            } else {
                console.error("PDF upload failed.");
            }
        } catch (error) {
            console.error("Error in whatsappClick:", error);
        } finally {
            setLoading(false);
        }
    };


    const getDoctorSiteDashboard = (bucketPath) => {
        setLoading(true);
        let postData = {
            method: "POST",
            body: JSON.stringify({
                functionName: "purpleHealthDoctorSiteDashboard",
                doctor_id: window.sessionStorage.getItem("doctorDashBId"),
                month_and_year: fromMonthYear ? moment(fromMonthYear).format("YYYY-MM") : "",
                month_and_year_to: toMonthYear ? moment(toMonthYear).format("YYYY-MM") : "",
                site_id: parseInt(window.sessionStorage.getItem("doctor-reportSiteId"), 10),
            }),
        }

        awsAPIUrlcommon(postData).then(async (data) => {
            if (data.success === "1") {
                setDoctorReportList(data.result);
                const association = data?.result?.[0]?.association?.[0];
                if (association) {
                    try {
              const bucketPath=process.env.REACT_APP_s3BucketRelianceAddressPath + "/" +
              "rhealthfiles" + "/" +
              "rhealthfiles" + "/" + "RHADashboard/"          
                        const companyLogo=await convertImageToBase64(bucketPath+ association.topRightLogo);
                        const projectLogo=await convertImageToBase64(bucketPath+ association.bottomRightLogo);
                        setCompanyLogo(companyLogo || '');
                        setProjectLogo(projectLogo || '');
                        await new Promise(resolve => setTimeout(resolve, 100));

                    } catch (error) {
                        console.error("Logo conversion error:", error);
                        toast.error("Error loading logos");
                    }
                }
            }
            // } else {
            //     console.warn("No valid data found in the response.");
            // }
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }).catch((error) => {
            setLoading(false);
            toast.error("An error occurred while getting details");
            console.error("API Error:", error);
        });
    }


    return (
        <div>
            <ToastContainer />
            <Header />

            {loading && <div className="loader d-block"></div>}
            <div className="container">
                <div>
                    <ul className="breadcrum-adminMangmnt mt-2">
                        <li>
                            <a href="/admin/doctor-dashboard-list">Doctor Dashboard List</a>
                        </li>
                        <li>
                            <a className="current">Doctor Report</a>
                        </li>
                    </ul>
                </div>
                <div className="m-auto">
                    <div className="col-11 col-lg-10 col-md-10">
                        <div className='d-flex justify-content-end my-3 align-items-center'>
                            <div className="d-flex">
                                <span className='pt-2 mr-2'>From </span>
                                <DatePicker
                                    dateFormat="MMMM yyyy"
                                    showMonthYearPicker
                                    selected={fromMonthYear}
                                    onChange={(date) => { setFromMonthYear(date ? moment(date).format("MMMM YYYY") : "") }}
                                    className="slecthsp slecthspMob mb-0 border-rad0"
                                />
                                <span className='ml-4 pt-2 mr-2'>To </span>
                                <DatePicker
                                    dateFormat="MMMM yyyy"
                                    showMonthYearPicker
                                    selected={toMonthYear}
                                    onChange={(date) => { setToMonthYear(date ? moment(date).format("MMMM YYYY") : "") }}
                                    className="slecthsp slecthspMob mb-0 border-rad0"
                                />
                                <button
                                    className="searchbtn-hspMng ml-2 mt-0"
                                    type="button"
                                    onClick={searchDate}
                                    disabled={!fromMonthYear && !toMonthYear}
                                >
                                    Search
                                </button>
                                <div className='col-12 col-lg-2 col-md-2 cursor-pointer'>
                                    <img src={PDFIcon} alt="PDFIcon" className='img-fluid ml-1' width="25" height="25" onClick={downloadPDF} title="Download Pdf" />

                                    <img src={Whatsapp} alt="Whatsapp" className='img-fluid ml-1' width="35" height="35" onClick={whatsappClick} title="Download Pdf" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='main-outer doctor-report' id="pdf-content" key={projectLogo + CompanyLogo}>
                    {console.log(projectLogo + CompanyLogo, "fffffffffffffffffffffffffffffffff")
                    }
                    <div className="dwnlod-btn-right">
                    </div>
                    <header className="header">
                        <div className='header-top'>
                            <div className="row">
                                <div className="col-lg-9 col-6 mt-3 mb-3">
                                    <h1 className='header-h1 mt-2'>Business Insights of {window.sessionStorage.getItem("doctorDashBName")}</h1>
                                    <p className='header-date'>{fromMonthYear && toMonthYear ? `${fromMonthYear} - ${toMonthYear}` : fromMonthYear || toMonthYear}</p>
                                </div>
                                {(doctorReportList?.[0]?.association?.[0]?.topRightLogo) &&
                                    <div className="col-lg-3 col-6 d-flex justify-content-end align-items-center pr-5">
                                        {CompanyLogo && (
                                            <img src={CompanyLogo} alt="Company Logo" className='img-fluid' width="100" height="100" />
                                        )}
                                    </div>
                                }
                            </div>

                        </div>
                    </header>
                    <div className='violet-border'></div>
                    <div className="utilization-snapshot">
                        <div className="">
                            <div className="">
                                <h2 className='utilization-head'>Utilization Snapshot</h2>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-12 pr-lg-0 pr-md-0">
                                    <div className="stats">
                                        <div className="stat-box">
                                            <div className="row border-blue">
                                                <div className="col-8">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <h3 className='count-report'>{doctorReportList?.[0]?.utilizationSnapshot?.totalPatient || 0}
                                                            </h3>
                                                        </div>
                                                        <div className="col-6">
                                                            <span className={(doctorReportList?.[0]?.utilizationSnapshot?.totalPatientChange >= 0) ? `increase` : `decrease`}>
                                                                {(doctorReportList?.[0]?.utilizationSnapshot?.totalPatientChange >= 0) ? `▲ ${doctorReportList?.[0]?.utilizationSnapshot?.totalPatientChange}` : `▼ ${Math.abs(doctorReportList?.[0]?.utilizationSnapshot?.totalPatientChange)}`}
                                                                {/* {doctorReportList?.[0]?.utilizationSnapshot?.totalPatientChange || 0} */}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <p className='patient-visit-count'>Total patient visits</p>
                                                </div>
                                                <div className="col-4">
                                                    <img src={Interface} alt="Img1" className='img-fluid' width="60" height="60" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="stat-box">
                                            <div className="row border-blue">
                                                <div className="col-8">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <h3 className='count-report'>{doctorReportList?.[0]?.utilizationSnapshot?.uniquePatient || 0}</h3>
                                                        </div>
                                                        <div className="col-6">
                                                            <span className={(doctorReportList?.[0]?.utilizationSnapshot?.uniquePatientChange >= 0) ? `increase` : `decrease`}>
                                                                {(doctorReportList?.[0]?.utilizationSnapshot?.uniquePatientChange >= 0) ? `▲ ${doctorReportList?.[0]?.utilizationSnapshot?.uniquePatientChange}` : `▼ ${Math.abs(doctorReportList?.[0]?.utilizationSnapshot?.uniquePatientChange)}`}
                                                                {/* {doctorReportList?.[0]?.utilizationSnapshot?.uniquePatientChange || 0} */}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <p className='patient-visit-count'>Unique patient visits</p>
                                                </div>
                                                <div className="col-4">
                                                    <img src={Record} alt="Img1" className='img-fluid' width="80" height="80" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {(doctorReportList?.[0]?.patientVisitData) &&
                                    <div className="col-lg-8 col-md-6 col-12 graph-line" >
                                        <Linechart
                                            seriesData={doctorReportList?.[0]?.patientVisitData.series}
                                            labelsData={doctorReportList?.[0]?.patientVisitData.labels} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='blue-border'></div>

                    <div className="google-snapshot">
                        <div className="">
                            <div className="">
                                <h2 className='google-head'>Google My Business</h2>
                            </div>
                            <div className="row mr-0 border-red google-my-business">
                                {doctorReportList?.[0]?.googleMyBusiness?.googleListing === 'NA' &&
                                    <div className='access-denied_section'>
                                        <div className='access-denied_content'>Access pending - Please call us @
                                            <span style={{ letterSpacing: "1px" }}> {doctorReportList?.[0]?.association?.[0]?.contactPersonPhone}</span>
                                        </div>
                                    </div>
                                }
                                <div className="col-12 text-center py-2 business-profile-head">
                                    Business Profile Interaction - {doctorReportList?.[0]?.googleMyBusiness?.googleListing === 'NA' ?
                                        doctorReportList?.[0]?.googleMyBusiness?.googleListing
                                        : doctorReportList?.[0]?.googleMyBusiness?.businessProfileInteraction}
                                </div>
                                <div className="col-lg-5 col-md-5 col-12">
                                    <div className="stats">
                                        <div className="stat-box">
                                            <div className="row border-grey m-0">
                                                <div className="col-8">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <h3 className='count-report'>{doctorReportList?.[0]?.googleMyBusiness?.googleListing === 'NA' ?
                                                                doctorReportList?.[0]?.googleMyBusiness?.googleListing
                                                                : doctorReportList?.[0]?.googleMyBusiness?.Calls}
                                                            </h3>
                                                        </div>

                                                    </div>
                                                    <p className='patient-visit-count'>Calls</p>
                                                </div>
                                                <div className="col-4">
                                                    <img src={Telephone} alt="Img1" className='img-fluid' width="60" height="60" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="stat-box">
                                            <div className="row m-0">
                                                <div className="col-8">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <h3 className='count-report'>{doctorReportList?.[0]?.googleMyBusiness?.googleListing === 'NA' ?
                                                                doctorReportList?.[0]?.googleMyBusiness?.googleListing
                                                                : doctorReportList?.[0]?.googleMyBusiness?.Bookings}</h3>
                                                        </div>

                                                    </div>
                                                    <p className='patient-visit-count'>Bookings</p>
                                                </div>
                                                <div className="col-4">
                                                    <img src={Booking} alt="Img1" className='img-fluid' width="60" height="60" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-12">

                                </div>
                                <div className="col-lg-5 col-md-5 col-12">
                                    <div className="stats">
                                        <div className="stat-box">
                                            <div className="row border-grey m-0">
                                                <div className="col-8 ">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <h3 className='count-report'>{doctorReportList?.[0]?.googleMyBusiness?.googleListing === 'NA' ?
                                                                doctorReportList?.[0]?.googleMyBusiness?.googleListing
                                                                : doctorReportList?.[0]?.googleMyBusiness?.Directions}
                                                            </h3>
                                                        </div>

                                                    </div>
                                                    <p className='patient-visit-count'>Directions</p>
                                                </div>
                                                <div className="col-4">
                                                    <img src={Direction} alt="Img1" className='img-fluid' width="60" height="60" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="stat-box">
                                            <div className="row m-0">
                                                <div className="col-8">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <h3 className='count-report'>{doctorReportList?.[0]?.googleMyBusiness?.googleListing === 'NA' ?
                                                                doctorReportList?.[0]?.googleMyBusiness?.googleListing
                                                                : doctorReportList?.[0]?.googleMyBusiness?.websiteClicks}</h3>
                                                        </div>

                                                    </div>
                                                    <p className='patient-visit-count'>Website Clicks</p>
                                                </div>
                                                <div className="col-4">
                                                    <img src={Application} alt="Img1" className='img-fluid' width="60" height="60" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='red-border'></div>
                    <div className="business-snapshot">
                        <div className="">
                            <div className="">
                                <h2 className='business-head'>Business Snapshot</h2>
                            </div>
                            <div className="row mr-0 border-green">
                                <div className="col-lg-5 col-md-5 col-12">
                                    <div className="stats">
                                        <div className="stat-box">
                                            <div className="row border-grey m-0">
                                                <div className="col-8">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <h3 className='count-report'>{doctorReportList?.[0]?.businessSnapshot?.TotalTransaction || 0}
                                                            </h3>
                                                        </div>
                                                        <div className="col-6">
                                                            <span className={(doctorReportList?.[0]?.businessSnapshot?.totalTransactionChange >= 0) ? `increase` : `decrease`}>
                                                                {(doctorReportList?.[0]?.businessSnapshot?.totalTransactionChange >= 0) ? `▲ ${doctorReportList?.[0]?.businessSnapshot?.totalTransactionChange}` : `▼ ${Math.abs(doctorReportList?.[0]?.businessSnapshot?.totalTransactionChange)}`}
                                                                {/* {doctorReportList?.[0]?.businessSnapshot?.totalTransactionChange || 0} */}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <p className='patient-visit-count'>Transactions
                                                    </p>
                                                </div>
                                                <div className="col-4">
                                                    <img src={Transaction} alt="Img1" className='img-fluid' width="60" height="60" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="stat-box">
                                            <div className="row m-0">
                                                <div className="col-8 ">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <h3 className='count-report'>{doctorReportList?.[0]?.businessSnapshot?.inPerson || 0}</h3>
                                                        </div>
                                                        <div className="col-6">
                                                            <span className={(doctorReportList?.[0]?.businessSnapshot?.inPersonChange >= 0) ? `increase` : `decrease`}>
                                                                {(doctorReportList?.[0]?.businessSnapshot?.inPersonChange >= 0) ? `▲ ${doctorReportList?.[0]?.businessSnapshot?.inPersonChange}` : `▼ ${Math.abs(doctorReportList?.[0]?.businessSnapshot?.inPersonChange)}`}
                                                                {/* {doctorReportList?.[0]?.businessSnapshot?.inPersonChange || 0} */}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <p className='patient-visit-count'>In-person</p>
                                                </div>
                                                <div className="col-4">
                                                    <img src={People} alt="Img1" className='img-fluid' width="60" height="60" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-12">
                                </div>
                                <div className="col-lg-5 col-md-5 col-12">
                                    <div className="stats">
                                        <div className="stat-box">
                                            <div className="row border-grey m-0" >
                                                <div className="col-8">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <h3 className='count-report'>{doctorReportList?.[0]?.businessSnapshot?.videoChat || 0}
                                                            </h3>
                                                        </div>
                                                        <div className="col-6">
                                                            <span className={(doctorReportList?.[0]?.businessSnapshot?.videoChatChange >= 0) ? `increase` : `decrease`}>
                                                                {(doctorReportList?.[0]?.businessSnapshot?.videoChatChange >= 0) ? `▲ ${doctorReportList?.[0]?.businessSnapshot?.videoChatChange}` : `▼ ${Math.abs(doctorReportList?.[0]?.businessSnapshot?.videoChatChange)}`}
                                                                {/* {doctorReportList?.[0]?.businessSnapshot?.videoChatChange || 0} */}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <p className='patient-visit-count'>Video Chat</p>
                                                </div>
                                                <div className="col-4">
                                                    <img src={Video} alt="Img1" className='img-fluid' width="60" height="60" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="stat-box">
                                            <div className="row m-0">
                                                <div className="col-8">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <h3 className='count-report'>{doctorReportList?.[0]?.businessSnapshot?.textChat || 0}</h3>
                                                        </div>
                                                        <div className="col-6">
                                                            <span className={(doctorReportList?.[0]?.businessSnapshot?.textChatChange >= 0) ? `increase` : `decrease`}>
                                                                {(doctorReportList?.[0]?.businessSnapshot?.textChatChange >= 0) ? `▲ ${doctorReportList?.[0]?.businessSnapshot?.textChatChange}` : `▼ ${Math.abs(doctorReportList?.[0]?.businessSnapshot?.textChatChange)}`}
                                                                {/* {doctorReportList?.[0]?.businessSnapshot?.textChatChange || 0} */}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <p className='patient-visit-count'>Text Chat</p>
                                                </div>
                                                <div className="col-4">
                                                    <img src={Speech} alt="Img1" className='img-fluid' width="60" height="60" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='green-border'></div>
                    <div className="header py-3">
                        <div className="">
                            <div className="row mr-0 violet-text bottom-text">
                                <div className="col-6">
                                    <img src={Purple1} alt="Img1" className='img-fluid mb-2' width="190" height="auto" />
                                    <div>
                                        {doctorReportList?.[0]?.association?.[0]?.contactPersonName || ""}
                                    </div>
                                    <div>{doctorReportList?.[0]?.association?.[0]?.contactPersonPhone || ""}</div>
                                    <div>{doctorReportList?.[0]?.association?.[0]?.contactPersonEmail || ""}</div>
                                </div>
                                {console.log(projectLogo, "4444444444444444444444444")
                                }
                                {(doctorReportList?.[0]?.association?.[0]?.bottomRightLogo) &&
                                    <div className="col-6 d-flex align-items-center justify-content-end pr-5">
                                        {projectLogo && (
                                            <img src={projectLogo} alt="Project Logo" className='img-fluid' height="100" width="100" />
                                        )}
                                    </div>
                                }
                            </div>
                            <div className="text-small text-center py-4">
                                - Private and confidential. Property of
                                PurpleHealth
                                . Not to be forwarded or duplicated -

                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
            <WhatsappPopup sendPdfUrl={sendPdfUrl} key={sendPdfUrl}
                isOpen={isPopupOpen}
                onClose={() => setIsPopupOpen(false)}

            />
        </div>
    );

}

export default DoctorReport;
