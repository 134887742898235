import React, { useEffect, useState, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { awsAPIUrlcommon } from "../apiService";
import moment from "moment";
import csv from "../image/icons/csv.png";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { toast, ToastContainer } from "react-toastify";
import DoctorDashboardSearch from "./doctorDashboardListSearch.jsx"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Imperson from "../image/inperson.png"
import Video from "../image/video.png"
import Text from "../image/chat.png"
import Medicalapp from "../image/medical-app.png"
import Patient from "../image/website.png"
import Community from "../image/community.png"

const DoctorPanel1 = () => {
    const navigate = useNavigate();
    const [siteWiseDataList, setSiteWiseDataList] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [siteId, setSiteId] = useState("")
    const [doctorName, setDoctorName] = useState("")
    const [siteName, setSiteName] = useState("")
    const [showMessage, setShowMessage] = useState("Please select site name first.")
    const [loading, setLoading] = useState(false);
    const headings = [
        { title: "In Person", image: Imperson },
        { title: "Video Chat", image: Video },
        { title: "Text Chat", image: Text },
        { title: "Patients Registered", image: Patient },
        { title: "Doctors Logged in", image: Community },
        { title: "Mobile App Doctors", image: Medicalapp }
    ];

    const handleDateChange = (date) => {
        setStartDate(moment(date).format("YYYY-MM-DD"));
        // console.log("Formatted Date:", moment(date).format("YYYY-MM-DD"));

    };
    useEffect(() => {
        siteId && getDoctorList(isChecked)
    }, [siteId]);

    const getDoctorList = (isCheck) => {
        const fromDate = new Date(startDate);
        const toDate = new Date(endDate);
        if (startDate && endDate) {
            if (fromDate >= toDate) {
                toast.warning("From Date must be earlier than To Date.");
                return;
            }
        }
        setLoading(true);
        let postData = {
            method: "POST",
            body: JSON.stringify({
                functionName: "siteWiseDataList",
                site_id: siteId,
                fromDate: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
                toDate: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
                flag: isCheck
            }),
        }
        // console.log(postData);
        awsAPIUrlcommon(postData).then((data) => {
            // console.log(data)
            if (data.success === "1") {
                // console.log(data.result)
                setSiteWiseDataList(data.result)
                // setLoading(false);
            }
            else {
                setSiteWiseDataList([])
                setShowMessage("No data found.")
            }

            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            toast.error("An error occurred while Getting Details");
        });
    }

    const ExportAllDataExcel = (exportData) => {
        if (exportData.length > 0) {
            const workbook = XLSX.utils.book_new();
            const sheets = [
                { name: "In Person", key: "inpersonData" },
                { name: "Video Chat", key: "videoAudioData" },
                { name: "Text Chat", key: "textData" },
                { name: "Patients Registered", key: "patientData" },
                { name: "Doctors Logged in", key: "appLoginDoctorData" },
                { name: "Mobile App Doctors", key: "appWebsiteDoctorData" }
            ];

            sheets.forEach(({ name, key }) => {
                let sheetData = exportData.map((item) => item?.[key] || []).flat();

                if (sheetData.length === 0) {
                    sheetData.push({ "": "No Data Available" });
                } else {
                    // ✅ Format date fields & ensure IDs are left-aligned
                    sheetData = sheetData.map((row) => {
                        const formattedRow = { ...row };
                        Object.keys(formattedRow).forEach((col) => {
                            const value = formattedRow[col];

                            // ✅ Check if value is a valid date string
                            if (value && moment(value, [moment.ISO_8601, "YYYY-MM-DDTHH:mm:ss.SSSZ"], true).isValid()) {
                                formattedRow[col] = moment(value).format("DD-MMM-YYYY hh.mm a");
                            }

                            // ✅ Force IDs (or numeric-like fields) to be left-aligned as text
                            if (typeof value === "number" || /^\d+$/.test(value)) {
                                formattedRow[col] = `${value}`;
                            }
                        });

                        return formattedRow;
                    });
                }

                const worksheet = XLSX.utils.json_to_sheet(sheetData);
                worksheet["!cols"] = Array(12).fill({ wch: 20 });

                // ✅ Apply left alignment to all cells
                Object.keys(worksheet).forEach((cell) => {
                    if (!cell.startsWith("!")) {
                        worksheet[cell].s = { alignment: { horizontal: "left" } };
                    }
                });

                XLSX.utils.book_append_sheet(workbook, worksheet, name);
            });

            // ✅ Generate the filename
            let fileName = "website-analyticsof-doctors.xlsx";
            if (startDate && endDate) {
                const formattedStartDate = moment(startDate).format("DD MMM YYYY");
                const formattedEndDate = moment(endDate).format("DD MMM YYYY");
                fileName = `website-analyticsof-doctors - ${formattedStartDate} - ${formattedEndDate}.xlsx`;
            }

            // ✅ Export the Excel file with styles
            const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array", cellStyles: true });
            saveAs(new Blob([excelBuffer], { type: "application/octet-stream" }), fileName);
        } else {
            toast.error("Website Analytics of Doctors List is empty!");
        }
    };

    return (
        <div className="purpleWrap bg-clr-wthHead">
            {
        // console.log("siteId---", siteId)
        }
            <ToastContainer />
            <Suspense fallback={<div></div>}>
                <section>
                    <Header />
                    {loading && <div className="loader d-block"></div>}
                    <div className="container mb-5">
                        <div>
                            <ul className="breadcrum-adminMangmnt">
                                <li>
                                    <a href="/admin/dashboardAdmin">Dashboard</a>
                                </li>
                                <li>
                                    <a className="current">Website Analytics of Doctors</a>
                                </li>
                            </ul>
                        </div>
                        <div className="head-hospMangmntList row mx-0">
                            Website Analytics of Doctors
                            {siteName && (
                                siteWiseDataList.length > 0 ? (
                                    <div className="col-12 col-lg-2 col-md-2 cursor-pointer text-right ml-auto">
                                        <img
                                            src={csv}
                                            alt="PDFIcon"
                                            className="img-fluid ml-1"
                                            width="25"
                                            height="25"
                                            title="Download Excel"
                                            onClick={() => ExportAllDataExcel(siteWiseDataList)}
                                        />
                                    </div>
                                ) : null
                            )}
                        </div>
                        <div className="row tableHeader-HspMangemnt table-headerDctrPanel tble-dctrpanel-mob
            justify-content-between" style={{ height: "auto" }}>
                            <div className="row col-md-8 ml-0 pl-2">
                                <DoctorDashboardSearch
                                    siteName={siteName} setSiteName={setSiteName} setSiteId={setSiteId} setDoctorName={setDoctorName} setStartDate={setStartDate} 
                                    setEndDate={setEndDate} setSiteWiseDataList={setSiteWiseDataList} setIsChecked={setIsChecked}/>
                                <div className="d-flex col-md-6 dctrpanel-headinput mb-1 mb-md-0 gap3">
                                    <DatePicker disabled={(siteName) ? false : true} selected={startDate} onChange={handleDateChange} className="form-control mb-0 borderRad0" placeholderText="From Date" showYearDropdown showMonthDropdown />
                                    <DatePicker disabled={(siteName) ? false : true} selected={endDate} onChange={(date) => setEndDate(date)} className="form-control mb-0 borderRad0" placeholderText="To Date" showYearDropdown showMonthDropdown />
                                    <button className="searchbtn-hspMng w-auto m-0 ml-3" type="button"
                                        onClick={() => { getDoctorList(isChecked) }}
                                        disabled={(siteName) ? false : true}>Search</button>
                                </div>
                            </div>
                            <div className="row col-md-4 justify-content-end mt-0">
                                <div className="d-flex align-items-center justify-content-center">
                                    <input type="checkbox" name="" id="" className="mr-2 cursor-pointer" checked={isChecked}
                                        onChange={(e) => {
                                            setIsChecked(e.target.checked);
                                            getDoctorList(e.target.checked);
                                        }}
                                        disabled={!siteName}
                                    />
                                    <span className="mt-0">
                                        Include all site</span>
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0">
{/* {console.log("siteWiseDataList---",siteWiseDataList)
} */}
                            {siteWiseDataList.length > 0 ? (
                                <div className="table-responsive">


                                    <div className="row m-0 analytics_box my-4">
                                        {siteWiseDataList.map((item, index) => (
                                            <div class="col-lg-3 col-md-6 mb-2" key={item.inpersonData}>
                                                <div class="card">
                                                    <div class="card-body">
                                                        <div class="analytics_user analytics_blue">
                                                            <img
                                                                src={headings[index]?.image || Imperson}
                                                                alt={headings[index]?.title || "Default"}
                                                                className="img-fluid web-analytics-db"
                                                            />
                                                        </div>
                                                        <div class="analytics_userdata">
                                                            <div class="userdata_head"> {item.totalCount}</div>
                                                            <div className="userdata_content">{headings[index]?.title || "Other"}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            ) : (
                                <p className="error-msg text-center w-100 mt-4">{showMessage}</p>
                            )}

                        </div>
                    </div>

                </section>
            </Suspense>
            <Suspense fallback={<div></div>}>
                <section>
                    <Footer />
                </section>
            </Suspense>


        </div >
    );
};

export default DoctorPanel1;