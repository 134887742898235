import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import Close from "../image/icons/close-mark.png";
import { apiCalling, awsAPIUrlcommon } from "../apiService";
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AWS from "aws-sdk";
import { method } from "lodash";
import * as XLSX from "xlsx";
import $ from "jquery";
import PurpleLogoImg from "../image/media/purple-health.png"
import UploadLogoImg from "../image/media/upload.png"
import imgPrvw from "../image/icons/image-preview.png";
import { MdHeight } from "react-icons/md";
import { uploadToS3 } from "../redux/actions/UploadFile";

const DoctorDashboardExcelPopup = ({ showPopUpData, cancelPopup, journalListing }) => {
    const [filteredData, setFilteredData] = useState([]);
    const [imageDeatils, setimageDeatils] = useState({
        AWSAccessKeyId: "",
        s3bucketAccessKey: "",
        region: "us-east-1",
        bucket: "",
        bucketFolder: "",
    })
    const [inputValue, setInputValue] = useState('');
    const [siteList, setsiteList] = useState([]);
    const [contactName, setContactName] = useState("")
    const [contactMobile, setContactMobile] = useState("")
    const [contactEmail, setContactEmail] = useState("")
    const [companyLogo, setCompanyLogo] = useState("")
    const [projectLogo, setProjectLogo] = useState("")
    const [loading, setLoading] = useState(false);
    const [purpleLogoFlag, setPurpleLogoFlag] = useState(false);
    const [imagePathData, setImagePathData] = useState("");
    const [dropDownFlag, setdropDownFlag] = useState(false);
    const [siteId, setSiteId] = useState(false);
    const [showList, setShowList] = useState(false);

    useEffect(() => {
        // imageUploadHandler();
        setImagePathData(
            "rhealthfiles" + "/" +
            "rhealthfiles" + "/" + "RHADashboard"
        );
    }, [])

    const getSiteDetails = () => {
        const siteDetail = {
            method: "POST",
            body: JSON.stringify({
                functionName: "getAllSiteDetails",
            }),
        };
        $(".loader").show();
        apiCalling(siteDetail).then((data) => {
            if (data.success === "1") {
                setsiteList(data.siteData)
                $(".loader").hide();
                if (window.localStorage.getItem("role") === "ROLE_GROUPHOSPITALADMIN") {
                    const filteredResult = data.siteData.filter(item => {
                        return item.siteId.toString() === window.localStorage.getItem("siteId");
                    })
                    setFilteredData(filteredResult);
                    // setSiteId(filteredResult && filteredResult[0] && filteredResult[0].siteId)
                    setInputValue(filteredResult && filteredResult[0] && filteredResult[0].siteName)
                    setShowList(false);
                    setdropDownFlag(true)
                }
            } else { $(".loader").hide(); }
        }).catch()
    }
    useEffect(() => {
        getSiteDetails()
        // imageUploadHandler();
    }, [])
    useEffect(() => {
        if (showPopUpData.length != 0) {
            setContactName(showPopUpData.contact_person_name)
            setContactEmail(showPopUpData.contact_person_email)
            setContactMobile(showPopUpData.contact_person_mobile)
            setPurpleLogoFlag(showPopUpData.purple_logo_required)
            setCompanyLogo(showPopUpData.company_logo)
            setProjectLogo(showPopUpData.project_logo)
            setSiteId(showPopUpData.site_id)
            const companyLogoUrl = showPopUpData.company_logo ? 
            `https://rhealthfiles.s3.amazonaws.com/${imagePathData}/${showPopUpData.company_logo}` : 
            UploadLogoImg;
            setCompanyLogo(companyLogoUrl)
            const projectLogoUrl = showPopUpData.project_logo ? 
            `https://rhealthfiles.s3.amazonaws.com/${imagePathData}/${showPopUpData.project_logo}` : 
            UploadLogoImg;
            setCompanyLogo(showPopUpData.company_logo)
            setProjectLogo(showPopUpData.project_logo)
            const imgCompanyLogo = document.getElementById('companyLogo');
            imgCompanyLogo.src =companyLogoUrl;
            const imgProjectLogo = document.getElementById('ProjectLogo');
            imgProjectLogo.src =projectLogoUrl;
        }
    }, [imagePathData, showPopUpData])

    // const imageUploadHandler = () => {
    //     const apiJson = {
    //         method: "POST",
    //         body: JSON.stringify({
    //             functionName: "imagePath",
    //         }),
    //     };

    //     apiCalling(apiJson).then((data) => {
    //         if (data.success === "1") {
    //             setimageDeatils((prev) => ({
    //                 ...prev,
    //                 s3bucketAccessKey: data.configPathDetails[0].s3bucketAccessKey,
    //                 AWSAccessKeyId: data.configPathDetails[0].s3bucketSecretKey,
    //                 bucket: data.configPathDetails[0].PurpleHealthS3bucket,
    //                 bucketFolder: data.configPathDetails[0].S3BucketMainFolder,
    //             }))
    //             const bucketPath =
    //                 data.configPathDetails[0].s3BucketRelianceAddressPath +
    //                 "/" +
    //                 data.configPathDetails[0].S3BucketMainFolder +
    //                 "/" +
    //                 "doctorDashboard" +
    //                 "/";
    //             setImagePathData(bucketPath);
    //         }
    //     }).catch((error) => {
    //         toast.error("An error occurred while getting details");
    //     });
    // };
    const handleFileUpload = (flag, event) => {
        const file = event.target.files[0];
        if (file) {
            // $(".loader").show();
            setLoading(true);
            fileUpload(flag, file);
        } else {
            alert("Please Choose a file for upload");
            return false;
        }
    };
    // const fileUpload = (flag, file) => {
    //     const S3BucketMainFolder = "";
    //     let fileName = file.name
    //     let last = fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length);
    //     if (flag === "excel") {
    //         fileName = `doctor-dashboard-report-${new Date().getTime()}.${last}`;
    //     }
    //     if (flag === "company_logo") {
    //         fileName = `${flag}-${new Date().getTime()}.${last}`;
    //         setCompanyLogo(fileName)
    //         const imgPreview = document.getElementById('companyLogo');
    //         imgPreview.src = URL.createObjectURL(file);
    //         imgPreview.onload = () => {
    //             URL.revokeObjectURL(imgPreview.src); // Free memory
    //         };
    //     }
    //     if (flag === "Project_logo") {
    //         fileName = `${flag}-${new Date().getTime()}.${last}`;
    //         setProjectLogo(fileName)
    //         const imgPreview = document.getElementById('ProjectLogo');
    //         imgPreview.src = URL.createObjectURL(file);
    //         imgPreview.onload = () => {
    //             URL.revokeObjectURL(imgPreview.src); // Free memory
    //         };
    //     }

    //     const fullFilePath = S3BucketMainFolder + fileName;
    //     AWS.config.update({
    //         accessKeyId: imageDeatils.s3bucketAccessKey,
    //         secretAccessKey: imageDeatils.AWSAccessKeyId,
    //         region: imageDeatils.region,
    //     });

    //     const bucketParams = {
    //         Bucket: `${imageDeatils.bucket}/${imageDeatils.bucketFolder}/doctorDashboard`,
    //     };
    //     const s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    //     s3.upload(
    //         {
    //             Key: fullFilePath,
    //             Body: file,
    //             ACL: "public-read",
    //         },
    //         (err, data) => {
    //             if (err) {
    //                 alert("There was an error uploading your Excel: ", err.message);
    //             } else if (data) {
    //                 // $(".loader").hide();
    //                 setLoading(false);
    //             }
    //         }
    //     );
    // };

    const fileUpload = async (flag, file) => {
        try {
            let fileName = file.name;
            let last = fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length);

            if (flag === "excel") {
                fileName = `doctor-dashboard-report-${new Date().getTime()}.${last}`;
            } else if (flag === "company_logo") {
                fileName = `${flag}-${new Date().getTime()}.${last}`;
                setCompanyLogo(fileName);
                const imgPreview = document.getElementById('companyLogo');
                imgPreview.src = URL.createObjectURL(file);
                imgPreview.onload = () => {
                    URL.revokeObjectURL(imgPreview.src);
                };
            } else if (flag === "Project_logo") {
                fileName = `${flag}-${new Date().getTime()}.${last}`;
                setProjectLogo(fileName);
                const imgPreview = document.getElementById('ProjectLogo');
                imgPreview.src = URL.createObjectURL(file);
                imgPreview.onload = () => {
                    URL.revokeObjectURL(imgPreview.src);
                };
            }
            const s3Url = await uploadToS3(file, imagePathData, fileName.replaceAll(/ /g, ""), "rhealthfiles");

            if (s3Url) {
                setLoading(false);
                return s3Url;
            }
        } catch (error) {
            alert("There was an error uploading your file: " + error.message);
            setLoading(false);
        }
    };

    const validateForm = () => {
        const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6}$/i;
        const phonePattern = /^[0-9]{10}$/;

        if (!siteId) return "Please select a site";
        if (!companyLogo) return "Please upload a company logo";
        if (!projectLogo) return "Please upload a project logo";
        if (!contactName) return "Please enter contact name";
        if (!emailPattern.test(contactEmail)) return "Invalid email address";
        if (!phonePattern.test(contactMobile)) return "Invalid mobile number";

        return null;
    };
    const fileSave = async () => {
        if (showPopUpData.length != 0) {
            if ((siteId === showPopUpData.site_id) && (companyLogo === showPopUpData.company_logo) &&
                (projectLogo === showPopUpData.project_logo) &&
                (contactName === showPopUpData.contact_person_name) &&
                (contactMobile === showPopUpData.contact_person_mobile) &&
                (contactEmail === showPopUpData.contact_person_email) &&
                (purpleLogoFlag === showPopUpData?.purple_logo_required)
            ) {
                toast.warning("No changes have been updated.");
                return false
            }
        }
        const errorMessage = validateForm();
        if (errorMessage) {
            toast.warning(errorMessage);
            return;
        }

        try {
            setLoading(true);
            const postData = {
                method: "POST",
                body: JSON.stringify({
                    functionName: "saveDoctorDashboardSettings",
                    id: showPopUpData?.id || "",
                    site_id: siteId,
                    company_logo: companyLogo,
                    project_logo: projectLogo,
                    contact_person_name: contactName,
                    contact_person_email: contactEmail,
                    contact_person_mobile: contactMobile,
                    purple_logo_required: purpleLogoFlag ? purpleLogoFlag : false,
                    timestamp: moment().valueOf(),
                    user_id: window.localStorage.getItem("userId"),
                }),
            };

            const data = await awsAPIUrlcommon(postData);
            if (data.success === "1") {
                toast.success("Settings saved successfully");
                journalListing();
                clearData();
            } else {
                toast.error(data.errorMessage);
            }
        } catch (error) {
            toast.error("Error saving settings");
        } finally {
            setTimeout(() => {
                cancelPopup(false);
                setLoading(false);
            }, 500);
        }
    };
    // const fileSave = () => {
    //     const phonePattern = /^[0-9]{10}$/;
    //     const email = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6}$/i;
    //     if (showPopUpData.length != 0) {
    //         if ((siteId === showPopUpData.site_id) && (companyLogo === showPopUpData.company_logo) &&
    //             (projectLogo === showPopUpData.project_logo) &&
    //             (contactName === showPopUpData.contact_person_name) &&
    //             (contactMobile === showPopUpData.contact_person_mobile) &&
    //             (contactEmail === showPopUpData.contact_person_email) &&
    //             (purpleLogoFlag === showPopUpData.purple_logo_required)
    //         ) {
    //             toast.warning("No changes have been updated.");
    //             return false
    //         }
    //     }

    //     // if (!companyLogo && !projectLogo && !fileName && !contactName && !contactMobile && !contactEmail) {
    //     //   toast("Please fill all field.")
    //     //   return false;
    //     // }
    //     if (!showPopUpData.id && (siteId === false || inputValue === "")) {
    //         toast("Please select Site First to save settings.")
    //         return false;
    //     }
    //     else if (!companyLogo) {
    //         toast("Please select Company Logo")
    //         return false;
    //     }
    //     else if (!projectLogo) {
    //         toast("Please select Project Logo")
    //         return false;
    //     }
    //     if (!contactName) {
    //         toast("Please enter Name")
    //         return false;
    //     }
    //     if (!email.test(contactEmail)) {
    //         toast("Email id is not valid.")
    //         return false;
    //     }

    //     else if (!email.test(contactEmail)) {
    //         toast("Email id is not valid.")
    //         return false;
    //     }
    //     else if (!phonePattern.test(contactMobile)) {
    //         toast("Mobile Number is not valid.")
    //         return false;
    //     }

    //     else {
    //         let postData = {
    //             method: "POST",
    //             body: JSON.stringify({
    //                 "functionName": "saveDoctorDashboardSettings",
    //                 "id": showPopUpData?.id ? showPopUpData.id : "",
    //                 "site_id": siteId,
    //                 "company_logo": companyLogo,
    //                 "project_logo": projectLogo,
    //                 "contact_person_name": contactName,
    //                 "contact_person_email": contactEmail,
    //                 "contact_person_mobile": contactMobile,
    //                 "purple_logo_required": purpleLogoFlag,
    //                 "timestamp": moment(new Date()).valueOf(),
    //                 "user_id": window.localStorage.getItem("userId"),
    //             }
    //             )
    //         }
    //         awsAPIUrlcommon(postData).then((data) => {
    //             $(".loader").hide();
    //             setTimeout(() => {
    //                 cancelPopup(false)
    //                 setLoading(false);
    //             }, 500);
    //             if (data.success === "1") {
    //                 toast.success("File uploaded successfully")
    //                 journalListing()
    //                 clearData()
    //             }
    //             else {
    //                 toast.error(data.errorMessage)
    //                 //   props.setExcelPopupFlag(false)
    //             }
    //         }).catch((error) => {
    //             $(".loader").hide();
    //             setLoading(false);
    //             toast.error("An error occurred while uploading...");
    //         }).finally()
    //     }

    // }


    const clearData = () => {
        setCompanyLogo("")
        setProjectLogo("")
        setContactMobile("")
        setContactEmail("")
        setContactName("")
        // props.setFileId("")
        // props.setFileData([])
        if (showPopUpData.length != 0) {
            //   props.fileList("", 0)
        }
        const companyLogo = document.getElementById('companyLogo');
        companyLogo.src = imgPrvw

        const projectLogo = document.getElementById('ProjectLogo');
        projectLogo.src = imgPrvw
    }
    const handleInputChange = (event) => {
        const inputValue = event.target.value.toLowerCase();
        setInputValue(inputValue);
        const filteredResult = siteList.filter(item => {
            return item.siteName.toLowerCase().includes(inputValue);
        });
        setFilteredData(filteredResult);
        setShowList(true);
    };
    const handleChange = (item) => {
        setInputValue(item.siteName); // Set the input value to the siteName
        setShowList(false);
        setSiteId(item.siteId)

    };
    return (
        <div className="popup-overlay modal_overlay">
            <ToastContainer />
            {loading && <div className="loader d-block"></div>}
            <div className="popup-contentDiv popup-content-padd pt-3">
                <div className="modal-popup">
                    <span className="closepopupqual close-popup-top" id="txtCancel"
                        onClick={(e) => {
                            cancelPopup(false)
                            clearData()
                        }}>
                        <img src={Close} alt="Close" className="img-fluid" width="20" />
                    </span>
                    <div className="content" style={{ minHeight: "300px" }}>
                        <div className="HosptlMangemntTable">

                            <div className="row mb-3">
                                <div className="col-md-12 head-hospMangmntList mb-0">Upload Data</div>
                            </div>
                            <hr></hr>
                            {!showPopUpData.id ?
                                <div className="row mb-3">
                                    <div className="col-md-4"><label className="mb-0"> Site Details</label></div>
                                    <div className="col-md-3 col-lg-4 div">
                                        <input
                                            type="text"
                                            // disabled={dropDownFlag}
                                            value={inputValue}
                                            onChange={handleInputChange}
                                            placeholder="Type to filter..."
                                            className="form-control"
                                        />
                                        {showList && inputValue && (
                                            <ul className="input-design-journal">
                                                {filteredData.map((item, index) => (
                                                    <li className="py-0" key={index}>
                                                        <button className="btn input-design-btn" onClick={() => handleChange(item)}>
                                                            {item.siteName}
                                                        </button>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                </div> : ""}
                            <div className="row mb-3">
                                <div className="col-md-4"><label className="mb-0">Upload Company Logo</label></div>
                                <div className="col-md-4">
                                    <div className="hspinfo-btnupload mb-auto hspinfo-btnupload-w">
                                        <span>Upload</span>
                                        <input type="file" className="hspinfoInputfieldupload" accept=".jpg,.jpeg,.png"
                                            onChange={(e) => { handleFileUpload("company_logo", e) }} />
                                    </div>
                                    <div className="fileUpload-img fileUpload-imgreptop">
                                        <img src={imgPrvw} onError={(e) => e.target.src = UploadLogoImg} id="companyLogo" width="50" alt="upload"
                                            style={{ objectFit: "contain", height: "auto", objectPosition: "bottom" }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-4"><label className="mb-0">Upload Project Logo</label></div>
                                <div className="col-md-4">
                                    <div className="hspinfo-btnupload mb-auto hspinfo-btnupload-w">
                                        <span>Upload</span>
                                        <input type="file" className="hspinfoInputfieldupload" accept=".jpg,.jpeg,.png"
                                            onChange={(e) => { handleFileUpload("Project_logo", e) }} />
                                    </div>
                                    <div className="fileUpload-img fileUpload-imgreptop">
                                        <img src={imgPrvw} onError={(e) => e.target.src = UploadLogoImg} id="ProjectLogo" width="50" alt="upload"
                                            style={{ objectFit: "contain", height: "auto", objectPosition: "bottom" }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-md-4">
                                    <label className="mb-0">contact Person Name<span className="text-danger">*</span></label>
                                </div>
                                <div className="col-md-8">
                                    <input type="text" className="form-control" value={contactName}
                                        onChange={(e) => { setContactName(e.target.value) }} />
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-md-4">
                                    <label className="mb-0">contact Person Email Id<span className="text-danger">*</span></label>
                                </div>
                                <div className="col-md-8">
                                    <input type="text" className="form-control" value={contactEmail}
                                        onChange={(e) => { setContactEmail(e.target.value) }} />
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-md-4">
                                    <label className="mb-0">contact Person Mobile Number<span className="text-danger">*</span></label>
                                </div>
                                <div className="col-md-8">
                                    <input type="number" className="form-control" value={contactMobile}
                                        onChange={(e) => {
                                            if (e.target.value.length <= 10) {
                                                setContactMobile(e.target.value);
                                            }
                                        }} />
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-md-4"><label className="mb-0">Purple Logo </label></div>
                                <div className="col-md-4 d-flex">
                                    <input
                                        type="checkbox"
                                        className="checkbox-clr mr-3"
                                        checked={purpleLogoFlag}
                                        onChange={() => setPurpleLogoFlag(!purpleLogoFlag)}
                                    />
                                    <div className="fileUpload-img fileUpload-imgreptop">
                                        <img src={PurpleLogoImg} id="Purple Logo" width="80" alt="upload"
                                            style={{ objectFit: "contain", height: "auto", objectPosition: "bottom" }} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 text-right pl-0 pt-4">
                                <input type="submit" className="smssetng-svebtn" value="Save" onClick={() => { fileSave() }}
                                    id="saveBtn" />
                                <input type="submit" className="smssetng-cancelbtn" value="Cancel" onClick={() => cancelPopup(false)} />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DoctorDashboardExcelPopup;
