import React, { Component, Suspense } from "react";
import HospitalInfomation from "./hospitalInformation";
import GoogleMap from "./GoogleMap";
import HospitalworkDetails from "./hospitalworkDetails";
import HospitalSpecialisation from "./hospitalSpecialisation";
import BankAccountdetails from "./bankAccountdetails";
import ConsultingTime from "./consultingTime";
import VisitingTime from "./visitingTime";
import SuperAdmin from "./superAdmin";
import Servicedetails from "./servicedetails";
import HospitalAdmindetails from "./hospitalAdmindetails";
import { apiCalling } from "../../apiService";
import $ from "jquery";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Link } from "react-router-dom";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import AWS from "aws-sdk";
import { uploadToS3 } from "../../redux/actions/UploadFile";
var s3;
var fileNameUploadSignature;
var isPrNumberEnabledFlag = true;
var isPrNumberChangeValue;
const hospitalId = JSON.parse(window.localStorage.getItem("dataPassing"))
export default class AddnewHsp extends Component {
  state = {
    slug: "",
    seoTitle: "",
    seoKeywords: "",
    seoDescription: "",
    backgroundColour: "#FFFFFF",
    foregroundColour: "#FFFFFF",
    buttonColour: "#FFFFFF",
    fontColour: "#FFFFFF",
    hospitalId: "",
    assosiationFullDataList: [],
    HospitalworkDetailsArray: [],
    hospitalDataArray: [],
    specializationIdEdit: [],
    hospitalVisitingTimeEdit: [],
    hospitalConsultingTimeEdit: [],
    hospitalAccreditationId: [],
    fileSignature: null,
    CredentialData: [],
    getTypeData: [],
    ClassificataionData: [],
    getPaymentTypeData: [],
    timeFilterData: [],
    stateListData: [],
    cityListData: [],
    countryData: [],
    subCitylistData: [],
    categoryData: [],
    imagepathDemo: "",
    longitude: "",
    latitude: "",
    googleAddressPin: "",
    associationIds: "",
    groupHospitalAdminId: "",
    // s3bucketAccessKey: "",
    // s3bucketSecretKey: "",
    // s3BucketMainFolder: "",
    // s3BucketAddressPath: "",
    // s3BucketDoctorImages: "",
    // s3Bucket: "",
    hospitalName: "",
    hospitalEmail: "",
    hospitalAddress: "",
    cmbCountry: "",
    pinCode: "",
    deactiveDate: "",
    phone: "",
    primaryEmailIds: "",
    categoryId: "2",
    credentialId: "1",
    typeId: "2",
    paymentTypeIds: [27],
    workTimeFilter: [1, 2],
    classificationIds: [1],
    uploadedImageName: "",
    uploadedImageNameWithPath: "",
    stateId: "0",
    cityId: "0",
    subCity: "0",
    specializationData: [],
    servicesData: [],
    hospitalServiceDetailsArray: [],
    hospitalImage: "",
    hospitalImagePath: "",
    hospitalAdminId: "",
    isLoading: true
  };

  changeHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  changeHandlerMulti = (e) => {
    const selectedOptions = [...e.target.selectedOptions].map(
      (opt) => opt.value
    );
    this.setState({ [e.target.id]: selectedOptions });
  };
  getCategory = () => {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getCategoryData",
        categoryId: "",
      }),
    };
    apiCalling(apiJson).then((data) => {
      this.setState(
        {
          categoryData: data,
        }
        // ,
        // () => {
        //   $('#categoryId option[value="2"]').attr("selected", true);
        // }
      );
    });
  };
  listing = () => {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getDropdownLists",
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState(
          {
            // s3bucketAccessKey: data.result[0].s3bucketAccessKey,
            // s3bucketSecretKey: data.result[0].s3bucketSecretKey,
            // s3BucketMainFolder: data.result[0].S3BucketMainFolder,
            // s3BucketAddressPath: data.result[0].s3BucketAddressPath,
            // S3BucketHospitalImages: data.result[0].S3BucketHospitalImages,
            // s3Bucket: data.result[0].s3Bucket,
          },
          () => {
            AWS.config.update({
              accessKeyId: this.state.s3bucketAccessKey,
              secretAccessKey: this.state.s3bucketSecretKey,
              region: "us-east-1",
            });
            var bucketParams = {
              Bucket: this.state.s3Bucket,
            };
            s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
          }
        );
      }
    });
  };
  getHospitalDropdown = () => {
    // $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getHospitalDropdown",
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        this.setState(
          {
            CredentialData: data.hospitalData[0].CredentialData,
            getTypeData: data.hospitalData[0].getTypeData,
            ClassificataionData: data.hospitalData[0].ClassificataionData,
            getPaymentTypeData: data.hospitalData[0].getPaymentTypeData,
            timeFilterData: data.hospitalData[0].timeFilterData,
            imagepathDemo:
              "https://purplehealthfiles.s3.amazonaws.com/test/hospitalimages/",
            specializationData: data.hospitalData[0].specializationData,
            servicesData: data.hospitalData[0].serviceData,
            assosiationFullDataList: data.hospitalData[0].associationData
          }
          /* ,() => {
            // $('#credentialId option[value="1"]').attr("selected", true);
            // $('#typeId option[value="2"]').attr("selected", true);
            // $('#workTimeFilter option[value="1"]').attr("selected", true);
            // $('#workTimeFilter option[value="2"]').attr("selected", true);
            // $('#paymentTypeIds option[value="27"]').attr("selected", true);
            // $('#classificationIds option[value="1"]').attr("selected", true);
            if (this.props.hospitalData1.length > 0) {
              // $("#hospitalId").val(this.props.hospitalId);
              //  $("#hospitalName").val(this.props.hospitalData1[0].name);
              //  $("#hospitalEmail").val(this.props.hospitalData1[0].emailId);
              //  $("#hospitalAddress").val(this.props.hospitalData1[0].address);
              //  $("#cmbCountry").val(this.props.hospitalData1[0].countryId);
              //  $("#pinCode").val(this.props.hospitalData1[0].pinCode);
              //  $("#phone").val(this.props.hospitalData1[0].phone);
              //  $("#primaryEmailIds").val(this.props.hospitalData1[0].emailId);
              //  $("#categoryId").val(this.props.hospitalData1[0].categoryId);
              //  $("#credentialId").val(this.props.hospitalData1[0].credentialId);
              //  $("#typeId").val(this.props.hospitalData1[0].typeId);
              $("#paymentTypeIds").val(_paymentTypeIdEdit);
              $("#workTimeFilter").val(this.props.timeFilterEdit);
              if (this.state.uploadedImageName !== "") {
                $("#imgDiv").show();
                $("#image").attr(
                  "src",
                  this.state.uploadedImageNameWithPath
                );
              }
              this.getCountryList(
                this.state.stateId,
                this.state.cityId,
                this.state.subCity
              );
            } else {
              this.getCountryList();
            }
          }*/
        );
      }
    });
  };
  getCountryList = (stateId, cityId, subCity) => {
    let _stateId =
      stateId === "" || stateId === undefined ? this.state.stateId : stateId;
    let _cityId =
      cityId === "" || cityId === undefined ? this.state.cityId : cityId;
    let _subCity =
      subCity === "" || cityId === undefined ? this.state.subCity : subCity;
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getCountryList",
        countryId: "1",
        stateId: _stateId === "0" ? "" : _stateId,
        cityId: _cityId === "0" ? "" : _cityId,
      }),
    };
    // $(".loader").show();
    apiCalling(apiJson).then((data) => {
      this.setState(
        {
          countryData: data.countryList,
          stateListData: data.stateList,
          cityListData: data.cityList,
          subCitylistData: data.subCitylist,
        },
        () => {
          $("#cmbState").val(
            _stateId === undefined || _stateId === null || _stateId === ""
              ? "0"
              : _stateId
          );
          $("#cmbCity").val(
            _cityId === undefined || _cityId === null || _cityId === ""
              ? "0"
              : _cityId
          );
          $("#cmbSubCity").val(
            _subCity === undefined || _subCity === null || _subCity === ""
              ? "0"
              : _subCity
          );
          // $(".loader").hide();
        }
      );
    });
  };
  uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
  hospitalinformation() {
    var image = "";
    if (this.state.fileSignature !== null) {
      this.fileUpload(this.state.fileSignature, "attachImg");
      image = fileNameUploadSignature;
    } else {
      image = fileNameUploadSignature;
    }
    return image;
  }
  fileUpload = async (file, mode) => {
    // let reader = new FileReader();
    // let s3BucketMainFolder1 =
    //   this.state.s3BucketMainFolder + "/" + this.state.S3BucketHospitalImages;
    let fileName = this.uuidv4() + file.name;
    this.setState({
      hospitalImage: fileName,
    });
    // let fullFilePath = s3BucketMainFolder1 + "/" + fileName;
    // fileNameUploadSignature = fullFilePath;

    if (mode === "attachImg") {
      fileNameUploadSignature = fileName;
    }

    try {
      const s3Url = await uploadToS3(file, `${process.env.REACT_APP_S3BucketMainFolder}`, fileName.replaceAll(/ /g,""))
      if (s3Url) {
        fileNameUploadSignature = s3Url
      }
    } catch (error) {
      console.error("S3 upload error:", error);
      throw error;
    }
    // s3.upload(
    //   {
    //     Key: fullFilePath,
    //     Body: file,
    //     ACL: "public-read",
    //   },
    //   (err, data) => {
    //     if (err) {
    //       alert("Error uploading Image");
    //     } else {
    //       //return fullFilePath;
    //       fileNameUploadSignature = data.Location;
    //     }
    //   }
    // );
  }
  componentDidMount() {

    this.getCategory();
    this.listing();
    this.getHospitalDropdown();
    if (hospitalId) {
      // const { hospitalId } = this.props.location.state;
      if (hospitalId > 0) {
        this.editHospitalData(hospitalId);
      }
      this.setState({
        hospitalId: hospitalId,
      });
    } else {
      this.getCountryList();
    }

  }
  editHospitalData = (hospitalId) => {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getHospitalDetails",
        hospitalId: hospitalId,
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        let insuranceIdEdit = [];
        let accreditationIdEdit = [];
        let timeFilterEdit = data?.hospitalData[0]?.timeFilter ? data?.hospitalData[0]?.timeFilter?.split(",") : [1, 2];
        let paymentTypeIdEdit = [];
        let hospitalVisitingTimeEdit = [];
        let hospitalConsultingTimeEdit = [];
        let hospitalServiceDetailsArray = [];
        data.hospitalData[0].hospitalInsuranceId.map((data) => {
          insuranceIdEdit.push(data.insuranceId);
        });
        data.hospitalData[0].hospitalAccreditationId.map((data) => {
          accreditationIdEdit.push(data.accreditationId);
        });

        data.hospitalData[0].paymentTypeId?.map((data) => {
          paymentTypeIdEdit.push(data.paymentTypeId);
        });

        data.hospitalData[0].hospitalVisitingTime.map((data) => {
          let vt = data.visitingTime.split("-");
          hospitalVisitingTimeEdit.push({
            visitingTimeFrom: vt[0],
            visitingTimeTo: vt[1],
          });
        });
        data.hospitalData[0].hospitalConsultingTime.map((data) => {
          let ct = data.consultingTime.split("-");
          hospitalConsultingTimeEdit.push({
            consultingTimeFrom: ct[0],
            consultingTimeTo: ct[1],
          });
        });
        data.hospitalData[0].hospitalServiceDetails.map((data) => {
          let st = data.hospitalTiming.split("-");
          hospitalServiceDetailsArray.push({
            hospitalServiceId: data.hospitalServiceId,
            servicesTimeFrom: st[0],
            servicesTimeTo: st[1],
          });
        });
        let officeHoursList = [
          {
            sundayTo: "0",
            sundayFrom: "0",
            mondayTo: "0",
            mondayFrom: "0",
            tuesdayTo: "0",
            tuesdayFrom: "0",
            wednesdayTo: "0",
            wednesdayFrom: "0",
            thursdayTo: "0",
            thursdayFrom: "0",
            fridayTo: "0",
            fridayFrom: "0",
            saturdayTo: "0",
            saturdayFrom: "0",
          },
        ];
        this.setState({
          slug: data.hospitalData[0].slug,
          seoTitle: data.hospitalData[0].seoTitle,
          seoKeywords: data.hospitalData[0].seoKeywords,
          seoDescription: data.hospitalData[0].seoDescription,
          hospitalDataArray: data.hospitalData,
          hospitalName: data.hospitalData[0].name.replaceAll("''", "'"),
          hospitalEmail: data.hospitalData[0].emailId,
          hospitalAddress: data.hospitalData[0].address,
          hospitalImage: data.hospitalData[0].uploadedImageName,
          hospitalImagePath: data.hospitalData[0].uploadedImageNameWithPath,
          pinCode: data.hospitalData[0].pinCode,
          phone: data.hospitalData[0].phone,
          primaryEmailIds: data.hospitalData[0].primaryEmailIds,
          deactiveDate: data.hospitalData[0].deactivateDate,
          categoryId: data.hospitalData[0].categoryId,
          credentialId: data.hospitalData[0].credentialId,
          typeId: data.hospitalData[0].typeId,
          paymentTypeIds: paymentTypeIdEdit.length ? paymentTypeIdEdit : [27],
          workTimeFilter: timeFilterEdit,
          cmbCountry: data.hospitalData[0].countryId,
          stateId: data.hospitalData[0].stateId,
          cityId: data.hospitalData[0].cityId,
          subCity: data.hospitalData[0].subCity,
          HospitalworkDetailsArray:
            data.hospitalData[0].hospitalOfficeHours.length > 0
              ? data.hospitalData[0].hospitalOfficeHours
              : officeHoursList,
          specializationIdEdit: data.hospitalData[0].specializationId,
          hospitalServiceDetailsArray: hospitalServiceDetailsArray,
          hospitalConsultingTimeEdit: hospitalConsultingTimeEdit,
          hospitalVisitingTimeEdit: hospitalVisitingTimeEdit,
          accreditationIdEdit: accreditationIdEdit,
          hospitalAccreditationId: data.hospitalData[0].hospitalAccreditationId,
          insuranceIdEdit: insuranceIdEdit,
          latitude: data.hospitalData[0].latitude,
          longitude: data.hospitalData[0].longitude,
          googleAddressPin: data.hospitalData[0].googleAddressPin,
          hospitalAdminId: data.hospitalData[0].hospitalAdminId,
        });
        this.getCountryList(
          data.hospitalData[0].stateId,
          data.hospitalData[0].cityId,
          data.hospitalData[0].subCity
        );
        // setTimeout(() => {
        //   $(".loader").hide();
        //   this.setState({ isLoading: false });
        // }, 30000);

      } else {
        // $(".loader").hide();
      }
    });
  };
  isEmail = (email) => {
    var isMailOk = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
      email
    );
    return isMailOk;
  };
  saveHospital = () => {
    let specialisations = this.state.specializationData;
    let servicedetailsData = this.state.hospitalServiceDetailsArray;
    let visitingArrayDetailsData = this.state.hospitalVisitingTimeEdit;
    // var visitingArrayDetailsData = this.refs.visitingTimeElement.VisitingTime();
    // let consultingArrayDetailsData =
    //   this.refs.consultingTimeElement.consultingArrayDetails();
    let consultingArrayDetailsData = this.state.hospitalConsultingTimeEdit;
    // var hospitalImage =
    //   this.refs.hospitalInformationElement.hospitalinformation();
    // this.hospitalinformation();
    var phoneno = /^\d{10}$/;
    var specialisationsSelect = [];
    for (var s = 0; s < specialisations.length; s++) {
      if (
        $("#chkSpecialisation" + specialisations[s].specializationId).is(
          ":checked"
        ) === true
      ) {
        var a = specialisations[s].specializationId;
        specialisationsSelect.push(a);
      }
    }
    var flag = true;
    //var email = $("#txtEmail").val()
    if ($("#hospitalName").val() === "") {
      alert("Hospital Name Required");
      setTimeout(() => {
        $("#hospitalName").focus();
      }, 10);
      flag = false;
    } else if ($("#hospitalEmail").val() === "") {
      alert("Hospital Email Required");
      setTimeout(() => {
        $("#hospitalEmail").focus();
      }, 10);
      flag = false;
    } else if (this.isEmail($("#hospitalEmail").val()) === false) {
      alert("Invalid Hospital Email id");
      setTimeout(() => {
        $("#hospitalEmail").focus();
      }, 10);
      flag = false;
    } else if ($("#hospitalAddress").val() === "") {
      alert("Hospital Address Required");
      setTimeout(() => {
        $("#hospitalAddress").focus();
      }, 10);
      flag = false;
    } else if ($("#cmbCountry").val() === "") {
      alert("Country Required");
      setTimeout(() => {
        $("#cmbCountry").focus();
      }, 10);
      flag = false;
    } else if ($("#stateId").val() === "0") {
      alert("State Required");
      setTimeout(() => {
        $("#stateId").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#cityId").val() === "0") {
      alert("City Required");
      setTimeout(() => {
        $("#cityId").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#pinCode").val() === "") {
      alert("Pin Code Required");
      setTimeout(() => {
        $("#pinCode").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#phone").val() === "") {
      alert("Phone Number Required");
      setTimeout(() => {
        $("#phone").focus().mousedown();
      }, 10);
      flag = false;
    } else if (!$("#phone").val().match(phoneno)) {
      alert("Please enter valid Mobile Number");
      setTimeout(() => {
        $("#phone").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#primaryEmailIds").val() === "") {
      alert("Primary Email Required");
      setTimeout(() => {
        $("#primaryEmailIds").focus().mousedown();
      }, 10);
      flag = false;
    } else if (this.isEmail($("#primaryEmailIds").val()) === false) {
      alert("Invalid Primary Email id");
      setTimeout(() => {
        $("#primaryEmailIds").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#categoryId").val() === "0") {
      alert("Category Id Required");
      setTimeout(() => {
        $("#categoryId").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#credentialId").val() === "0") {
      alert("Credential Id Required");
      setTimeout(() => {
        $("#credentialId").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#typeId").val() === "0") {
      alert("Type Id Required");
      setTimeout(() => {
        $("#typeId").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#classificationIds").val().length === "0") {
      alert("Classification Ids Required");
      setTimeout(() => {
        $("#classificationIds").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#paymentTypeIds").val().length === "0") {
      alert("PaymentType Ids Required");
      setTimeout(() => {
        $("#paymentTypeIds").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#workTimeFilter").val().length === "0") {
      alert("WorkTime Filter Ids Required");
      setTimeout(() => {
        $("#workTimeFilter").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#sh1MonFrom").val() === "0") {
      alert("Office Hours Required");
      setTimeout(() => {
        $("#sh1MonFrom").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#sh1MonTo").val() === "0") {
      alert("Office Hours Required");
      setTimeout(() => {
        $("#sh1MonTo").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#sh1TueFrom").val() === "0") {
      alert("Office Hours Required");
      setTimeout(() => {
        $("#sh1TueFrom").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#sh1TueTo").val() === "0") {
      alert("Office Hours Required");
      setTimeout(() => {
        $("#sh1TueTo").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#sh1WedFrom").val() === "0") {
      alert("Office Hours Required");
      setTimeout(() => {
        $("#sh1WedFrom").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#sh1WedTo").val() === "0") {
      alert("Office Hours Required");
      setTimeout(() => {
        $("#sh1WedTo").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#sh1ThurFrom").val() === "0") {
      alert("Office Hours Required");
      setTimeout(() => {
        $("#sh1ThurFrom").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#sh1ThurTo").val() === "0") {
      alert("Office Hours Required");
      setTimeout(() => {
        $("#sh1ThurTo").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#sh1SunFrom").val() === "0") {
      alert("Office Hours Required");
      setTimeout(() => {
        $("#sh1SunFrom").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#sh1SunTo").val() === "0") {
      alert("Office Hours Required");
      setTimeout(() => {
        $("#sh1SunTo").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#sh1FriFrom").val() === "0") {
      alert("Office Hours Required");
      setTimeout(() => {
        $("#sh1FriFrom").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#sh1FriTo").val() === "0") {
      alert("Office Hours Required");
      setTimeout(() => {
        $("#sh1FriTo").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#sh1SatFrom").val() === "0") {
      alert("Office Hours Required");
      setTimeout(() => {
        $("#sh1SatFrom").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#sh1SatTo").val() === "0") {
      alert("Office Hours Required");
      setTimeout(() => {
        $("#sh1SatTo").focus().mousedown();
      }, 10);
      flag = false;
    } else if (specialisationsSelect.length === 0) {
      alert("Specialisations Required");
      setTimeout(() => {
        $('html, body').animate({
          scrollTop: $("#spc").offset().top
        }, 100);

      }, 10);
      flag = false;
    } else if (this.state.associationIds == 0) {
      alert("Choose Association Name");
      setTimeout(() => {
        $('html, body').animate({
          scrollTop: $("#associationIds").offset().top
        }, 100);

      }, 10);
      flag = false;
    } else if ($("#adminDisplayName").val() === "") {
      alert("Admin Display Name Required");
      setTimeout(() => {
        $("#adminDisplayName").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#adminUserName").val() === "") {
      alert("Admin User Name Required");
      setTimeout(() => {
        $("#adminUserName").focus().mousedown();
      }, 10);
      flag = false;
    }

    else if ($("#superAdminUserName").val()) {
      if (this.isEmail($("#superAdminUserName").val()) === false) {
        alert("Invalid Super Admin UserName");
        setTimeout(() => {
          $("#superAdminUserName").focus();
        }, 10);
        flag = false;
      }
    }
    else if ($("#description").val() === "") {
      alert("Description Required");
      setTimeout(() => {
        $("#description").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#latitude").val() === "") {
      alert("Latitude Required");
      setTimeout(() => {
        $("#latitude").focus().mousedown();
      }, 10);
      flag = false;
    } else if ($("#longitude").val() === "") {
      alert("Longitude Required");
      setTimeout(() => {
        $("#longitude").focus().mousedown();
      }, 10);
      flag = false;
    }
    if (flag === true) {
      $(".loader").show();
      var consultingAndVisitingTiming = [];
      for (var i = 0; i < consultingArrayDetailsData.length; i++) {
        var g = {
          time:
            consultingArrayDetailsData[i].consultingTimeFrom +
            "-" +
            consultingArrayDetailsData[i].consultingTimeTo,
          type: "C",
        };
        consultingAndVisitingTiming.push(g);
      }
      for (var i = 0; i < visitingArrayDetailsData.length; i++) {
        var e = {
          time:
            visitingArrayDetailsData[i].visitingTimeFrom +
            "-" +
            visitingArrayDetailsData[i].visitingTimeTo,
          type: "V",
        };
        consultingAndVisitingTiming.push(e);
      }
      var servicedetailsArray = [];
      for (var i = 0; i < servicedetailsData.length; i++) {
        var b = {
          serviceId: servicedetailsData[i].hospitalServiceId,
          time:
            servicedetailsData[i].servicesTimeFrom +
            "-" +
            servicedetailsData[i].servicesTimeTo,
        };
        servicedetailsArray.push(b);
      }

      const apiJson = {
        method: "POST",
        body: JSON.stringify({
          functionName: "updateHospitalDetails",
          hospitalId: $("#hospitalId").val(),
          googleAddressPin: $("#googleAddressPin").val(),
          hospitalName: $("#hospitalName").val().replace(/'/g, "''"),
          hospitalImage:
            this.state.hospitalImage === undefined ||
              this.state.hospitalImage === ""
              ? ""
              : this.state.hospitalImage,
          // hospitalImage: hospitalImage,
          doctorCount: $("#doctorCount_s").val() ? $("#doctorCount_s").val().trim() : "",
          modifiedOn: $("#modifiedOn").val() ? $("#modifiedOn").val().trim() : "",
          hospitalEmail: $("#hospitalEmail").val() ? $("#hospitalEmail").val().trim() : "",
          hospitalAddress: $("#hospitalAddress").val(),
          countryId: $("#cmbCountry").val(),
          stateId: $("#stateId").val(),
          cityObj: {
            cityId: $("#cityId").val().split("-")[0],
            cityName: $("#cityId :selected").text(),
          },
          subCityId: $("#subCity").val(),
          pinCode: $("#pinCode").val(),
          phone: $("#phone").val(),
          primaryEmailIds: $("#primaryEmailIds").val() ? $("#primaryEmailIds").val().trim() : "",
          categoryId: $("#categoryId").val(),
          credentialId: $("#credentialId").val(),
          typeId: $("#typeId").val(),
          classificationIds: $("#classificationIds")
            .val()
            .toString()
            .replace(/[\[\]']/g, ""),
          paymentTypeIds: $("#paymentTypeIds")
            .val()
            .toString()
            .replace(/[\[\]']/g, ""),
          workTimeFilter: $("#workTimeFilter")
            .val()
            .toString()
            .replace(/[\[\]']/g, ""),
          accreditationIds: $("#accreditationIds")
            .val()
            .toString()
            .replace(/[\[\]']/g, ""),
          insuranceIds: $("#insuranceIds")
            .val()
            .toString()
            .replace(/[\[\]']/g, ""),
          hospitalOfficeHours: [
            {
              sundayTo: $("#sh1SunTo").val(),
              sundayFrom: $("#sh1SunFrom").val(),
              mondayTo: $("#sh1MonTo").val(),
              mondayFrom: $("#sh1MonFrom").val(),
              tuesdayTo: $("#sh1TueTo").val(),
              tuesdayFrom: $("#sh1TueFrom").val(),
              wednesdayTo: $("#sh1WedTo").val(),
              wednesdayFrom: $("#sh1WedFrom").val(),
              thursdayTo: $("#sh1ThurTo").val(),
              thursdayFrom: $("#sh1ThurFrom").val(),
              fridayTo: $("#sh1FriTo").val(),
              fridayFrom: $("#sh1FriFrom").val(),
              saturdayTo: $("#sh1SatTo").val(),
              saturdayFrom: $("#sh1SatFrom").val(),
            },
          ],
          specializationIds: specialisationsSelect
            .toString()
            .replace(/[\]']/g, ""),
          associationIds: this.state.associationIds.toString(),
          groupHospitalAdminId: this.state.groupHospitalAdminId,
          fax: $("#fax").val(),
          secondaryEmailIds: $("#secondaryEmailIds").val() ? $("#secondaryEmailIds").val().trim() : "",
          adminDisplayName: $("#adminDisplayName").val().replace(/'/g, "''"),
          adminUserTitle: $("#adminUserTitle").val(),
          adminUserName: $("#adminUserName").val() ? $("#adminUserName").val().trim() : "",
          adminPassword: $("#adminPassword").val() ? $("#adminPassword").val().trim() : "",
          isAccountExpired: $("#isAccountExpired").val(),
          isAccountLocked: $("#isAccountLocked").val(),
          isPasswordExpired: $("#isPasswordExpired").val(),
          isEHREnabled: $("#isEHREnabled").val(),
          isVideoRecordingAvailable: $("#isVideoRecordingAvailable").val(),
          websiteUrl: $("#websiteUrl").val(),
          rating: $('#rating').val() ? $('#rating').val() : "1",
          sortId: $('#sortId').val() ? $('#sortId').val() : "1",
          advertismentId: $('#advertismentId').val() ? $('#advertismentId').val() : "1",
          beds: $('#beds').val() ? $('#beds').val() : "1",
          isMedicalTourism: $("#isMedicalTourism").val(),
          isRegistered: $("#isRegistered").val(),
          isAcceptOnlineAppointment: $("#isAcceptOnlineAppointment").val(),
          isverified: $("#isverified").val(),
          isShowHospitallogoassitelogo: $(
            "#isShowHospitallogoassitelogo"
          ).val(),
          isShowHospitalMedicineOnly: $("#isShowHospitalMedicineOnly").val(),
          isPrepaidCardEnabled: $("#isPrepaidCardEnabled").val(),
          isVideoEnabled: $("#isVideoEnabled").val(),
          hasPrNoEnabled:
            $("#isPRNumberEnabled").val() === "true" ? true : false,
          isDoctorInitiatedChat: $("#isDoctorInitiatedChat").val(),
          isCashback: $("#isCashback").val(),
          cashbackPercent: $('#cashbackPercent').val() ? $('#cashbackPercent').val() : "1",
          hasDiscount: $("#hasDiscount").val(),
          isPromoCode: $("#isPromoCode").val(),
          hasPmWaterMark: $("#hasPmWaterMark").val(),
          hasSuperAdmin: $("#hasSuperAdmin").val(),
          isEnabled: $("#isEnabled").val(),
          superAdminDisplayName: $("#superAdminDisplayName").val(),
          superAdminUserName: $("#superAdminUserName").val() ? $("#superAdminUserName").val().trim() : "",
          superAdminPassword: $("#superAdminPassword").val() ? $("#superAdminPassword").val().trim() : "",
          bankAccountName: $("#bankAccountName").val(),
          bankAccountEmail: $("#bankAccountEmail").val() ? $("#bankAccountEmail").val().trim() : "",
          businessName: $("#businessName").val(),
          linkedAccountId: $("#linkedAccountId").val(),
          branchIFSCCode: $("#branchIFSCCode").val(),
          bankAccountNo: $("#bankAccountNo").val(),
          beneficiaryName: $("#beneficiaryName").val(),
          businessTypeId: "1",
          consultingAndVisitingTiming: consultingAndVisitingTiming,
          description: $("#description").val().replace(/'/g, "''"),
          searchKeywords: $("#searchKeywords").val().replace(/'/g, "''"),
          officeDetails: $("#officeDetails").val().replace(/'/g, "''"),
          serviceOffered: $("#serviceOffered").val().replace(/'/g, "''"),
          latitude: $("#latitude").val(),
          longitude: $("#longitude").val(),
          serviceDetails: servicedetailsArray,
          deactiveDate: $("#deactive").val(),
          activeDate: $("#activeDate").val(),
          registerDate: $("#registrationDate").val(),
          createdOn: $("#txtCreatedOn").val(),
          slug: this.state.slug,
          seoTitle: this.state.seoTitle,
          seoKeywords: this.state.seoKeywords,
          seoDescription: this.state.seoDescription,
          logoImage: "",
          photoImage: null,
          backgroundColour: this.state.backgroundColour,
          foregroundColour: this.state.foregroundColour,
          buttonColour: this.state.buttonColour,
          fontColour: this.state.fontColour,
          iFrameLink:
            "<html><body><iframe id='we' src='http://www.purplehealth.com/vaas/doctorsList?id=11767' style='width:250px;height:300px;' scrolling='no'></iframe></body></html>",
          windowLink:
            "window.open('http://www.purplehealth.com/vaas/doctorsList?id=11767', '_blank', 'toolbar=0,location=0,menubar=0,width='300',height='300'')",
        }),
      };
      apiCalling(apiJson).then((data) => {
        if (data.success === "1") {
          $("#hospitalId").val()
            ? alert("Hospital Updated Successfully")
            : alert("Hospital Added Successfully");
          const hospApi = {
            method: "POST",
            body: JSON.stringify({
              functionName: "hospitalDefaultDataSave",
              hospitalId: data.hospitalId,
            }),
          };
          apiCalling(hospApi);
          $("#txtCancel").click();
          if (isPrNumberChangeValue === "true" && isPrNumberEnabledFlag === false) {
            const json = {
              method: "POST",
              body: JSON.stringify({
                functionName: "updatingPrNumber",
                hospitalId: data.hospitalId
              }),
            }
            apiCalling(json).then((data) => {
              isPrNumberEnabledFlag = true;
            });
          }
        } else {
          alert(data.errorMessage);
        }

      });
    }
  };
  isPrNumberEnabled(flag) {
    isPrNumberEnabledFlag = flag;
    isPrNumberChangeValue = $("#isPRNumberEnabled").val();
  }
  render() {
    return (
      <div className="purpleWrap bg-clr-wthHead">
        <Suspense fallback={<div></div>}>
          <section>
            <Header /> <div className="loader"></div>
          </section>
        </Suspense>
        <div className="container mb-5">
          <div>
            <ul className="breadcrum-adminMangmnt">
              <li>
                <a href="/admin/dashboardAdmin">Dashboard</a>
              </li>
              <li>
                <a href="/adminManagement/hospitalManagementList/hospitalManagementList">
                  Hospital Management
                </a>
              </li>
              <li>
                <a href="" className="current">
                  Hospital Details
                </a>
              </li>
            </ul>
          </div>
          <div className="smssetng-btn mb-4 mt-4">
            <input
              type="submit"
              value={this.state.hospitalId > 0 ? "Update" : "Save"}
              className="smssetng-svebtn"
              onClick={() => this.saveHospital()}
            />
            <Link
              to={{
                pathname:
                  "/adminManagement/hospitalManagementList/hospitalManagementList",
              }}
            >
              <input
                type="submit"
                id="txtCancel"
                value="Cancel"
                className="smssetng-cancelbtn"
              />
            </Link>
          </div>
          <div className="addHspPage-Wrapper">
            <Tabs>
              <TabList>
                <Tab>Hospital Details</Tab>
                {/* <Tab>Seo</Tab> */}
                {/* <Tab>Link</Tab> */}
                {/* <Tab>Photo</Tab>
                  <Tab>Logo Upload</Tab> */}
              </TabList>
              <div className="doctr-wraper-border">
                <TabPanel>
                  {/* for hospital Edit */}
                  {(this.state.hospitalDataArray.length > 0 ||
                    this.state.hospitalId === "") && (
                      <HospitalInfomation
                        ref="hospitalInformationElement"
                        getCountryList={this.getCountryList}
                        hospitalData={this.state}
                        setFileSignature={(fileObj) => {
                          this.setState({ fileSignature: fileObj });
                        }}
                        hospitalImage={this.state.hospitalImage}
                        hospitalImagePath={this.state.hospitalImagePath}
                        setImagePath={(hospitalImagePath) => {
                          // debugger;
                          this.setState({ hospitalImagePath: hospitalImagePath });
                        }}
                        setImage={(hospitalImage) => {
                          this.setState({ hospitalImage: hospitalImage });
                        }}
                        changeHandler={this.changeHandler}
                        changeHandlerMulti={this.changeHandlerMulti}
                      />
                    )}
                  {(this.state.hospitalDataArray.length > 0 ||
                    this.state.hospitalId === "") && (
                      <GoogleMap
                        longitude={this.state.longitude}
                        latitude={this.state.latitude}
                        googleAddressPin={this.state.googleAddressPin}
                        changeHandler={this.changeHandler}
                        setGoogleAddressPin={(googleAddressPin) => {
                          this.setState({ googleAddressPin });
                        }}
                        selectGoogleAddressPin={(googleAddressPin, lat, lng) => {
                          this.setState({
                            googleAddressPin: googleAddressPin,
                            latitude: lat,
                            longitude: lng,
                          });
                        }}
                        onMarkerDragEnd={(lat, lng) => {
                          this.setState({ latitude: lat, longitude: lng });
                        }}
                      />
                    )}
                  {(this.state.HospitalworkDetailsArray.length > 0 ||
                    this.state.hospitalId === "") && (
                      <HospitalworkDetails
                        HospitalworkData={this.state.HospitalworkDetailsArray}
                        accreditationIdEdit={this.state.accreditationIdEdit}
                        insuranceIdEdit={this.state.insuranceIdEdit}
                      />
                    )}

                  {((this.state.specializationIdEdit.length > 0 ||
                    this.state.specializationData.length > 0) ||
                    this.state.hospitalId === "") && (
                      <HospitalSpecialisation
                        key={this.state.specializationIdEdit}
                        ref="specialisationElement"
                        onChange={(value) => {
                          this.setState({ specializationData: value });
                        }}
                        specializationData={this.state.specializationData}
                        specializationIdEdit={this.state.specializationIdEdit}
                      />
                    )}
                  {(this.state.hospitalDataArray.length > 0 || this.state.assosiationFullDataList.length > 0 ||
                    this.state.hospitalId === "") && (
                      <HospitalAdmindetails
                        // assosiationFullDataList={this.state.assosiationFullDataList}
                        hospitalData1={this.state.hospitalDataArray}
                        hospitalState={this.state}
                        onChange={(value, grpAdmId) => {
                          this.setState({
                            associationIds: value,
                            groupHospitalAdminId: grpAdmId,
                          });
                        }}
                        isPrNumberEnabled={
                          this.isPrNumberEnabled
                        }
                      />
                    )}
                  {(this.state.hospitalDataArray.length > 0 ||
                    this.state.hospitalId === "") && (
                      <SuperAdmin hospitalData1={this.state.hospitalDataArray} />
                    )}
                  {(this.state.hospitalDataArray.length > 0 ||
                    this.state.hospitalId === "") && (
                      <BankAccountdetails
                        hospitalData1={this.state.hospitalDataArray}
                      />
                    )}
                  {(this.state.hospitalConsultingTimeEdit.length > 0 ||
                    this.state.hospitalId === "") && (
                      <ConsultingTime
                        setHospitalConsultingTimeEdit={(
                          hospitalConsultingTimeEdit
                        ) => {
                          this.setState({ hospitalConsultingTimeEdit });
                        }}
                        hospitalConsultingTimeEdit={
                          this.state.hospitalConsultingTimeEdit
                        }
                      />
                    )}
                  {(this.state.hospitalVisitingTimeEdit.length > 0 ||
                    this.state.hospitalId === "") && (
                      <VisitingTime
                        setHospitalVisitingTimeEdit={(
                          hospitalVisitingTimeEdit
                        ) => {
                          this.setState({ hospitalVisitingTimeEdit });
                        }}
                        hospitalVisitingTimeEdit={
                          this.state.hospitalVisitingTimeEdit
                        }
                      />
                    )}
                  {(this.state.hospitalDataArray.length > 0 ||
                    this.state.hospitalId === "") && (
                      <Servicedetails
                        ref="servicedetailsElement"
                        hospitalData1={this.state.hospitalDataArray}
                        hospitalServiceDetailsEdit={
                          this.state.hospitalServiceDetailsArray
                        }
                        setHospitalServiceDetailsEdit={(
                          hospitalServiceDetailsArray
                        ) => {
                          this.setState({ hospitalServiceDetailsArray });
                        }}
                        servicesData={this.state.servicesData}
                      />
                    )}
                  <div className="smssetng-btn text-left pt-2">
                    <input
                      type="submit"
                      value={this.state.hospitalId > 0 ? "Update" : "Save"}
                      className="smssetng-svebtn"
                      onClick={() => this.saveHospital()}
                    />
                    <Link
                      to={{
                        pathname:
                          "/adminManagement/hospitalManagementList/hospitalManagementList",
                      }}
                    >
                      <input
                        type="submit"
                        value="Cancel"
                        id="txtCancel"
                        className="smssetng-cancelbtn"
                      />
                    </Link>
                  </div>
                </TabPanel>
              </div>
              {/* <TabPanel>
                   <Seo />
              </TabPanel> */}
              {/* <TabPanel>
                <LinkHsp />
              </TabPanel> */}
              {/* <TabPanel>
                <Photo />
              </TabPanel>
              <TabPanel>
                  <LogoUpload />
              </TabPanel> */}
            </Tabs>
          </div>
        </div>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
