import React, { useEffect, useState, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import { RiWhatsappFill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { apiCalling, awsAPIUrlcommon } from "../apiService";
import moment from "moment";
import $ from "jquery";
import csv from "../image/icons/csv.png";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { toast, ToastContainer } from "react-toastify";
import DoctorTemplate from "../image/excel/DoctorDashboardDetails.xlsx";
import DoctorDashboardSearch from "./doctorDashboardListSearch.jsx";
import DoctorFilePopup from "./doctorDashboardFilePopup.jsx";
import DoctorExcelPopup from "./doctorDashboardExcelPopup.jsx";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import JSZip from "jszip";
import Application from "../image/media/application.png";
import Transaction from "../image/media/transaction.png";
import Video from "../image/media/video-message.png";
import People from "../image/media/two-people.png";
import Direction from "../image/media/direction.png";
import Booking from "../image/media/booking.png";
import Telephone from "../image/media/telephone.png";
import Record from "../image/media/medical-record.png";
import Interface from "../image/media/interface.png";
import Speech from "../image/media/speech-bubble.png";
import Purple1 from "../image/media/purple-health.png";
import Linechart from "./doctor-report-chart";
import { useDispatch } from "react-redux";
import DateRangePopup from "./doctorDashboardPopUP.jsx";
import { resetInactivityTime } from "../redux/reducers/inactivitySlice";

const DoctorPanel = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [siteId, setSiteId] = useState("");
  const [doctorName, setDoctorName] = useState("");
  const [siteName, setSiteName] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [max, setMax] = useState(10);
  const [offSet, setOffSet] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [doctorList, setDoctorList] = useState([]);
  const [doctorFileFlag, setDoctorFileFlag] = useState(false);
  const [showMessage, setShowMessage] = useState(
    "Please select site name first."
  );
  const [excelPopupFlag, setExcelPopupFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileId, setFileId] = useState("");
  const [fileData, setFileData] = useState([]);
  const [excelDoctorList, setExcelDoctorList] = useState([]);
  const [id_name, setData] = useState([]);
  const [doctorReportList, setDoctorReportList] = useState(null);
  const [bucket, setBucketPath] = useState("");
  const [fromMonthYear, setFromMonthYear] = useState(
    moment().format("MMMM YYYY")
  );
  const [toMonthYear, setToMonthYear] = useState("");
  const [CompanyLogo, setCompanyLogo] = useState("");
  const [projectLogo, setProjectLogo] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    // Simulate loading process (Replace with actual API call)
    setTimeout(() => {
      // setLoading(false); // Set loading false when data is fetched
      dispatch(resetInactivityTime()); // Reset inactivity when loading completes
    }, 30000); // 30 seconds example

  }, [dispatch]);
  useEffect(() => {
    siteId !== "" && getDoctorList();
    siteId !== "" &&
      // getDoctorSiteDashboard(
      //   process.env.REACT_APP_s3BucketRelianceAddressPath +
      //   "/" +
      //   "rhealthfiles" +
      //   "/" +
      //   "rhealthfiles" +
      //   "/" +
      //   "RHADashboard/"
      // );
      setBucketPath(
        process.env.REACT_APP_s3BucketRelianceAddressPath +
        "/" +
        "rhealthfiles" +
        "/" +
        "rhealthfiles" +
        "/" +
        "RHADashboard/"
      );
  }, [siteId, offSet, max]);

  const pdfDoctorDetails = (data) => {
    window.sessionStorage.setItem("doctorDashBId", data.doctorId);
    window.sessionStorage.setItem("doctorDashBName", data.displayName);
    window.sessionStorage.setItem("doctorNumber", data.contactNumber);
    window.sessionStorage.setItem("doctor-reportSiteId", siteId);
    setTimeout(() => {
      navigate("/admin/doctor-report");
    }, 1000);
  };
  const handlePageChange = (pageNumber) => {
    const offset = (pageNumber - 1) * max;
    setOffSet(offset);
    setActivePage(pageNumber);
    // getDoctorList(offset)
  };

  const getDoctorList = () => {
    // $(".loader").show();
    setLoading(true);
    let postData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorList",
        siteId: siteId,
        doctorName: doctorName,
        max: offSet,
        offset: max,
      }),
    };
    apiCalling(postData)
      .then((data) => {
        if (data.success === "1") {
          setDoctorList(data.doctorData);
          const doctorArray = data.doctorData.map((doctor) => ({
            doctorId: doctor.doctorId,
            doctorFirstName: doctor.doctorFirstName,
          }));
          setData(doctorArray);
          setTotalCount(data.doctorDataCount);
          if (data.doctorData.length === 0) {
            setShowMessage("No data found.");
          }
        } else {
          setShowMessage("No data found.");
          setDoctorList([]);
          setTotalCount(0);
        }
        // $(".loader").hide();
        setLoading(false);
      })
      .catch((error) => {
        // $(".loader").hide();
        setLoading(false);
        toast.error("An error occurred while Getting Details");
      });
  };
  const excelDownload = () => {
    setLoading(true);
    let postData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "doctorList",
        siteId: siteId,
        doctorName: doctorName,
        max: 0,
        offset: 10000,
      }),
    };
    apiCalling(postData)
      .then((data) => {
        setLoading(false);
        if (data.success === "1") {
          setExcelDoctorList(data.doctorData);
          ExportAllDataExcel(data.doctorData);
        } else {
          toast.error("No data found.");
          setExcelDoctorList([]);
        }
        // $(".loader").hide();
      })
      .catch((error) => {
        // $(".loader").hide();
        setLoading(false);
        toast.error("An error occurred while Getting Details");
      });
  };

  const ExportAllDataExcel = (exportData) => {
    if (exportData.length > 0) {
      const header = [
        "Doctor Id",
        "Doctor Name",
        "City",
        "Hospital Name",
        "Address",
        "Specialization",
        "Services",
      ];
      const rows = exportData.map((item) => [
        item.doctorId,
        item.displayName,
        item.city,
        item.hospitalName,
        item.address,
        item.specialization,
        item.services.join(", "), // Join services array as a string
      ]);
      const worksheetData = [header, ...rows];
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      const columnWidths = [
        { wch: 10 }, // doctorId
        { wch: 22 }, // displayName
        { wch: 20 }, // city
        { wch: 24 }, // hospitalName
        { wch: 35 }, // address
        { wch: 20 }, // specialization
        { wch: 40 }, // services
      ];
      worksheet["!cols"] = columnWidths;
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const filename = "doctor-list.xlsx";
      saveAs(
        new Blob([excelBuffer], { type: "application/octet-stream" }),
        filename
      );
    } else {
      toast.error("Doctor List is empty!");
    }
  };

  const convertImageToBase64 = async (imgUrl) => {
    if (!imgUrl) return null;

    try {
      const response = await fetch(imgUrl);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const img = new Image();
          img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(img, 0, 0);
            resolve(canvas.toDataURL("image/png"));
          };
          img.onerror = () => resolve(null);
          img.src = reader.result;
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Image conversion error:", error);
      return null;
    }
  };

  // useEffect(() => {
  //   getImagePath();
  // }, []);

  // const getImagePath = () => {
  //   const apiJson = {
  //     method: "POST",
  //     body: JSON.stringify({
  //       functionName: "imagePath",
  //     }),
  //   };
  //   apiCalling(apiJson).then((data) => {
  //     if (data.success === "1") {
  //       let bucketPath =
  //         data.configPathDetails[0].s3BucketRelianceAddressPath +
  //         "/" +
  //         data.configPathDetails[0].S3BucketMainFolder +
  //         "/" +
  //         'doctorDashboard' +
  //         "/";
  //       setBucketPath(bucketPath);
  //       getDoctorSiteDashboard(bucketPath);
  //     }
  //   }).catch((error) => {
  //     toast.error("An error occurred while getting details");
  //   });
  // };

  const handleDateRangeConfirm = async (dateRange) => {
    try {
      setLoading(true);
      setFromMonthYear(dateRange.startDate);
      setToMonthYear(dateRange.endDate);
      await handleDownloadAllReports(dateRange.startDate, dateRange.endDate);
      setIsPopupOpen(false);
      setFromMonthYear("");
      setToMonthYear("");
    } catch (error) {
      console.error("Error during download:", error);
      toast.error("An error occurred during download");
    } finally {
      // setLoading(false);
    }
  };

  const handleDownloadAllReports = async (start, end) => {
    setLoading(true);
    setProgress(0);
    setIsPopupOpen(false);
    setFromMonthYear("");
      setToMonthYear("");
    const zip = new JSZip();
    try {
      let offset = 0;
      const limit = 100;
      let totalProcessed = 0;
      let allDoctors = [];
      let totalCountOfDOc = 100;

      while (totalProcessed < totalCountOfDOc) {
        let postData = {
          method: "POST",
          body: JSON.stringify({
            "functionName": "purpleHealthDoctorSiteDashboard",
            "doctor_id": "",
            "month_and_year": start,
            "month_and_year_to": end,
            "site_id": siteId,
            "max": limit.toString(),
            "offset": offset.toString()
          })
        };
        const response = await awsAPIUrlcommon(postData);
        if (!response || response.success !== "1" || !response.result || response.result.length === 0) {
          throw new Error("No valid doctor data received");
        }

        totalCountOfDOc = response.totalCount;
        allDoctors = [...allDoctors, ...response.result];
        totalProcessed += response.result.length;
        offset += limit;
      }
      await getDoctorSiteDashboardSettingData(allDoctors);
      const startDate = moment(start).format("MMM YYYY");
      const endDate = moment(end).format("MMM YYYY");
      let processedDoctors = 0;
      setProgress(Math.round((processedDoctors / allDoctors.length)));
      for (let doctor of allDoctors) {
        window.sessionStorage.setItem("doctorDashBId", doctor.doctorId);
        window.sessionStorage.setItem("doctorDashBName", doctor.doctorName || "Unknown");
        window.sessionStorage.setItem("doctorNumber", doctor.contactNumber || "N/A");
        setDoctorReportList([doctor]);
        await new Promise((resolve) => setTimeout(resolve, 300));
        const pdfContentElement = document.getElementById("pdf-content");
        if (!pdfContentElement) {
          console.warn("⚠️ Missing #pdf-content element, skipping doctor");
          continue;
        }
        await waitForImagesToLoad(pdfContentElement);
        const canvas = await html2canvas(pdfContentElement, {
          backgroundColor: "white",
          scale: 2,
          useCORS: true,
          windowWidth: pdfContentElement.scrollWidth,
          windowHeight: pdfContentElement.scrollHeight,
        });
        processedDoctors++;

        const pdf = new jsPDF("p", "mm", "a4");
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const yOffset = (pdf.internal.pageSize.getHeight() - imgHeight) / 2;

        try {
          const imgData = canvas.toDataURL("image/jpeg", 1.0);
          pdf.addImage(imgData, "JPEG", 0, yOffset > 0 ? yOffset : 0, imgWidth, imgHeight);
          const fileName = `doctor-dashboard-${doctor.doctorName?.trim()} ${startDate}-${endDate} ${doctor.doctorId}.pdf`;
          const pdfData = pdf.output("blob");
          zip.file(fileName, pdfData);
          setProgress(Math.round((processedDoctors / totalCountOfDOc) * 100));
        } catch (pdfError) {
          setProgress(0);
          console.error("PDF generation failed for", doctor.doctorId, pdfError);
        }

      }

      if (Object.keys(zip.files).length === 0) {
        throw new Error("❌ No PDFs were generated successfully");
      }
      const content = await zip.generateAsync({ type: "blob" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(content);
      link.download = "doctor_dashboards.zip";
      document.body.appendChild(link);

      link.click();

      // Cleanup
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("❌ Error in generating reports:", error);
      toast.error(`PDF Generation Error: ${error.message}`);
    } finally {
      setProgress(0);
      setLoading(false);
    }
  };
  const waitForImagesToLoad = async (element) => {
    const images = element.getElementsByTagName("img");
    const imagePromises = [];

    for (let img of images) {
      if (!img.complete) {
        imagePromises.push(new Promise((resolve) => {
          img.onload = () => resolve();
          img.onerror = () => resolve();
        }));
      }
    }

    await Promise.all(imagePromises);
  };
  const getDoctorSiteDashboardSettingData = async (doctorList) => {
    try {
      if (!doctorList || doctorList.length === 0) {
        throw new Error("❌ No doctors available to fetch logo data.");
      }

      const logoCache = {};

      for (let doctor of doctorList) {
        if (doctor.association && doctor.association.length > 0) {
          const topRightLogoUrl = bucket + doctor.association[0].topRightLogo;
          const bottomRightLogoUrl = bucket + doctor.association[0].bottomRightLogo;

          if (!logoCache[topRightLogoUrl]) {
            logoCache[topRightLogoUrl] = await convertImageToBase64(topRightLogoUrl);
          }
          if (!logoCache[bottomRightLogoUrl]) {
            logoCache[bottomRightLogoUrl] = await convertImageToBase64(bottomRightLogoUrl);
          }

          setCompanyLogo(logoCache[topRightLogoUrl] || null);
          setProjectLogo(logoCache[bottomRightLogoUrl] || null);
        }
      }

    } catch (error) {
      console.error("❌ Error fetching logo data:", error);
      toast.error("An error occurred while getting logo details");
    }
  };
  return (
    <div className="purpleWrap bg-clr-wthHead">
      <ToastContainer />
      <Suspense fallback={<div></div>}>
        <section>
          <Header />
          {loading && (
            <div className="text-center p-3">
              {progress > 0 ? (
                <div className="popup-overlay" style={{ display: "block" }}>
                  <div className="modal-dialog h-100" 
                  style={{display:'flex',alignItems:'center',justifyContent:'center'}}
                  >
                  <div style={{ width: 150, height: 150 }}>
                  <CircularProgressbar value={progress} text={`${progress}%`} />;
                  </div>
                  </div>
                </div>

              ) : (
                <div className="loader d-block"></div> 
              )}
            </div>
          )}
          <div className="container mb-5">
            <div>
              <ul className="breadcrum-adminMangmnt">
                <li>
                  <a href="/admin/dashboard">Dashboard</a>
                </li>
                <li>
                  <a className="current">Doctor Dashboard List</a>
                </li>
              </ul>
            </div>
            <div className="head-hospMangmntList row mx-0">
              Doctor Dashboard List
              {siteName && (
                <div className="col-12 col-lg-2 col-md-2 cursor-pointer text-right ml-auto">
                  <img
                    src={csv}
                    alt="PDFIcon"
                    className="img-fluid ml-1"
                    width="25"
                    height="25"
                    title="Download Excel"
                    onClick={excelDownload}
                  />
                </div>
              )}
            </div>
            <div
              className="row tableHeader-HspMangemnt table-headerDctrPanel tble-dctrpanel-mob
            justify-content-between"
              style={{ height: "auto" }}
            >
              <div className="row col-md-6 ml-0 pl-2">
                <DoctorDashboardSearch
                  siteName={siteName}
                  setSiteName={setSiteName}
                  setSiteId={setSiteId}
                  setDoctorName={setDoctorName}
                />
                <div className="d-flex col-md-6 dctrpanel-headinput mb-1 mb-md-0">
                  <input
                    type="text"
                    className="hspInfofieldinput w-75"
                    disabled={siteName ? false : true}
                    placeholder="Enter Doctor Name"
                    value={doctorName}
                    onChange={(e) => {
                      setDoctorName(e.target.value);
                    }}
                  />
                  <button
                    className="searchbtn-hspMng w-auto m-0 ml-3"
                    type="button"
                    onClick={() => {
                      getDoctorList();
                    }}
                    disabled={siteName ? false : true}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="row col-md-6 justify-content-end mt-0">
                <button
                  className="searchbtn-hspMng w-auto mt-0"
                  type="button"
                  onClick={() => setIsPopupOpen(true)}
                  disabled={!siteName || loading}
                >
                  {loading ? "Downloading..." : "Download All"}
                </button>
                <button
                  className="searchbtn-hspMng w-auto mt-0"
                  type="button"
                  onClick={() => {
                    // setDoctorFileFlag(true)
                    navigate(
                      "/admin/doctor-dashboard-file-list"
                      // {state:setFileData}
                    );
                  }}
                >
                  Show Files
                </button>
                <button
                  className="searchbtn-hspMng w-auto mt-0"
                  type="button"
                  onClick={() => {
                    setExcelPopupFlag(true);
                    setFileId("");
                  }}
                >
                  Excel Upload
                </button>
                <a
                  className="searchbtn-hspMng w-auto text-white mt-0"
                  href={DoctorTemplate}
                  download="DoctorDashboardDetails.xlsx"
                  style={{ paddingTop: "7px" }}
                >
                  Download Template
                </a>
                {/* <button className="searchbtn-hspMng mb-md-auto m-0 w-auto" type="button"
                    onClick={fileSave}
                    disabled={uploaded ? false : true}>
                    Save
                  </button> */}
                {/* <div className="col-4 col-md-3 text-right">
                  <RiWhatsappFill size={24} className="theme-color mt-1 mr-xl-2" />
                  <MdEmail size={24} className="theme-color mt-1" />
                </div> */}
              </div>
            </div>
            <div className="row mx-0">
              {doctorList.length > 0 ? (
                <div className="table-responsive">
                  <table className="table table-bordered-hsp">
                    <thead>
                      <tr>
                        <th className="tablehead-hspmng" colSpan="2">
                          Doctor Id
                        </th>
                        <th className="tablehead-hspmng" colSpan="4">
                          Doctor Name{" "}
                        </th>
                        <th className="tablehead-hspmng" colSpan="3">
                          City
                        </th>
                        <th className="tablehead-hspmng" colSpan="4">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {doctorList.map((data) => (
                        <tr key={data.doctorId}>
                          <td colSpan="2">{data.doctorId}</td>
                          <td colSpan="4">{data.displayName}</td>
                          <td colSpan="3">{data.city}</td>
                          <td
                            colSpan="4"
                            className="nowrap d-flex aling-items-center flex-wrap"
                          >
                            <div>
                              <span className="ph_action_horizontal">
                                <a
                                  href="#"
                                  className="listLink-hspmangmnt"
                                  onClick={() => pdfDoctorDetails(data)}
                                >
                                  View Dashboard
                                </a>
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className="error-msg text-center w-100 mt-4">
                  {showMessage}
                </p>
              )}
              {totalCount > 10 && (
                <div className="paginationSection orderm-paginationSection pagination-HspList pagination-HspListwidth p-3">
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={max}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={10}
                    onChange={handlePageChange}
                  />
                </div>
              )}
            </div>
          </div>
          {doctorFileFlag && (
            <DoctorFilePopup
              setDoctorFileFlag={setDoctorFileFlag}
              setExcelPopupFlag={setExcelPopupFlag}
              setFileId={setFileId}
              setFileData={setFileData}
            />
          )}
          {excelPopupFlag && (
            <DoctorExcelPopup
              siteId={siteId}
              setExcelPopupFlag={setExcelPopupFlag}
              setFileId={setFileId}
              fileId={fileId}
              fileData={fileData}
              setFileData={setFileData}
            />
          )}
        </section>
      </Suspense>
      <Suspense fallback={<div></div>}>
        <section>
          <Footer />
        </section>
      </Suspense>
      <div id="pdf-content" style={{ position: "absolute", left: "-9999px" }}>
        <div className="main-outer doctor-report" id="pdf-content">
          <header className="header">
            <div className="header-top">
              <div className="row">
                <div className="col-lg-9 col-6 mt-3 mb-3">
                  <h1 className="header-h1 mt-2">
                    Business Insights of{" "}
                    {window.sessionStorage.getItem("doctorDashBName")}
                  </h1>
                  <p className="header-date">
                    {fromMonthYear && toMonthYear
                      ? `${fromMonthYear} - ${toMonthYear}`
                      : fromMonthYear || toMonthYear}
                  </p>
                </div>
                {bucket &&
                  doctorReportList?.[0]?.association?.[0]?.topRightLogo && (
                    <div className="col-lg-3 col-6 d-flex justify-content-end align-items-center pr-5">
                      <img
                        src={CompanyLogo}
                        alt="Img1"
                        className="img-fluid"
                        width="100"
                        height="100"
                      />
                    </div>
                  )}
              </div>
            </div>
          </header>
          <div className="violet-border"></div>
          <div className="utilization-snapshot">
            <div className="">
              <div className="">
                <h2 className="utilization-head">Utilization Snapshot</h2>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-6 col-12 pr-lg-0 pr-md-0">
                  <div className="stats">
                    <div className="stat-box">
                      <div className="row border-blue">
                        <div className="col-8">
                          <div className="row">
                            <div className="col-6">
                              <h3 className="count-report">
                                {doctorReportList?.[0]?.utilizationSnapshot
                                  ?.totalPatient || 0}
                              </h3>
                            </div>
                            <div className="col-6">
                              <span
                                className={
                                  doctorReportList?.[0]?.utilizationSnapshot
                                    ?.totalPatientChange >= 0
                                    ? `increase`
                                    : `decrease`
                                }
                              >
                                {doctorReportList?.[0]?.utilizationSnapshot
                                  ?.totalPatientChange >= 0
                                  ? `▲ ${doctorReportList?.[0]?.utilizationSnapshot?.totalPatientChange}`
                                  : `▼ ${Math.abs(
                                    doctorReportList?.[0]?.utilizationSnapshot
                                      ?.totalPatientChange
                                  )}`}
                                {/* {doctorReportList?.[0]?.utilizationSnapshot?.totalPatientChange || 0} */}
                              </span>
                            </div>
                          </div>
                          <p className="patient-visit-count">
                            Total patient visits
                          </p>
                        </div>
                        <div className="col-4">
                          <img
                            src={Interface}
                            alt="Img1"
                            className="img-fluid"
                            width="60"
                            height="60"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="stat-box">
                      <div className="row border-blue">
                        <div className="col-8">
                          <div className="row">
                            <div className="col-6">
                              <h3 className="count-report">
                                {doctorReportList?.[0]?.utilizationSnapshot
                                  ?.uniquePatient || 0}
                              </h3>
                            </div>
                            <div className="col-6">
                              <span
                                className={
                                  doctorReportList?.[0]?.utilizationSnapshot
                                    ?.uniquePatientChange >= 0
                                    ? `increase`
                                    : `decrease`
                                }
                              >
                                {doctorReportList?.[0]?.utilizationSnapshot
                                  ?.uniquePatientChange >= 0
                                  ? `▲ ${doctorReportList?.[0]?.utilizationSnapshot?.uniquePatientChange}`
                                  : `▼ ${Math.abs(
                                    doctorReportList?.[0]?.utilizationSnapshot
                                      ?.uniquePatientChange
                                  )}`}
                                {/* {doctorReportList?.[0]?.utilizationSnapshot?.uniquePatientChange || 0} */}
                              </span>
                            </div>
                          </div>
                          <p className="patient-visit-count">
                            Unique patient visits
                          </p>
                        </div>
                        <div className="col-4">
                          <img
                            src={Record}
                            alt="Img1"
                            className="img-fluid"
                            width="80"
                            height="80"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {doctorReportList?.[0]?.patientVisitData && (
                  <div className="col-lg-8 col-md-6 col-12 graph-line">
                    <Linechart
                      seriesData={
                        doctorReportList?.[0]?.patientVisitData.series
                      }
                      labelsData={
                        doctorReportList?.[0]?.patientVisitData.labels
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="blue-border"></div>

          <div className="google-snapshot">
            <div className="">
              <div className="">
                <h2 className="google-head">Google My Business</h2>
              </div>
              <div className="row mr-0 border-red google-my-business">
                {doctorReportList?.[0]?.googleMyBusiness?.googleListing ===
                  "NA" && (
                    <div className="access-denied_section">
                      <div className="access-denied_content">
                        Access pending - Please call us @
                        <span style={{ letterSpacing: "1px" }}>
                          {" "}
                          {
                            doctorReportList?.[0]?.association?.[0]
                              ?.contactPersonPhone
                          }
                        </span>
                      </div>
                    </div>
                  )}
                <div className="col-12 text-center py-2 business-profile-head">
                  Business Profile Interaction -{" "}
                  {doctorReportList?.[0]?.googleMyBusiness?.googleListing ===
                    "NA"
                    ? doctorReportList?.[0]?.googleMyBusiness?.googleListing
                    : doctorReportList?.[0]?.googleMyBusiness
                      ?.businessProfileInteraction}
                </div>
                <div className="col-lg-5 col-md-5 col-12">
                  <div className="stats">
                    <div className="stat-box">
                      <div className="row border-grey m-0">
                        <div className="col-8">
                          <div className="row">
                            <div className="col-6">
                              <h3 className="count-report">
                                {doctorReportList?.[0]?.googleMyBusiness
                                  ?.googleListing === "NA"
                                  ? doctorReportList?.[0]?.googleMyBusiness
                                    ?.googleListing
                                  : doctorReportList?.[0]?.googleMyBusiness
                                    ?.Calls}
                              </h3>
                            </div>
                          </div>
                          <p className="patient-visit-count">Calls</p>
                        </div>
                        <div className="col-4">
                          <img
                            src={Telephone}
                            alt="Img1"
                            className="img-fluid"
                            width="60"
                            height="60"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="stat-box">
                      <div className="row m-0">
                        <div className="col-8">
                          <div className="row">
                            <div className="col-6">
                              <h3 className="count-report">
                                {doctorReportList?.[0]?.googleMyBusiness
                                  ?.googleListing === "NA"
                                  ? doctorReportList?.[0]?.googleMyBusiness
                                    ?.googleListing
                                  : doctorReportList?.[0]?.googleMyBusiness
                                    ?.Bookings}
                              </h3>
                            </div>
                          </div>
                          <p className="patient-visit-count">Bookings</p>
                        </div>
                        <div className="col-4">
                          <img
                            src={Booking}
                            alt="Img1"
                            className="img-fluid"
                            width="60"
                            height="60"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-12"></div>
                <div className="col-lg-5 col-md-5 col-12">
                  <div className="stats">
                    <div className="stat-box">
                      <div className="row border-grey m-0">
                        <div className="col-8 ">
                          <div className="row">
                            <div className="col-6">
                              <h3 className="count-report">
                                {doctorReportList?.[0]?.googleMyBusiness
                                  ?.googleListing === "NA"
                                  ? doctorReportList?.[0]?.googleMyBusiness
                                    ?.googleListing
                                  : doctorReportList?.[0]?.googleMyBusiness
                                    ?.Directions}
                              </h3>
                            </div>
                          </div>
                          <p className="patient-visit-count">Directions</p>
                        </div>
                        <div className="col-4">
                          <img
                            src={Direction}
                            alt="Img1"
                            className="img-fluid"
                            width="60"
                            height="60"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="stat-box">
                      <div className="row m-0">
                        <div className="col-8">
                          <div className="row">
                            <div className="col-6">
                              <h3 className="count-report">
                                {doctorReportList?.[0]?.googleMyBusiness
                                  ?.googleListing === "NA"
                                  ? doctorReportList?.[0]?.googleMyBusiness
                                    ?.googleListing
                                  : doctorReportList?.[0]?.googleMyBusiness
                                    ?.websiteClicks}
                              </h3>
                            </div>
                          </div>
                          <p className="patient-visit-count">Website Clicks</p>
                        </div>
                        <div className="col-4">
                          <img
                            src={Application}
                            alt="Img1"
                            className="img-fluid"
                            width="60"
                            height="60"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="red-border"></div>
          <div className="business-snapshot">
            <div className="">
              <div className="">
                <h2 className="business-head">Business Snapshot</h2>
              </div>
              <div className="row mr-0 border-green">
                <div className="col-lg-5 col-md-5 col-12">
                  <div className="stats">
                    <div className="stat-box">
                      <div className="row border-grey m-0">
                        <div className="col-8">
                          <div className="row">
                            <div className="col-6">
                              <h3 className="count-report">
                                {doctorReportList?.[0]?.businessSnapshot
                                  ?.TotalTransaction || 0}
                              </h3>
                            </div>
                            <div className="col-6">
                              <span
                                className={
                                  doctorReportList?.[0]?.businessSnapshot
                                    ?.totalTransactionChange >= 0
                                    ? `increase`
                                    : `decrease`
                                }
                              >
                                {doctorReportList?.[0]?.businessSnapshot
                                  ?.totalTransactionChange >= 0
                                  ? `▲ ${doctorReportList?.[0]?.businessSnapshot?.totalTransactionChange}`
                                  : `▼ ${Math.abs(
                                    doctorReportList?.[0]?.businessSnapshot
                                      ?.totalTransactionChange
                                  )}`}
                                {/* {doctorReportList?.[0]?.businessSnapshot?.totalTransactionChange || 0} */}
                              </span>
                            </div>
                          </div>
                          <p className="patient-visit-count">Transactions</p>
                        </div>
                        <div className="col-4">
                          <img
                            src={Transaction}
                            alt="Img1"
                            className="img-fluid"
                            width="60"
                            height="60"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="stat-box">
                      <div className="row m-0">
                        <div className="col-8 ">
                          <div className="row">
                            <div className="col-6">
                              <h3 className="count-report">
                                {doctorReportList?.[0]?.businessSnapshot
                                  ?.inPerson || 0}
                              </h3>
                            </div>
                            <div className="col-6">
                              <span
                                className={
                                  doctorReportList?.[0]?.businessSnapshot
                                    ?.inPersonChange >= 0
                                    ? `increase`
                                    : `decrease`
                                }
                              >
                                {doctorReportList?.[0]?.businessSnapshot
                                  ?.inPersonChange >= 0
                                  ? `▲ ${doctorReportList?.[0]?.businessSnapshot?.inPersonChange}`
                                  : `▼ ${Math.abs(
                                    doctorReportList?.[0]?.businessSnapshot
                                      ?.inPersonChange
                                  )}`}
                                {/* {doctorReportList?.[0]?.businessSnapshot?.inPersonChange || 0} */}
                              </span>
                            </div>
                          </div>
                          <p className="patient-visit-count">In-person</p>
                        </div>
                        <div className="col-4">
                          <img
                            src={People}
                            alt="Img1"
                            className="img-fluid"
                            width="60"
                            height="60"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-12"></div>
                <div className="col-lg-5 col-md-5 col-12">
                  <div className="stats">
                    <div className="stat-box">
                      <div className="row border-grey m-0">
                        <div className="col-8">
                          <div className="row">
                            <div className="col-6">
                              <h3 className="count-report">
                                {doctorReportList?.[0]?.businessSnapshot
                                  ?.videoChat || 0}
                              </h3>
                            </div>
                            <div className="col-6">
                              <span
                                className={
                                  doctorReportList?.[0]?.businessSnapshot
                                    ?.videoChatChange >= 0
                                    ? `increase`
                                    : `decrease`
                                }
                              >
                                {doctorReportList?.[0]?.businessSnapshot
                                  ?.videoChatChange >= 0
                                  ? `▲ ${doctorReportList?.[0]?.businessSnapshot?.videoChatChange}`
                                  : `▼ ${Math.abs(
                                    doctorReportList?.[0]?.businessSnapshot
                                      ?.videoChatChange
                                  )}`}
                                {/* {doctorReportList?.[0]?.businessSnapshot?.videoChatChange || 0} */}
                              </span>
                            </div>
                          </div>
                          <p className="patient-visit-count">Video Chat</p>
                        </div>
                        <div className="col-4">
                          <img
                            src={Video}
                            alt="Img1"
                            className="img-fluid"
                            width="60"
                            height="60"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="stat-box">
                      <div className="row m-0">
                        <div className="col-8">
                          <div className="row">
                            <div className="col-6">
                              <h3 className="count-report">
                                {doctorReportList?.[0]?.businessSnapshot
                                  ?.textChat || 0}
                              </h3>
                            </div>
                            <div className="col-6">
                              <span
                                className={
                                  doctorReportList?.[0]?.businessSnapshot
                                    ?.textChatChange >= 0
                                    ? `increase`
                                    : `decrease`
                                }
                              >
                                {doctorReportList?.[0]?.businessSnapshot
                                  ?.textChatChange >= 0
                                  ? `▲ ${doctorReportList?.[0]?.businessSnapshot?.textChatChange}`
                                  : `▼ ${Math.abs(
                                    doctorReportList?.[0]?.businessSnapshot
                                      ?.textChatChange
                                  )}`}
                                {/* {doctorReportList?.[0]?.businessSnapshot?.textChatChange || 0} */}
                              </span>
                            </div>
                          </div>
                          <p className="patient-visit-count">Text Chat</p>
                        </div>
                        <div className="col-4">
                          <img
                            src={Speech}
                            alt="Img1"
                            className="img-fluid"
                            width="60"
                            height="60"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="green-border"></div>
          <div className="header py-3">
            <div className="">
              <div className="row mr-0 violet-text bottom-text">
                <div className="col-6">
                  <img
                    src={Purple1}
                    alt="Img1"
                    className="img-fluid mb-2"
                    width="190"
                    height="auto"
                  />
                  <div>
                    {doctorReportList?.[0]?.association?.[0]
                      ?.contactPersonName || ""}
                  </div>
                  <div>
                    {doctorReportList?.[0]?.association?.[0]
                      ?.contactPersonPhone || ""}
                  </div>
                  <div>
                    {doctorReportList?.[0]?.association?.[0]
                      ?.contactPersonEmail || ""}
                  </div>
                </div>
                {bucket &&
                  doctorReportList?.[0]?.association?.[0]?.bottomRightLogo && (
                    <div className="col-6 d-flex align-items-center justify-content-end pr-5">
                      <img
                        src={projectLogo}
                        alt="Img1"
                        className="img-fluid"
                        height="100"
                        width="100"
                        style={{ background: "transparent" }}
                      />
                    </div>
                  )}
              </div>
              <div className="text-small text-center py-4">
                - Private and confidential. Property of PurpleHealth . Not to be
                forwarded or duplicated -
              </div>
            </div>
          </div>
        </div>
      </div>
      <DateRangePopup
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        onConfirm={handleDateRangeConfirm}
      />
    </div>
  );
};

export default DoctorPanel;
