import React, { useState, useEffect, Suspense } from "react";
import { useLocation } from "react-router-dom";
import { apiCalling } from "../../apiService";
import { Editor } from "react-draft-wysiwyg";
import $ from "jquery";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import AWS from "aws-sdk";
import showImg from "../../image/icons/blogging.png";
import Closebtn from "../../image/icons/close-sm.png";
import download from "../../image/icons/download.png";
import cancel from "../../image/icons/sh-cancel.png";
import pdf from "../../image/icons/pdf.png";
import attachment from "../../image/icons/attachment.png";
import { uploadToS3 } from "../../redux/actions/UploadFile";

const AddJournal = (props) => {
  const location = useLocation();
  const [titleTxt, setTitleTxt] = useState("");
  const [siteDataTxt, setSiteDataTxt] = useState("");
  const [fileNameOnly, setFileNameOnly] = useState("");
  const [fileNameWithPath, setFileNameWithPath] = useState("");
  const [imageNameWithPath, setImageNameWithPath] = useState([]);
  const [imageName, setImageName] = useState([]);
  const [imageNameWithPathLocal, setImageNameWithPathLocal] = useState([]);
  const [imageNameLocal, setImageNameLocal] = useState([]);
  const [siteList, setSiteList] = useState([]);
  const [s3bucketAccessKey, setS3bucketAccessKey] = useState("");
  const [AWSAccessKeyId, setAWSAccessKeyId] = useState("");
  const [region] = useState("us-east-1");
  const [bucket, setBucket] = useState("");
  const [bucketFolder, setBucketFolder] = useState("");
  const [S3BucketBlogImages, setS3BucketBlogImages] = useState("");
  const [branchesDropdown, setBranchesDropdown] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [displayForBranches, setDisplayForBranches] = useState(false);
  // const [flag, setFlag] = useState(false);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML("<p></p>"))
    )
  );
  useEffect(() => {
    branchDropdownList();
    imageUploadHandler();
    getSiteDetails();
    if (location && location.state) {
      getjournalDetails();
    }
  }, []);
  const handleBranchesAutoFill = (e) => {
    if (e.target.value) {
      setBranchData((prevBranchData) => [
        ...prevBranchData,
        { branchName: e.target.value },
      ]);
    }
  };
  const handleCancelBranch = (id, i) => {
    let copyBranchData = [...branchData];
    copyBranchData.splice(i, 1);
    setBranchData(copyBranchData);
  };
  const handleChange = (event) => {
    const { value, name } = event.target;
    switch (name) {
      case "titleTxt":
        setTitleTxt(value);
        break;
      case "siteDataTxt":
        setSiteDataTxt(value);
        break;
      default:
        break;
    }
  };
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };
  const getSiteDetails = () => {
    const siteDetail = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getAllSiteDetails",
      }),
    };
    apiCalling(siteDetail).then((data) => {
      if (data.success === "1") {
        setSiteList(data.siteData);
      }
    });
  };
  const getjournalDetails = () => {
    const journalData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getJournalsListing",
        siteId: "",
        journalId: location && location.state,
      }),
    };
    $(".loader").show();
    apiCalling(journalData).then((data) => {
      if (data.success === "1") {
        setTitleTxt(data.result[0].journalTitle);
        setFileNameWithPath(data.result[0].fileNameWithPath);
        setFileNameOnly(data.result[0].fileNameOnly);
        setImageName(
          data.result[0].imageNameOnly === ""
            ? []
            : data.result[0].imageNameOnly.split(",")
        );
        setImageNameWithPath(
          data.result[0].imageNameWithPath === ""
            ? []
            : data.result[0].imageNameWithPath.split(",")
        );
        setSiteDataTxt(data.result[0].siteId);
        setEditorState(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(data.result[0].description)
            )
          )
        );
        setBranchData(data.result[0].branchList);
        if (data.result[0].displayForBranches === true) {
          $("#displayForBranches").prop("checked", true);
          setDisplayForBranches([]);
        } else {
          $("#displayForBranches").prop("checked", false);
          setDisplayForBranches(data.result[0].displayForBranches);
        }
      }
      $(".loader").hide();
    });
  };
  const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
      c
    ) {
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };
  const imageUploadHandler = () => {
    $(".loader").show();
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "imagePath",
        siteId: "",
      }),
    };
    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        // setS3bucketAccessKey(data.configPathDetails[0].s3bucketAccessKey);
        // setAWSAccessKeyId(data.configPathDetails[0].s3bucketSecretKey);
        setBucket(data.configPathDetails[0].PurpleHealthS3bucket);
        setBucketFolder(data.configPathDetails[0].S3BucketMainFolder);
        setS3BucketBlogImages(data.configPathDetails[0].S3BucketBlogImages);
      }
      $(".loader").hide();
    });
  };
  const fileChange = (e) => {
    const imageFile = e.target.files[0];
    if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
      alert("Add file format with only JPG,JPEG,PNG format");
      return false;
    }
    if (imageFile.size > 30145728) {
      alert("File size must be under 30 MB!");
      return false;
    } else {
      setFileNameWithPath(window.URL.createObjectURL(imageFile));
      fileUpload(imageFile);
    }
  };
  // const fileUpload = async (file) => {
  //   AWS.config.update({
  //     accessKeyId: s3bucketAccessKey,
  //     secretAccessKey: AWSAccessKeyId,
  //     region: region,
  //   });
  //   var bucketParams = {
  //     Bucket: `${bucket}/${bucketFolder}/${S3BucketBlogImages}`,
  //   };
  //   const s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
  //   let S3BucketMainFolder = "";
  //   let fileName = uuidv4() + file.name;
  //   let fullFilePath = S3BucketMainFolder + fileName;
  //   try {
  //     const data = await s3.upload({
  //       Key: fullFilePath,
  //       Body: file,
  //       ACL: "public-read",
  //     }).promise();
  //     setFileNameWithPath(data.Location);
  //     setFileNameOnly(fileName);
  //   } catch (err) {
  //     alert("There was an error uploading your photo: ", err.message);
  //   }
  // }

  const fileUpload = async (file) => {
    $(".loader").show();
    try {
      let fileName = uuidv4() + file.name;
      const s3Url = await uploadToS3(file,`${process.env.REACT_APP_S3BucketMainFolder}/blogImages`,fileName);

      if (s3Url) {
        setFileNameWithPath(s3Url)
        setFileNameOnly(fileName);
      }
    } catch (error) {
      alert("There was an error uploading your photo: " + error.message);
    } finally {
      $(".loader").hide();
    }
  }
  const multipleFileChange = (e) => {
    let files = e.target.files;
    for (var i = 0; i < files.length; i++) {
      let imageFile = files[i];
      if (!imageFile.name.match(/.(jpg|jpeg|png|pdf)$/)) {
        alert("Add file format with only JPG,JPEG,PNG format");
        return false;
      }
      if (imageFile.size > 30145728) {
        alert("File size must under 30 MB!");
        return false;
      } else {
        let imageNameArrLocal = imageNameLocal;
        let imageNameWithPathArrLocal = imageNameWithPathLocal;
        imageNameArrLocal.push(imageFile.name);
        let fileName = imageFile.name.match(/\.(pdf)$/) ? pdf : window.URL.createObjectURL(imageFile);
        imageNameWithPathArrLocal.push({
          name: fileName,
          isLocal: true,
          loading: 0,
        });
        setImageNameLocal(imageNameArrLocal);
        setImageNameWithPathLocal(imageNameWithPathArrLocal);

        uploadMutipleFile(imageFile);
      }
    }
  }
  const uploadMutipleFile = async (file) => {
    $(".loader").show();
    try {
        let imageNameArr = [...imageName];
        let imageNameWithPathArr = [...imageNameWithPath];
        let imageNameArrLocal = [...imageNameLocal];
        let imageNameWithPathArrLocal = [...imageNameWithPathLocal];

        // AWS.config.update({
        //     accessKeyId: s3bucketAccessKey,
        //     secretAccessKey: AWSAccessKeyId,
        //     region: region,
        // });

        // AWS.config.httpOptions.timeout = 0;

        let fileName = uuidv4() + file.name.replaceAll(/ /g, "");
        let fileIndex = imageNameArrLocal.indexOf(file.name);

        const s3Url = await uploadToS3(file,`${process.env.REACT_APP_S3BucketMainFolder}/blogImages`, fileName);

        if (s3Url) {
            imageNameArrLocal.splice(fileIndex, 1);
            imageNameArr.push(fileName);

            // Update the loading state to 1 (completed) for the uploaded file
            imageNameWithPathArrLocal[fileIndex].loading =1;
            imageNameWithPathArrLocal[fileIndex].name = s3Url;
            imageNameWithPathArrLocal[fileIndex].isLocal = false;
          
            imageNameWithPathArr.push(s3Url);

            setImageName([...imageNameArr]);
            setImageNameWithPath([...imageNameWithPathArr]);
            // setImageNameWithPathLocal([...imageNameWithPathArrLocal]);
        }
    } catch (error) {
        alert("There was an error uploading your photo: " + error.message);
    } finally {
        $(".loader").hide();
    }
};

  // const uploadMutipleFile = (file) => {
  //   let imageNameArr = imageName;
  //   let imageNameWithPathArr = imageNameWithPath;
  //   let imageNameArrLocal = imageNameLocal;
  //   let imageNameWithPathArrLocal = imageNameWithPathLocal;
  //   AWS.config.update({
  //     accessKeyId: s3bucketAccessKey,
  //     secretAccessKey: AWSAccessKeyId,
  //     region: region,
  //   });
  //   AWS.config.httpOptions.timeout = 0;
  //   var bucketParams = {
  //     Bucket: `${bucket}/${bucketFolder}/${S3BucketBlogImages}`,
  //   };
  //   const s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
  //   let S3BucketMainFolder = "";
  //   let fileName = uuidv4() + file.name;
  //   let fullMultiplePath = S3BucketMainFolder + fileName;
  //   let fileIndex = imageNameArrLocal.indexOf(file.name);
  //   const uploadParams = {
  //     Key: fullMultiplePath,
  //     Body: file,
  //     ACL: "public-read",
  //   };
  //   const uploadRequest = s3.upload(uploadParams);
  //   uploadRequest.on("httpUploadProgress", (evt) => {
  //     let updatedLoading = [...imageNameWithPathArrLocal];
  //     updatedLoading[fileIndex].loading = Math.round((evt.loaded / evt.total) * 100);
  //     setImageNameWithPathLocal(updatedLoading);
  //   });
  //   uploadRequest.send((err, data) => {
  //     if (err) {
  //       return null;
  //     } else {
  //       imageNameArrLocal.splice(fileIndex, 1);
  //       imageNameArr.push(fileName);
  //       imageNameWithPathArrLocal.splice(fileIndex, 1);
  //       imageNameWithPathArr.push(data.Location);
  //       setImageName(imageNameArr);
  //       setImageNameWithPath(imageNameWithPathArr);
  //     }
  //   });
  // }
  // const removeFile = (filePath) => {
  //   let imageNameWithPathTemp = [...imageNameWithPath];
  //   const imageNameWithPathIndex = imageNameWithPathTemp.indexOf(filePath);
  //   if (imageNameWithPathIndex > -1) {
  //     imageNameWithPathTemp.splice(imageNameWithPathIndex, 1);
  //   }
  //   let imageUrl = filePath.split("/");
  //   let imageUrlIndex = imageUrl.length - 1;
  //   let fileName = imageUrl[imageUrlIndex];
  //   let tempimageName = [...imageName];
  //   const index = tempimageName.indexOf(fileName);
  //   if (index > -1) {
  //     tempimageName.splice(index, 1);
  //   }
  //   setImageNameWithPath(imageNameWithPathTemp);
  //   setImageName(tempimageName);
  // }
  const removeFile = (filePath) => {
    let imageNameWithPathTemp = [...imageNameWithPath];
    const imageNameWithPathIndex = imageNameWithPathTemp.indexOf(filePath);
    if (imageNameWithPathIndex > -1) {
        imageNameWithPathTemp.splice(imageNameWithPathIndex, 1);
    }

    let imageUrl = filePath.split("/");
    let imageUrlIndex = imageUrl.length - 1;
    let fileName = imageUrl[imageUrlIndex];
    let tempimageName = [...imageName];
    const index = tempimageName.indexOf(fileName);
    if (index > -1) {
        tempimageName.splice(index, 1);
    }

    setImageNameWithPath([...imageNameWithPathTemp]);
    setImageName([...tempimageName]);
};
  const validateForm = () => {
    let editorStateHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    if (titleTxt === "") {
      alert("Title required.");
    } else if (siteDataTxt === "") {
      alert("Site Details required.");
      return false;
    } else if (fileNameWithPath === "") {
      alert("Blog Image required.");
      return false;
    } else if (editorStateHtml === "") {
      alert("Detailed Description required.");
      return false;
    } else {
      return true;
    }
  }
  const saveHandle = () => {
    let branchId = [];
    if (validateForm()) {
      let siteId = $("#siteDataTxt").val();
      if (displayForBranches === false) {
        for (let i = 0; i < branchData.length; i++) {
          branchId.push(branchData[i].branchName);
        }
      }
      const saveField = {
        method: "POST",
        body: JSON.stringify({
          functionName: "saveJournals",
          siteId: siteId,
          journalId: location && location.state === "null" ? "" : location && location.state,
          fileName: fileNameOnly,
          title: titleTxt,
          description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
          imageName: imageName.join(),
          displayForBranches: displayForBranches,
          branches: branchId.toString(),
        }),
      };
      $(".loader").show();
      apiCalling(saveField).then((data) => {
        $(".loader").hide();
        if (data.success === "1") {
          let message = location && location.state > 0 ? "Updated successfully " : "Save successfully";
          alert(message);
          cancelHandle();
        } else if (data.success === "0") {
          alert(data.errorMessage);
        }
      });
    }
  }
  const cancelHandle = () => {
    window.location.href = "/adminManagement/journalDashboard/journalListing";
  };
  const srcTypeImage = (ev) => {
    ev.target.src = showImg;
  };
  const branchDropdownList = () => {
    const branchDropdown = {
      method: "POST",
      body: JSON.stringify({
        functionName: "branchListing",
        keyWord: "",
      }),
    };
    $(".loader").show();
    apiCalling(branchDropdown).then((data) => {
      if (data.success == "1") {
        $(".loader").hide();
        setBranchesDropdown(data.result);
      }
      if (data.success == "0") {
        $(".loader").hide();
        setBranchesDropdown([]);
      }
    });
  };
  const handleBranchesChange = (e) => {
    if (!e.target.checked) {
      $("#BranchList").fadeIn("fast");
      setDisplayForBranches(false);
    } else {
      $("#BranchList").fadeOut("fast");
      setDisplayForBranches(true);
    }
  };
  return (
    <div className="purpleWrap">
      <Suspense fallback={<div>Waiting...</div>}>
        <section>
          <Header /> <div className="loader"></div>
        </section>
      </Suspense>
      <div className="container">
        <div className="main-wrapper-smwidth1">
          <div className="col-md-12 p-0">
            <div>
              <ul className="breadcrum-adminMangmnt">
                <li>
                  <a href="/admin/dashboard">Dashboard</a>
                </li>
                <li>
                  <a href="/adminManagement/journalDashboard/journalListing">Journal List</a>
                </li>
                <li>
                  <a href="" className="current">
                    Add Journal
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-12 form-head-journal mb-0">Journal</div>
          <div className="form-section-brdr-jounal">
            <div className="row">
              <div className="row-margin col-lg-5">
                <label className="col-sm-12 label-text-journal">
                  Add Title <span className="star-red-label">*</span>
                </label>
                <div className="col-sm-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Blog Title"
                    id="titleTxt"
                    name="titleTxt"
                    value={titleTxt}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row-margin col-lg-4">
                <label className="col-sm-12 label-text-journal">
                  Site Details <span className="star-red-label">*</span>
                </label>
                <div className="col-sm-12">
                  <select
                    className="form-control"
                    id="siteDataTxt"
                    name="siteDataTxt"
                    onChange={handleChange}
                    value={siteDataTxt}
                  >
                    <option value="">Select Site</option>
                    {siteList.map((obj) =>
                      obj.siteId === siteDataTxt ? (
                        <option key={obj.siteId} value={obj.siteId} selected>
                          {obj.siteName}
                        </option>
                      ) : (
                        <option key={obj.siteId} value={obj.siteId}>
                          {obj.siteName}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
              <label className="d-flex align-items-center mb-0">
                Display For All Branches
                <input
                  type="checkbox"
                  name="displayForBranches"
                  id="displayForBranches"
                  className="mx-3"
                  onChange={handleBranchesChange}
                />
              </label>
              {!displayForBranches ? (
                <div className="col-md-12">
                  <div id="BranchList" className="row">
                    <div className="col-md-6 mb-3">
                      <div className="row-margin">
                        {/* <div className="col-md-4"> */}
                        {/* Branch List<span className="text-danger">*</span> */}
                        <label className="col-sm-12 label-text-journal">
                          Branch List <span className="star-red-label">*</span>
                        </label>
                        {/* </div> */}
                        <div className="col-md-8 d-flex align-items-center">
                          <select
                            className="form-control"
                            onChange={(e) => handleBranchesAutoFill(e)}
                          >
                            <option value="" defaultChecked>
                              Select Branches
                            </option>
                            {branchesDropdown.map((data) => (
                              <option key={data.branchName} value={data.branchName}>
                                {data.branchName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mb-3 ml-3">
                      <div className="row">
                        {branchData.map((data, i) => (
                          <div className="col-md-3" key={data.branchId}>
                            <div className="d-flex align-items-center">
                              <input
                                type="text"
                                readOnly
                                className="form-control"
                                value={data.branchName}
                              />
                              <div className="input_icon">
                                <a onClick={() => handleCancelBranch(data.branchName, i)}>
                                  <img src={Closebtn} />
                                </a>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="col-sm-6 mt-2 px-5">
                <div className="fileUpload-medical-journal mr-3">
                  <span>Add Blog Image</span>
                  <input
                    type="button"
                    id="attach"
                    name="attach"
                    className="upload"
                  />
                  <input
                    type="file"
                    className="upload"
                    name="fileText"
                    id="fileText"
                    onChange={fileChange}
                  />
                </div>
                <div className="fileUpload-img">
                  <img
                    onError={srcTypeImage}
                    id="fileNameWithPath"
                    src={fileNameWithPath}
                    width="50"
                    alt="Blog"
                  ></img>
                </div>
                <p className="note-journlimg">
                  Support File Type:JPEG,JPG,PNG
                </p>
              </div>
              <div className="row-margin detldes-div col-sm-12">
                <label className="col-sm-12 label-text-journal">
                  Detailed Description{" "}
                </label>
                <div className="col-sm-12">
                  <Editor
                    editorState={editorState}
                    initialEditorState={editorState}
                    toolbarclassName="toolbarclassName"
                    wrapperclassName="redactor-wrapper"
                    editorclassName="editorclassName"
                    onEditorStateChange={onEditorStateChange}
                    handlePastedText={() => false}
                    editorStyle={{
                      minHeight: "200px",
                      maxHeight: "200px",
                      overflowX: "hidden",
                      overflowY: "scroll",
                      borderWidth: "1px",
                      borderColor: "#989898",
                      borderStyle: "solid",
                      borderRadius: "10px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  />
                </div>
              </div>
              <div className="row-margin col-lg-12">
                <label className="col-sm-12 label-text-journal">
                  <a>
                    <img
                      src={attachment}
                      style={{
                        padding: "1px",
                        width: "30px",
                        height: "30px",
                      }}
                      alt="Attachments"
                    ></img>{" "}
                    Attachments
                  </a>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    multiple
                    id="fileAttacthments"
                    onChange={multipleFileChange}
                  />
                </label>
                <div className="row col-sm-12 mt-3">
                  {imageNameWithPath.map((x) => {
                    if (x.split(".").pop() === "pdf") {
                      return (
                        <div key={x} className="attchment-adddiv mb-3">
                          <a onClick={() => removeFile(x)}>
                            <img
                              src={cancel}
                              className="attachment-cancelImg"
                              alt="Cancel"
                            ></img>
                          </a>
                          <img
                            src={pdf}
                            width="100"
                            height="100"
                            style={{ objectFit: "cover", objectPosition: "center" }}
                            alt="PDF"
                          ></img>
                          <a
                            href={x}
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                          >
                            <img
                              src={download}
                              className="attachment-downloadImg"
                              alt="Download"
                            ></img>
                          </a>
                        </div>
                      );
                    } else {
                      return (
                        <div key={x} className="attchment-adddiv mb-3">
                          <a onClick={() => removeFile(x)}>
                            <img
                              src={cancel}
                              className="attachment-cancelImg"
                              alt="Cancel"
                            ></img>
                          </a>
                          <img
                            src={x}
                            width="100"
                            height="100"
                            style={{ objectFit: "cover", objectPosition: "center" }}
                            alt="Attachment"
                          ></img>
                          <a
                            href={x}
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                          >
                            <img
                              src={download}
                              className="attachment-downloadImg"
                              alt="Download"
                            ></img>
                          </a>
                        </div>
                      );
                    }
                  })}

                  {/* {imageNameWithPathLocal.map((x) => {
                    return (
                      <div key={x.name} className="attchment-adddiv mb-3">
                        <img
                          src={x.name}
                          width="100"
                          height="100"
                          style={{ objectFit: "cover", objectPosition: "center" }}
                          alt="Attachment"
                        ></img>
                        <div
                          style={{
                            position: "absolute",
                            display: "block",
                            width: "100px",
                            height: "100px",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            zIndex: 2,
                            cursor: "pointer",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              fontSize: "40px",
                              color: "white",
                              transform: "translate(-50%,-50%)",
                            }}
                          >{x.loading}</div>
                        </div>
                      </div>
                    );
                  })} */}
                </div>
              </div>
              <div className="col-sm-12 formButton-divright mt-3">
                <input
                  type="submit"
                  value={location && location.state > 0 ? "Update" : "Save"}
                  id="saveType"
                  className="formButton-save"
                  onClick={saveHandle}
                ></input>
                <input
                  type="button"
                  value="Cancel"
                  className="formButton-cancel"
                  onClick={cancelHandle}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Suspense fallback={<div></div>}>
        <section>
          <Footer />
        </section>
      </Suspense>
    </div>
  )
}
export default AddJournal;      