import React, { Component, Suspense } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import $ from "jquery";
import AWS from "aws-sdk";
import { apiCalling } from "../../../apiService";
import Header from "../../../layout/header";
import Footer from "../../../layout/footer";
import ErrorPage from "./registrationErrorPage";
import TimeComponent from "./timeComponent";
import loaderImg from "../../../image/icons/preloader.gif";
import { uploadToS3 } from "../../../redux/actions/UploadFile";

// const defaultHospitalId = 12694; //prod
const defaultHospitalId = 12841; //test
var s3;
var fileNameUploadSignature;
var fileNameUploadProfile;

export default class SimpleDoctorRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doctorRegistrationAvailableCount: 1,
      languageData: [],
      timeDropDown: [],
      languageData: [],
      languageEdit: [],
      timeFilterData: [],
      hospitalData: [],
      countryData: [],
      stateData: [],
      cityData: [],
      cityId: "",
      subCityData: [],
      medicineBranchData: [],
      medicineBranchId: null,
      selectedMedicineBranchId: 0,
      specialization: [],
      dateOfBirth: "",
      yearOfEducation: "",
      educationData: [],
      educationDetailsArray: [{ Id: "", year: "", college: "" }],
      achievementTypeData: [],
      year: [],
      fileSignature: null,
      fileAppProfile: null,
      fullFilePath: "",
      hospitalClick: 0,
      s3bucketAccessKey: "",
      s3bucketSecretKey: "",
      s3BucketMainFolder: "",
      s3BucketAddressPath: "",
      s3BucketDoctorImages: "",
      s3Bucket: "",
      hospitalArray: [],
      subCityArray: [],
      cityArray: [],

      associationData: [],
      countryArray: [],
      isIDALogin: "false",
      pmSiteId: "",
      isData: 0,
      doctorRegistrationAvailableCount: 1,
      selState: "",
      stateId: "",
      selCity: "",
      selLocation: "",
      selspecializationId: [],
      GetisEhrEnabled: false,
      GetpmPaymentDoned: false,
      associationId: "",
      s3bucketAccessKey: "",
      s3bucketSecretKey: "",
      s3BucketMainFolder: "",
      s3BucketAddressPath: "",
      s3BucketDoctorImages: "",
      s3Bucket: "",
      seoSlug: "",
    };
    this.inputRefs = {
      educationRef: React.createRef(),
      associationRef: React.createRef(),
      genderRef: React.createRef(),
      nameRef: React.createRef(),
      titleRef: React.createRef(),
      doctorTitleRef: React.createRef(),
      // educationRef : React.createRef(),
      // educationRef : React.createRef(),
    };
  }

  componentDidMount() {
    $("#sunFrom").on("change", () => {
      let sunVal = $("#sunFrom").val();
      $(".testMainFrom").val(sunVal)
    });
    $("#sunTo").on("change", () => {
      let sunValTo = $("#sunTo").val();
      $(".testMainTo").val(sunValTo)
    });

    this.dataListing();
    this.setState({
      timeDropDown: [
        { val: "24", text: "12:00 AM" },
        { val: "48", text: "12:30 AM" },
        { val: "1", text: "01:00 AM" },
        { val: "25", text: "01:30 AM" },
        { val: "2", text: "02:00 AM" },
        { val: "26", text: "02:30 AM" },
        { val: "3", text: "03:00 AM" },
        { val: "27", text: "03:30 AM" },
        { val: "4", text: "04:00 AM" },
        { val: "28", text: "04:30 AM" },
        { val: "5", text: "05:00 AM" },
        { val: "29", text: "05:30 AM" },
        { val: "6", text: "06:00 AM" },
        { val: "30", text: "06:30 AM" },
        { val: "7", text: "07:00 AM" },
        { val: "31", text: "07:30 AM" },
        { val: "8", text: "08:00 AM" },
        { val: "32", text: "08:30 AM" },
        { val: "9", text: "09:00 AM" },
        { val: "33", text: "09:30 AM" },
        { val: "10", text: "10:00 AM" },
        { val: "34", text: "10:30 AM" },
        { val: "11", text: "11:00 AM" },
        { val: "35", text: "11:30 AM" },
        { val: "12", text: "12:00 PM" },
        { val: "36", text: "12:30 PM" },
        { val: "13", text: "01:00 PM" },
        { val: "37", text: "01:30 PM" },
        { val: "14", text: "02:00 PM" },
        { val: "38", text: "02:30 PM" },
        { val: "15", text: "03:00 PM" },
        { val: "39", text: "03:30 PM" },
        { val: "16", text: "04:00 PM" },
        { val: "40", text: "04:30 PM" },
        { val: "17", text: "05:00 PM" },
        { val: "41", text: "05:30 PM" },
        { val: "18", text: "06:00 PM" },
        { val: "42", text: "06:30 PM" },
        { val: "19", text: "07:00 PM" },
        { val: "43", text: "07:30 PM" },
        { val: "20", text: "08:00 PM" },
        { val: "44", text: "08:30 PM" },
        { val: "21", text: "09:00 PM" },
        { val: "45", text: "09:30 PM" },
        { val: "22", text: "10:00 PM" },
        { val: "46", text: "10:30 PM" },
        { val: "23", text: "11:00 PM" },
        { val: "47", text: "11:30 PM" },
        { val: "49", text: "12:00 AM" },
      ],
    });
    this.setState(
      {
        educationDetailsArray: [...this.state.educationDetailsArray],
      },
      () => this.yearDropdown(0)
    );
  }
  dataListing() {
    var temphospitalData;
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getHospitalDropdown",
      }),
    };
    apiCalling(apiJson).then((data) => {
      this.setState({
        languageData: data.hospitalData[0].languages,
        timeFilterData: data.hospitalData[0].timeFilterData,
        achievementTypeData: data.hospitalData[0].achievementTypeData,
        s3bucketAccessKey: data.hospitalData[0].s3bucketAccessKey,
        s3bucketSecretKey: data.hospitalData[0].s3bucketSecretKey,
        s3BucketMainFolder: data.hospitalData[0].S3BucketMainFolder,
        s3BucketAddressPath: data.hospitalData[0].s3BucketAddressPath,
        s3BucketDoctorImages: data.hospitalData[0].S3BucketDoctorImages,
        s3Bucket: data.hospitalData[0].s3Bucket,
        associationData: data.hospitalData[0].associationData,
        educationData: data.hospitalData[0].education,
        // specialization: data.hospitalData[0].specializationData,
        isData: 1,
      });
      //   temphospitalData = hospitalData.result;
      //   temphospitalData = temphospitalData.filter(function (value, index, arr) {
      //     return value.hospitalId !== defaultHospitalId;
      //   });
    });
    const apiJson2 = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getDropdownLists",
      }),
    };
    apiCalling(apiJson2).then((data) => {
      this.setState(
        {
          countryData: data.result[0].countryData,
          stateData: data.result[0].stateData,
          cityData: data.result[0].cityData,
          subCityData: data.result[0].subCityData,
          titleData: data.result[0].doctorTitleData,
          medicineBranchData: data.result[0].medicineBranchData,
          hospitalData: data.result[0].hospitalData,
          s3bucketAccessKey: data.result[0].s3bucketAccessKey,
          s3bucketSecretKey: data.result[0].s3bucketSecretKey,
          s3BucketMainFolder: data.result[0].S3BucketMainFolder,
          s3BucketAddressPath: data.result[0].s3BucketAddressPath,
          s3BucketDoctorImages: data.result[0].S3BucketDoctorImages,
          s3Bucket: data.result[0].s3Bucket,
        },
        () => {
          AWS.config.update({
            accessKeyId: this.state.s3bucketAccessKey,
            secretAccessKey: this.state.s3bucketSecretKey,
            region: "us-east-1",
          });
          var bucketParams = {
            Bucket: this.state.s3Bucket,
          };
          s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
        }
      );
    });
    // const hospitalAutoComplete = {
    //   method: "POST",
    //   body: JSON.stringify({
    //     functionName: "hospitalsListBySite",
    //     key: "",
    //     siteId: "",
    //   }),
    // };
    // apiCalling(hospitalAutoComplete).then((hospitalData) => {
    //   console.log("listinggg...", hospitalData);
    //   temphospitalData = hospitalData.result;
    //   temphospitalData = temphospitalData.filter(function (value, index, arr) {
    //     return value.hospitalId !== defaultHospitalId;
    //   });
    // });
    // const apiJson = {
    //   method: "POST",
    //   body: JSON.stringify({
    //     functionName: "doctorMasterData",
    //     idaUserId: "0",
    //     siteId: "",
    //   }),
    // };
    // apiCalling(apiJson).then((data) => {
    //   if (data.success === "1") {
    //     let pathname = new URL(window.location.href).pathname;
    //     let idaId = pathname.split("/")[4];
    //     if (idaId === "0" || idaId === undefined || idaId === null) {
    //       this.setState({
    //         educationData: data.result[0].educationData,
    //       });
    //     } else {
    //       this.setState({
    //         educationData: data.result[0].educationDataDental,
    //       });
    //     }
    //     this.setState(
    //       {
    //         titleData: data.result[0].doctorTitleData,
    //         languageData: data.result[0].languageData,
    //         timeFilterData: data.result[0].timeFilterData,
    //         hospitalData: temphospitalData,
    //         countryData: data.result[0].countryData,
    //         stateData: data.result[0].stateData,
    //         cityData: data.result[0].cityData,
    //         subCityData: data.result[0].subCityData,
    //         medicineBranchData: data.result[0].medicineBranchData,
    //         achievementTypeData: data.result[0].achievementTypeData,
    //         s3bucketAccessKey: data.result[0].s3bucketAccessKey,
    //         s3bucketSecretKey: data.result[0].s3bucketSecretKey,
    //         s3BucketMainFolder: data.result[0].S3BucketMainFolder,
    //         s3BucketAddressPath: data.result[0].s3BucketAddressPath,
    //         s3BucketDoctorImages: data.result[0].S3BucketDoctorImages,
    //         s3Bucket: data.result[0].s3Bucket,
    //         isData: 1,
    //       },
    //       () => {
    //         if (this.state.languageEdit.length > 0) {
    //           $("#cmbLanguage").val(this.state.languageEdit);
    //         } else {
    //           $('#cmbLanguage option[value="1"]').prop("selected", true);
    //         }
    //         if (data.result[0].idaUserData.length > 0) {
    //           if (data.result[0].idaUserData[0].first_name !== null) {
    //             $("#txtFirstName").val(
    //               data.result[0].idaUserData.length > 0
    //                 ? data.result[0].idaUserData[0].first_name
    //                 : ""
    //             );
    //             // $("#txtDisplayName").val(data.result[0].idaUserData.length > 0 ? data.result[0].idaUserData[0].first_name : "")
    //           }

    //           if (!data.result[0].idaUserData[0].email) {
    //             $("#txtEmail").val(
    //               data.result[0].idaUserData.length > 0
    //                 ? data.result[0].idaUserData[0].email
    //                 : ""
    //             );
    //             $("#txtEmail").prop("readonly", true);
    //           }
    //         }
    //         $('#doctorTitle option[value="Dr."]').attr("selected", true);
    //         // $('#cmbTimeFilter option[value="2"]').attr("selected",true);

    //         //console.log("data.result[0].medicineBranchData", data.result[0].medicineBranchData)
    //         // alert(this.state.isIDALogin)
    //         if (this.state.isIDALogin === "true") {
    //           let obj1 = data.result[0].medicineBranchData.filter(
    //             (o) => o.medicineBranchId === 4
    //           );
    //           // console.log("")
    //           this.setState({
    //             specialization: obj1[0].specializationData,
    //           });
    //           //console.log("obj1", obj1)
    //         }

    //         this.yearDropdown(0);
    //         if (this.state.selState) {
    //           $('#cmbState option[value="' + this.state.selState + '"]').attr(
    //             "selected",
    //             true
    //           );
    //           this.cityAgainstState(this.state.selState);
    //         }
    //       }
    //     );

    //     AWS.config.update({
    //       accessKeyId: data.result[0].s3bucketAccessKey,
    //       secretAccessKey: data.result[0].s3bucketSecretKey,
    //       region: "us-east-1",
    //     });
    //     var bucketParams = {
    //       Bucket: data.result[0].s3Bucket,
    //     };
    //     s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    //   }
    // });
  }
  isEmail = (email) => {
    var isMailOk = new RegExp(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6}$/i).test(
      email
    );
    return isMailOk;
  };
  primarySpecialisationValidation(i) {
    if (
      $("#chkPrimarySpecialisation" + i).is(":checked") === true &&
      $("#chkSpecialisation" + i).is(":checked") === false
    ) {
      alert("Please add corresponding Specialisation");
      $("#chkPrimarySpecialisation" + i).prop("checked", false);
    }
  }
  handleImageUpload(e, mode) {
    //console.log('e........', e)
    //console.log('e.target.files[0]........', e.target.files[0])
    if (typeof e.target.files[0] !== "undefined") {
      let file = e.target.files[0];
      let fileType = file["type"];
      //console.log('fileType........', fileType)
      let validImageTypes = ["image/jpg", "image/jpeg", "image/png"];
      if (!validImageTypes.includes(fileType)) {
        alert("Invalid file type");
        $("#" + mode + "File").val(null);
        if (mode === "attachImg") {
          $("#image").attr("src", "");
          $("#imgDiv").hide();
        } else {
          $("#pofileImage").attr("src", "");
          $("#imgProfileDiv").hide();
        }
      } else {
        var fileObj = e.target.files[0];
        let reader = new FileReader();
        var img = new Image();
        img.src = window.URL.createObjectURL(fileObj);
        img.onload = function () {
          reader.onload = function (e) {
            if (mode === "attachImg") {
              $("#image").attr("src", e.target.result);
              $("#imgDiv").show();
            } else {
              $("#pofileImage").attr("src", e.target.result);
              $("#imgProfileDiv").show();
            }
          };

          reader.readAsDataURL(fileObj);
        };
        if (mode === "attachImg") {
          this.setState({ fileSignature: fileObj });
        } else {
          this.setState({ fileAppProfile: fileObj });
        }
      }
    }
  }
  handleChangeDate(date) {
    this.setState({
      dateOfBirth: date,
    });
  }
  disableDateField() {
    $("#txtDateOfBirth").attr("readonly", "readonly");
    this.setState({
      dateOfBirth: null,
    });
  }
  hospitalChange() {
    $("#btnOk").show();
    this.hospitalNameOk();
  }
  hospitalNameOk() {
    if ($("#cmbHospitalName").val().length === 0) {
      $("#btnOk").show();
    }
    this.setState({
      hospitalArray: [],
    });
    $("#hospitalId").val("");

    if ($("#cmbHospitalName").val().length > "2") {
      let obj = this.state.hospitalData.filter((o) =>
        o.hospitalName
          .toString()
          .toLowerCase()
          .includes($("#cmbHospitalName").val().toLowerCase())
      );

      this.setState(
        {
          hospitalArray: obj,
        },
        () => {
          var name = $("#cmbHospitalName").val();
          if (this.state.hospitalArray.length === 0) {
            $("#hospitalProfessionalStatementDiv").show()
            $("#hospitalserviceOfferedDiv").show()
            $("#txtAddress").val("");
            $("#newHospitalSelectDiv").show();
            $("#hospitalSelectDiv").hide();
            $("#cmbHospitalName").val("");
            $("#txtHospitalName").val(name);
            this.setState({
              hospitalClick: 1,
            });
          }
        }
      );
    }
  }
  setHospitalId(hospitalId, hospitalName, address) {
    this.setState({
      hospitalArray: [],
    });
    if (hospitalId !== "") {
      $("#btnOk").hide();
    }

    $("#hospitalId").val(hospitalId);
    $("#cmbHospitalName").val(hospitalName + " (" + address + ")");
    $("#txtAddress").val(address);
  }
  setHospitalAddress = (e) => {
    $("#txtAddress").val(e.target.value);
  };
  cancelHospital() {
    // $("#addHospitalBtn").show();
    $("#hospitalSelectDiv").show();
    $("#newHospitalSelectDiv").hide();
    $("#hospitalProfessionalStatementDiv").hide()
    $("#hospitalserviceOfferedDiv").hide()
    $("#txtHospitalName").val("");
    $("#txtHospitalAddress").val("");
    $("#txtClinicEmailId").val("")
    this.setState({
      hospitalClick: 0,
    });
  }
  loadTime(mode1, mode2, mode3) {
    var dayArray = ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"];
    var val = $("#" + mode3).val();
    for (var i = 0; i < dayArray.length; i++) {
      if ($("#" + mode1 + dayArray[i] + mode2).val() === "0") {
        $("#" + mode1 + dayArray[i] + mode2).val(val);
      }
    }
  }
  timeValidation() { }
  changeHospitalName = (e) => {
    if (this.state.hospitalClick === 1) {
      $("#txtHospitalAddress").val(e.target.value);
    }
  };
  cityAgainstState(e) {
    this.setState({
      countryArray: [],
    });
    this.setState({
      cityArray: [],
    });
    const selectedStateId = e.target.options[e.target.selectedIndex].getAttribute("data-id");
    let stateObj = this.state.stateData.filter(
      (o) => o.stateName === $("#cmbState option:selected").text()
    );

    let obj1 = this.state.cityData.filter(
      (o) => o.stateName === $("#cmbState option:selected").text()
    );

    this.setState({
      stateId: selectedStateId,
      cityArray: obj1,
    });
    // if(statename!==""){
    // let objstate = this.state.cityData.filter(o => o.stateName ===statename);

    // this.setState({
    //   cityArray: objstate
    // })}
    if (stateObj[0]) {
      let obj2 = this.state.countryData.filter(
        (o) => o.countryId === stateObj[0].countryId
      );

      // console.log("obj2....", obj2[0].countryId.toString())
      this.setState(
        {
          countryArray: obj2,
        },
        () => {
          if (this.state.countryArray.length === 1) {
            $("#cmbCountry").val(obj2[0].countryId);
          }
          if (this.state.selCity) {
            $('#cmbCity option[value="' + this.state.selCity + '"]').attr(
              "selected",
              true
            );
            this.subCityAgainstCity();
          }
        }
      );
    }
  }

  subCityAgainstCity(e) {
    // this.setState({
    //   subCityArray: []
    // })
    const cityId = e.target.options[e.target.selectedIndex].getAttribute("data-id");
    let obj = this.state.subCityData.filter(
      (o) => o.cityName === $("#cmbCity option:selected").text()
    );

    this.setState(
      {
        cityId: cityId,
        subCityArray: obj,
      },
      () => {
        if (this.state.selLocation && this.state.selLocation !== "0") {
          $('#cmbSubCity option[value="' + this.state.selLocation + '"]').attr(
            "selected",
            true
          );
        }
      }
    );
  }
  handleChange(event) {
    // alert(event.target.keyCode)
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || !re.test(event.target.value)) {
      var a = event.target.value.toString().slice(0, -1);
      $("#" + event.target.id).val(a);
      if (!re.test($("#" + event.target.id).val())) {
        $("#" + event.target.id).val("");
      }
    } else {
      return true;
    }
  }
  getSpecialisation() {
    var branchIndex = $("#cmbMedicineBranch").val().toString().split("-");

    var specilisationArray =
      this.state.medicineBranchData[parseInt(branchIndex[0])]
        .specializationData;
    this.setState({
      specialization: specilisationArray,
      medicineBranchId: branchIndex[0],
      selectedMedicineBranchId:
        this.state.medicineBranchData[parseInt(branchIndex[0])]
          .medicineBranchId,
    });
    //console.log("specialization.....", this.state.specialization)
  }
  specialityClick(specializationId, index) {
    var medicineBranchId =
      this.state.isIDALogin == "true" ? 0 : this.state.medicineBranchId;
    this.state.medicineBranchData[medicineBranchId].specializationData[
      index
    ].checked = $("#chkSpecialisation" + index).is(":checked");
    this.setState(
      {
        specialization:
          this.state.medicineBranchData[medicineBranchId].specializationData,
      },
      () => {
        console.log("hi", this.state.specialization);
      }
    );
  }
  setEducationData(i, mode) {
    // alert("oooo")
    if (mode === "cmbEducation") {
      this.state.educationDetailsArray[i].Id = $("#" + mode + i).val();
    } else if (mode === "cmbYear") {
      this.state.educationDetailsArray[i].year = $("#" + mode + i).val();
    } else if (mode === "txtCollege") {
      this.state.educationDetailsArray[i].college = $("#" + mode + i).val();
    }
    // console.log("educationDetailsArray...set......", this.state.educationDetailsArray)
    this.setState({
      educationDetailsArray: this.state.educationDetailsArray,
    });
  }
  yearDropdown(index) {
    var ddlYears = document.getElementById("cmbYear" + index);
    var currentYear = new Date().getFullYear();
    if (ddlYears !== null && ddlYears !== "null") {
      $("#cmbYear" + index).append("<option value='0'>Select</option>");
      for (var i = currentYear; i >= 1950; i--) {
        $("#cmbYear" + index).append(
          "<option value='" + i + "'>" + i + "</option>"
        );
      }
    }
  }
  educationDetails(i) {
    var flag = false;
    if (this.state.educationDetailsArray.length > 0) {
      for (var i = 0; i <= this.state.educationDetailsArray.length; i++) {
        if ($("#cmbEducation" + i).val() === "") {
          alert("Education Required");
          $("#cmbEducation" + i).focus();
          flag = true;
          break;
        }
      }
    }
    if (flag === false) {
      var a = {
        Id: "",
        year: "",
        college: "",
      };
      this.state.educationDetailsArray.push(a);
      //console.log("educationDetailsArray....", this.state.educationDetailsArray)
      this.setState(
        {
          educationDetailsArray: this.state.educationDetailsArray,
        },
        () => {
          this.yearDropdown(i - 1);
        }
      );

      // console.log("iiiii.....", i)
    }
  }
  removeEducationDetails(educationId, i) {
    var data = $.grep(this.state.educationDetailsArray, function (e) {
      return e.Id !== educationId;
    });
    this.state.educationDetailsArray = data;
    this.setState({
      educationDetailsArray: this.state.educationDetailsArray,
    });
    $("#cmbYear" + i).val("");
    $("#txtCollege" + i).val("");
    if (data.length === 0) {
      this.educationDetails(0);
    }
  }
  textBoxEneble(id) {
    if ($("#chk" + id).is(":checked") === true) {
      $("#txt" + id).show();
      $("#txt" + id).focus();
    } else {
      $("#txt" + id).hide();
    }
    if ($("#chkInperson").is(":checked") === true) {
      $("#hiddenInpersonTime").show();
    } else {
      $("#hiddenInpersonTime").hide();
    }
    if (
      $("#chkVideo").is(":checked") === true ||
      $("#chkAudio").is(":checked") === true
    ) {
      $("#audioVideoAppointmentDiv").show();
    } else {
      $("#audioVideoAppointmentDiv").hide();
    }
  }
  validation() {
    var flag = true;
    var email = $("#txtEmail").val();
    var ClinicEmailId = $("#txtClinicEmailId").val()
    if ($("#associationSelect").val() === "0") {
      alert("Select Association please");
      // $("#associationSelect").focus();
      this.inputRefs.associationRef.current.focus();
      flag = false;
    } else if ($("#doctorTitle").val() === "0") {
      this.inputRefs.doctorTitleRef.current.focus();
      // alert("Title Required");
      $("#doctorTitle").focus();
      flag = false;
    } else if ($("#txtFirstName").val() === "") {
      alert("Full Name Required");
      $("#txtFirstName").focus();
      flag = false;
    } else if (
      $("#inlineRadio1").is(":checked") === false &&
      $("#inlineRadio2").is(":checked") === false
    ) {
      alert("Gender Required");
      $("#inlineRadio1").focus();
      flag = false;
    } else if (
      this.state.isIDALogin === "false" &&
      $("#txtPassword").val() === ""
    ) {
      alert("Password Required");
      $("#txtPassword").focus();
      flag = false;
    } else if (
      this.state.isIDALogin === "false" &&
      $("#txtPassword").val().length < 6
    ) {
      alert("Password must contain at least 6 characters");
      $("#txtPassword").focus();
      flag = false;
      // } else if ($("#txtConformPassword").val() === "") {
      //   alert("Confirm Password Required");
      //   setTimeout(() => {
      //     $("#txtConformPassword").focus();
      //   }, 100);
      //   flag = false;
      // } else if (
      //   this.state.isIDALogin === "false" &&
      //   $("#txtPassword").val() !== $("#txtConformPassword").val()
      // ) {
      //   alert("Password and confirm password does not match ");
      //   $("#txtConformPassword").val("");
      //   $("#txtPassword").val("");
      //   $("#txtPassword").focus();
      //   flag = false;
    } else if ($("#cmbLanguage").val().length === 0) {
      alert("Select Languages");
      $("#cmbLanguage").focus();
      flag = false;
    } else if (
      this.state.hospitalClick === 0 &&
      $("#hospitalId").val() === ""
    ) {
      alert("Select Hospital from suggestions");
      $("#cmbHospitalName").focus();
      flag = false;
    } else if (
      this.state.hospitalClick === 1 &&
      $("#txtHospitalName").val() === ""
    ) {
      alert("Hospital name Required");
      $("#txtHospitalName").focus();
      flag = false;
    } else if ($("#txtEmail").val() === "") {
      alert("Doctor Email Required");
      $("#txtEmail").focus();
      flag = false;
    } else if (this.isEmail(email) === false) {
      alert("Invalid Email id");
      $("#txtEmail").focus();
      flag = false;
    }
    else if ($("#txtClinicEmailId").val() === "") {
      alert("Clinic  Email id required");
      $("#txtClinicEmailId").focus();
      flag = false;
    }
    else if (this.isEmail(ClinicEmailId) === false) {
      alert("Invalid Clinic Email id");
      $("#txtClinicEmailId").focus();
      flag = false;
    } else if ($("#txtAddress").val() === "") {
      alert("Clinic Address Required");
      $("#txtAddress").focus();
      flag = false;
    } else if ($("#cmbState").val() === "") {
      alert("State Required");
      $("#cmbState").focus();
      flag = false;
    } else if ($("#cmbCity").val() === "") {
      alert("City / District Required");
      $("#cmbCity").focus();
      flag = false;
    } else if ($("#txtPinCode").val() === "") {
      alert("Pin Code Required");
      $("#txtPinCode").focus();
      flag = false;
    } else if ($("#txtMobile").val() === "") {
      alert("Doctor Mobile Number Required");
      $("#txtMobile").focus();
      flag = false;
    } else if (!$("#txtMobile").val().match("[0-9]{10}")) {
      alert("Please enter valid Mobile Number");
      $("#txtMobile").focus();
      flag = false;
    } else if ($("#txtPhone").val() === "") {
      alert("Please Fill the field Clinic Phone no");
      $("#txtPhone").focus();
      flag = false;
    } else if (
      this.state.isIDALogin === "false" &&
      $("#cmbMedicineBranch").val() === ""
    ) {
      alert("Medicine Branch Name Required");
      $("#cmbMedicineBranch").focus();
      flag = false;
    } else if ($("#txtAreaStatement").val() === "") {
      alert("Brief Description about yourself Required");
      $("#txtAreaStatement").focus();
      flag = false;
    } else if ($("#cmbEducation0").val() === "0") {
      alert("Education Details Required");
      $("#cmbEducation0").focus();
      flag = false;
      // } else if ($("#cmbSubCity").val() === "") {
      //   alert("Place Required");
      //   $("#cmbSubCity").focus();
      //   flag = false;
    } else if (
      this.state.isIDALogin === "false" &&
      $("#chkInperson").is(":checked") === false &&
      $("#chkVideo").is(":checked") === false &&
      $("#chkAudio").is(":checked") === false &&
      $("#chkText").is(":checked") === false
    ) {
      alert("Select atleast one Service");

      flag = false;
    }

    if (this.state.isIDALogin === "false") {
      var specialization = this.state.specialization;
      if (specialization.length > 0 && flag === true) {
        var isPrimary = "0";
        var multiple = 0;
        for (var i = 0; i < specialization.length; i++) {
          if ($("#chkPrimarySpecialisation" + i).is(":checked") === true) {
            multiple = parseInt(multiple) + 1;
          }
        }
        for (var i = 0; i < specialization.length; i++) {
          if (
            $("#chkPrimarySpecialisation" + i).is(":checked") === true &&
            $("#chkSpecialisation" + i).is(":checked") === true
          ) {
            // let specializationId = $("#chkSpecialisation" + i).val();
            // let seoSpecialization = specialization
            //   .filter((obj) => obj.specialityId == specializationId)
            //   .map((object) => object.speciality);
            // this.setState({
            //   seoSlug:
            //     $("#txtFirstName").val() +
            //     "|" +
            //     $("#hospitalId").val() +
            //     "|" +
            //     seoSpecialization +
            //     "|" +
            //     $("#txtAddress").val() +
            //     $("#cmbCity").val() +
            //     "|" +
            //     $("#cmbState").val(),
            // });
            isPrimary = "1";
            flag = true;
            break;
          }
        }

        if (isPrimary === "0" && specialization.length > 0) {
          alert("Atleast one Primary Specialisation Required");
          flag = false;
        } else if (multiple > 1) {
          alert("Only one Primary Specialisation Required");
          flag = false;
          $("#chkPrimarySpecialisation" + i).focus();
        }
      }

      if (
        this.state.isIDALogin === "false" &&
        flag === true &&
        ($("#chkVideo").is(":checked") === true ||
          $("#chkAudio").is(":checked") === true)
      ) {
        var dayArray = ["sun", "mon", "tues", "wed", "thu", "fri", "sat"];
        var dayFullArray = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        for (var i = 0; i < dayArray.length; i++) {
          if (
            $("#" + dayArray[i] + "From").val() !== "0" &&
            $("#" + dayArray[i] + "To").val() === "0"
          ) {
            alert(dayFullArray[i] + " To Time Required");
            $("#" + dayArray[i] + "To").focus();
            flag = false;
            break;
          } else if (
            $("#" + dayArray[i] + "From").val() === "0" &&
            $("#" + dayArray[i] + "To").val() !== "0"
          ) {
            alert(dayFullArray[i] + " From Time Required");
            $("#" + dayArray[i] + "From").focus();
            flag = false;
            break;
          }
        }
        if (flag === true) {
          for (var i = 0; i < dayArray.length; i++) {
            if (
              $("#" + dayArray[i] + "From").val() !== "0" &&
              $("#" + dayArray[i] + "To").val() !== "0"
            ) {
              var from_time = $("#" + dayArray[i] + "From").val();
              var to_time = $("#" + dayArray[i] + "To").val();
              var from = Date.parse("01/01/2011 " + from_time);
              var to = Date.parse("01/01/2011 " + to_time);
              if (from > to) {
                alert(
                  "Incorrect time on " +
                  dayFullArray[i] +
                  ". To Time should be greater than From Time."
                );
                $("#" + dayArray[i] + "To").focus();
                flag = false;
                break;
              }
            }
          }
        }
      }
    }

    // console.log('1111111111111111111')
    if (flag === true) {
      // console.log('222222222222222')
      var dayArray = ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"];
      var dayFullArray = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ];
      for (var i = 0; i < dayArray.length; i++) {
        if (
          $("#sh1" + dayArray[i] + "From").val() !== "0" &&
          $("#sh1" + dayArray[i] + "To").val() === "0"
        ) {
          alert(dayFullArray[i] + " To Time required in Schedule 1");
          $("#sh1" + dayArray[i] + "To").focus();
          flag = false;
          break;
        } else if (
          $("#sh1" + dayArray[i] + "From").val() === "0" &&
          $("#sh1" + dayArray[i] + "To").val() !== "0"
        ) {
          alert(dayFullArray[i] + " From Time required in Schedule 1");
          $("#sh1" + dayArray[i] + "From").focus();
          flag = false;
          break;
        } else if (
          $("#sh2" + dayArray[i] + "From").val() !== "0" &&
          $("#sh2" + dayArray[i] + "To").val() === "0"
        ) {
          alert(dayFullArray[i] + " To Time required in Schedule 2");
          $("#sh2" + dayArray[i] + "To").focus();
          flag = false;
          break;
        } else if (
          $("#sh2" + dayArray[i] + "From").val() === "0" &&
          $("#sh2" + dayArray[i] + "To").val() !== "0"
        ) {
          alert(dayFullArray[i] + " From Time required in Schedule 2");
          $("#sh2" + dayArray[i] + "From").focus();
          flag = false;
          break;
        }
      }
      // console.log('333333333333...', flag)
      if (flag === true) {
        // console.log('44444444444444444...')
        for (var i = 0; i < dayArray.length; i++) {
          //console.log('5555...', $("#sh1" + dayArray[i] + "From").val())
          //console.log('6666...', $("#sh1" + dayArray[i] + "To").val())
          if (
            $("#sh1" + dayArray[i] + "From").val() !== "0" &&
            $("#sh1" + dayArray[i] + "To").val() !== "0"
          ) {
            // console.log('iffffffffffffffffff...')
            var from_time = $(
              "#sh1" + dayArray[i] + "From option:selected"
            ).text();
            var to_time = $("#sh1" + dayArray[i] + "To option:selected").text();
            var from = Date.parse("01/01/2011 " + from_time);
            var to = Date.parse("01/01/2011 " + to_time);
            if (from > to) {
              alert(
                "Incorrect time on " +
                dayFullArray[i] +
                ". To Time should be greater than From Time in Schedule 1."
              );
              $("#sh1" + dayArray[i] + "To").focus();
              flag = false;
              break;
            }
          }
        }
      }

      if (flag === true) {
        // console.log('44444444444444444...')
        for (var i = 0; i < dayArray.length; i++) {
          // console.log('5555...', $("#sh2" + dayArray[i] + "From").val())
          // console.log('6666...', $("#sh2" + dayArray[i] + "To").val())

          if (
            $("#sh2" + dayArray[i] + "From").val() !== "0" &&
            $("#sh2" + dayArray[i] + "To").val() !== "0"
          ) {
            // console.log('if22222222222222222...')
            var from_time = $(
              "#sh2" + dayArray[i] + "From option:selected"
            ).text();
            var to_time = $("#sh2" + dayArray[i] + "To option:selected").text();
            var from = Date.parse("01/01/2011 " + from_time);
            var to = Date.parse("01/01/2011 " + to_time);
            if (from > to) {
              alert(
                "Incorrect time on " +
                dayFullArray[i] +
                ". To Time should be greater than From Time in Schedule 2."
              );
              $("#sh2" + dayArray[i] + "To").focus();
              flag = false;
              break;
            }
          }
        }
      }
    }

    return flag;
  }
  saveIDA() {
    //  $("#btnRegister").hide()
    // if (this.state.isIDALogin === "false") {
    //   $("#btnProcessingNotIDA").show()
    //   $("#btnProcessing").hide()
    // }
    // else {
    //   $("#btnProcessingNotIDA").hide()
    //   $("#btnProcessing").show()
    // }

    $("#btnProcessingNotIDA").show();
    $("#btnProcessing").hide();
    var isValid = this.validation();

    if (isValid === true) {
      // $("#btnRegister").hide()
      this.saveData();
    } else {
      $("#btnProcessing").hide();
      $("#btnOtpRegister").show();
      // $("#btnRegister").show()
      $("#btnProcessingNotIDA").hide();
    }
  }
  uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
  fileUpload = async (file, mode) => {
    //alert("22")
    // alert(file.name);
    //console.log("imagggg....", file)
    // let reader = new FileReader();
    //let reader = new FileReader();
    // let s3BucketMainFolder1 =
    //   this.state.s3BucketMainFolder + "/" + this.state.s3BucketDoctorImages;
    let fileName = this.uuidv4() + file.name;
    // console.log('fileName.........' + fileName)
    // let fullFilePath = s3BucketMainFolder1 + "/" + fileName;
    fileNameUploadSignature = fileName;
    // console.log('fullFilePath.........' + fullFilePath)
    if (mode === "attachImg") {
      fileNameUploadSignature = fileName;
    } else {
      fileNameUploadProfile = fileName;
    }

    try {
      const s3Url = await uploadToS3(file, `${process.env.REACT_APP_S3BucketMainFolder}`, fileNameUploadSignature.replaceAll(/ /g, ""))
      if (s3Url) {
        console.log("Photo uploaded successfully");
        return s3Url
      }
    } catch (error) {
      console.error("S3 upload error:", error);
      throw error;
    }
    // console.log('fullFilePath.........' + fullFilePath)
    // s3.upload(
    //   {
    //     Key: fullFilePath,
    //     Body: file,
    //     ACL: "public-read",
    //   },
    //   (err, data) => {
    //     if (err) {
    //console.log('img error,...', err)
    // // alert(
    // //     "There was an error uploading your photo: ",
    // //     err.message
    // );
    //   return null;
    // } else {
    //alert("Image uploaded successfuly")
    //alert("fullFilePath"+fullFilePath)
    // return fullFilePath;
    // }
    // }
    // );
  }
  saveData() {

    let languageName = $("#cmbLanguage")
      .val()
      .toString()
      .replace(/[\[\]']/g, "");
    var languageArray = []
    // var language = $("#cmbLanguage").val();
    $('#cmbLanguage option:selected').each(function () {
      let languageName = $(this).text();
      let languageId = $(this).val();
      let languageObj = {
        "languageId": languageId,
        "languageName": languageName
      }
      languageArray.push(languageObj)
    })
    // console.log(language, "languageArraylanguageArray");
    //console.log("language...", language)
    // for (var i = 0; i < language.length; i++) {
    //   var l =  {
    //     "languageId": language[i],
    //     "languageName":languageName[i]
    //   }

    //console.log("language...", language)
    //  languageArray.push(l)
    // }
    // for (let i = 0; i < language.length; i++) {
    //   languageArray.push(language[i]);
    // }
    // var workTimeArray = []
    // var workTime = $("#cmbTimeFilter").val()
    // for (var i = 0; i < workTime.length; i++) {
    //   var n = {
    //     "workTimeId": workTime[i],
    //     "workTimeName": ""
    //   }
    //   console.log("workTime...", workTime)
    //   workTimeArray.push(n)
    // }

    var specilisationArray = [];
    var specialization = this.state.specialization;
    for (var i = 0; i < specialization.length; i++) {
      if ($("#chkSpecialisation" + i).is(":checked") === true) {
        var s = {
          specializationId: $("#chkSpecialisation" + i).val(),
          isPrimary:
            $("#chkPrimarySpecialisation" + i).is(":checked") === true,
        };
        specilisationArray.push(s);
      }
    }
    if (specilisationArray.length === 0) {
      alert("Atleast one specialization required ");
      return false;
    }
    //console.log('specilisationArray........', specilisationArray)

    var tempDate = new Date();
    var formattedDate = [
      tempDate.getDate(),
      tempDate.getMonth() + 1,
      tempDate.getFullYear(),
    ].join("/");
    var formattedDateExpiry = [
      tempDate.getDate(),
      tempDate.getMonth() + 1,
      tempDate.getFullYear() + 1,
    ].join("/");

    var educationArray = [];
    for (var i = 0; i < this.state.educationDetailsArray.length; i++) {
      if (this.state.educationDetailsArray[i].Id !== "") {
        var b = {
          educationId: this.state.educationDetailsArray[i].Id,
          educationYear: this.state.educationDetailsArray[i].year,
          educationCollege: this.state.educationDetailsArray[i].college,
        };
        educationArray.push(b);
      }
      // else if (educationArray.length === 0){
      //   alert("Education Details Required00000")
      // }
    }

    if (this.state.fileSignature !== null) {
      this.fileUpload(this.state.fileSignature, "attachImg");
    }
    var image = "";
    if (this.state.fileSignature !== null) {
      image = fileNameUploadSignature;
    }

    if (this.state.fileAppProfile !== null) {
      this.fileUpload(this.state.fileAppProfile, "attachProfilePic");
    }
    var imageProfile = "";
    if (this.state.fileAppProfile !== null) {
      imageProfile = fileNameUploadProfile;
    }
    let pathname = new URL(window.location.href).pathname;
    let idaId = pathname.split("/")[4];
    $("#btnOtpRegister").attr("hidden", true);
    $("#btnProcessing").show();
    const apiJson = {
      method: "POST",
      // body: JSON.stringify({
      //   functionName: "saveDoctor",
      //   activeDate: formattedDate,
      //   address: $("#txtAddress").val(),
      //   advertisementId: "999",
      //   city: $("#cmbCity option:selected").text(),
      //   cityId: this.state.cityId ? this.state.cityId : '',
      //   country: "India",
      //   createdBy: 1,
      //   createdOn: "",
      //   dateOfBirth: $("#txtDateOfBirth").val(),
      //   deactiveDate: formattedDateExpiry,
      //   doctorFirstName: $("#txtFirstName").val(),
      //   gender: $("input:radio[name=rdoGenderType]:checked").val(),
      //   isAcceptOnline: false,
      //   isAssistedEnabled: false,
      //   isBackgroundVerified: false,

      //   isMedicalTourism: false,
      //   isVaasEnabled: false,
      //   isVideoAccountCreated: false,
      //   isVideoEnabled:$("#chkVideo").is(":checked"),
      //   keyWords: "",
      //   doctorLastName: "",
      //   medicalRegNumber: $("#txtRegisterNo").val(),
      //   medicineBranchId:
      //     this.state.isIDALogin === "false"
      //       ? this.state.selectedMedicineBranchId
      //       : "4",
      //   mobileNumber: $("#txtMobile").val(),
      //   modifiedBy: 1,
      //   modifiedOn: "29/08/2021",
      //   phone: $("#txtPhone").val(),
      //   practiceStartYear: $("#txtStartYear").val(),
      //   professionalStatement: $("#txtAreaStatement").val(),
      //   ratePerMinute:$("#chkVideo").is(":checked")?$("#txtVideo").val():0,
      //   ratePerSession:$("#chkVideo").is(":checked")?$("#txtVideo").val():0,
      //   rating: 0,
      //   regDate: formattedDate,
      //   languageIds: language,
      //   description: "Kerala govt",
      //   registrationstate: $("#txtRegistrationState").val(),
      //   routeId: "22",
      //   searchKeywords: "",
      //   seoTitle: "",
      //   slug: "",
      //   //   seoTitle:
      //   //   $("#doctorTitle").val() +
      //   //   "|" +
      //   //   $("#txtFirstName").val() +
      //   //   "|" +
      //   //   $("#cmbCity").val() +
      //   //   "|" +
      //   //   $("#cmbState").val(),
      //   // slug: this.state.seoSlug,
      //   sortOrder: "999",
      //   state: $("#cmbState option:selected").text(),
      //   stateId: this.state.stateId ? this.state.stateId : '',
      //   subCity: $("#cmbSubCity").val(),
      //   timeFilter: "1,2",
      //   doctorTitle: $("#doctorTitle").val(),
      //   doctorSignature: image,
      //   doctorEmail: $("#txtEmail").val(),
      //   associationId: this.state.associationId,
      //   doctorImage: imageProfile,
      //   zip: $("#txtPinCode").val(),
      //   isEhrEnabled: true,
      //   isAcceptOnline: true,
      //   pmPaymentDone: true,
      //   doctorPhoto: imageProfile,
      //   isExcludeFromPh: false,
      //   hasInitialEvaluation: false,
      //   isAgreeTermsAndCondition: false,
      //   fromNetwork: false,
      //   networkServiceRequest: null,
      //   lastFeedViewedTime: "09/09/2022",
      //   lastalertViewedTime: "09/09/2022",
      //   fromDoctorRegistration: false,
      //   password:
      //     this.state.isIDALogin === "false"
      //       ? $("#txtPassword").val()
      //       : "password",
      //   username: $("#txtEmail").val(),
      //   version: 0,
      //   aqMakePublic: false,
      //   aqNumFollowUp: 0,
      //   aqPrice: $("#chkInperson").is(":checked")?$("#txtInperson").val():0,
      //   askQuestion: false,
      //   freeAqAvailCount: 0,
      //   isFreeAq: false,
      //   isPhoneConsultAvail: false,
      //   officeDetails: $("#txtAreaStatement").val(),
      //   phoneConsultPrice: 0,
      //   serviceOffered: $("#txtAreaService").val(),
      //   college: "zxxzx",
      //   year: "2008",
      //   doctorId:
      //     typeof this.props.match.params.doctorId === "undefined"
      //       ? ""
      //       : this.props.match.params.doctorId,
      //   consultationPrice: 0,
      //   ispmmodeWrite: false,
      //   paidFromDate: "",
      //   paidToDate: "",
      //   mobileAppOnly: 0,
      //   pmCalenderMode: "daily",
      //   hasInPersonAppointment: true,
      //   hasSaveHospital: true,
      //   // "hospitalId": this.props.match.params.hospitalId,
      //   hospitalId: $("#hospitalId").val(),
      //   hospitalEmailId: $("#txtClinicEmailId").val(),
      //   hospitalAddress: $("#txtHospitalAddress").val(),
      //   hospitalName: $("#txtHospitalName").val(),
      //   idaId: idaId,
      //   // {this.state.Validationfordoclogin !== "undefined" &&    }
      //   hospitalDoctorDetails: [
      //     {
      //       hospitalId: $("#hospitalId").val(),
      //       hasOwner: true,
      //     },
      //   ],
      //   doctorOfficeHours: [
      //     {
      //       hospitalId: $("#hospitalId").val(),
      //       schedule: [
      //         {
      //           sundayTo: $("#sh1SunTo").val(),
      //           sundayFrom: $("#sh1SunFrom").val(),
      //           mondayTo: $("#sh1MonTo").val(),
      //           mondayFrom: $("#sh1MonFrom").val(),
      //           tuesdayTo: $("#sh1TueTo").val(),
      //           tuesdayFrom: $("#sh1TueFrom").val(),
      //           wednesdayTo: $("#sh1WedTo").val(),
      //           wednesdayFrom: $("#sh1WedFrom").val(),
      //           thursdayTo: $("#sh1ThurTo").val(),
      //           thursdayFrom: $("#sh1ThurFrom").val(),
      //           fridayTo: $("#sh1FriTo").val(),
      //           fridayFrom: $("#sh1FriFrom").val(),
      //           saturdayTo: $("#sh1SatTo").val(),
      //           saturdayFrom: $("#sh1SatFrom").val(),
      //         },
      //         {
      //           sundayTo: $("#sh2SunTo").val(),
      //           sundayFrom: $("#sh2SunFrom").val(),
      //           mondayTo: $("#sh2MonTo").val(),
      //           mondayFrom: $("#sh2MonFrom").val(),
      //           tuesdayTo: $("#sh2TueTo").val(),
      //           tuesdayFrom: $("#sh2TueFrom").val(),
      //           wednesdayTo: $("#sh2WedTo").val(),
      //           wednesdayFrom: $("#sh2WedFrom").val(),
      //           thursdayTo: $("#sh2ThurTo").val(),
      //           thursdayFrom: $("#sh2ThurFrom").val(),
      //           fridayTo: $("#sh2FriTo").val(),
      //           fridayFrom: $("#sh2FriFrom").val(),
      //           saturdayTo: $("#sh2SatTo").val(),
      //           saturdayFrom: $("#sh2SatFrom").val(),
      //         },
      //       ],
      //     },
      //   ],
      //   isAccountLocked: false,
      //   isVerified: true,
      //   status: true,
      //   specializationIds: specilisationArray,
      //   // "specializationIds": [
      //   //   {
      //   //     "specializationIds": "376",
      //   //     "isPrimarySpecialisation": false
      //   //   },
      //   //   {
      //   //     "specializationIds": "416",
      //   //     "isPrimarySpecialisation": true
      //   //   }
      //   // ],
      //   educationIds: educationArray,
      //   achievementIds: [
      //     // {
      //     //   "achievementId": "1",
      //     //   "description": "Awards Test"
      //     // },
      //     // {
      //     //   "achievementId": "2",
      //     //   "description": "certificate Achieved"
      //     // }
      //   ],
      //   procedures: [
      //     // {
      //     //   "buyPrice": "5",
      //     //   "maxPrice": "10",
      //     //   "minPrice": "0",
      //     //   "isActive": false,
      //     //   "mtBuyPrice": "8",
      //     //   "mtMaxPrice": "8",
      //     //   "mtMinPrice": "3",
      //     //   "procedureId": "17"
      //     // },
      //     // {
      //     //   "buyPrice": "2",
      //     //   "maxPrice": "0",
      //     //   "minPrice": "5",
      //     //   "isActive": true,
      //     //   "mtBuyPrice": "10",
      //     //   "mtMaxPrice": "8",
      //     //   "mtMinPrice": "3",
      //     //   "procedureId": "2"
      //     // }
      //   ],
      // }),
      body: JSON.stringify({
        isEhrEnabled: true,
        isAcceptOnline: true,
        pmPaymentDone: true,
        isExcludeFromPh: false,
        hasInitialEvaluation: false,
        isAgreeTermsAndCondition: false,
        fromNetwork: false,
        fromDoctorRegistration: false,
        aqMakePublic: false,
        aqNumFollowUp: 0,
        aqPrice: $("#chkInperson").is(":checked") ? $("#txtInperson").val() : 0,
        askQuestion: false,
        freeAqAvailCount: 0,
        isFreeAq: false,
        isPhoneConsultAvail: false,
        phoneConsultPrice: 0,
        serviceOffered: $("#txtAreaService").val(),
        hospitalServiceOffered: $("#hospitalserviceOffered").val(),
        hospitalProfessionalStatement: $("#hospitalProfessionalStatement").val(),
        ispmmodeWrite: false,
        mobileAppOnly: 0,
        pmCalenderMode: "daily",
        hasInPersonAppointment: true,
        hasSaveHospital: true,
        isAccountLocked: false,
        isVerified: true,
        status: true,
        hospitalEmailId: $("#txtClinicEmailId").val(),
        functionName: "doctorRegistration",
        siteId: $("#associationSelect").val(),
        idaId: (typeof this.state.idaId === "undefined" ? "0" : this.state.idaId),
        firstName: $("#txtFirstName").val(),
        lastName: "",
        title: $("#doctorTitle").val(),
        gender: $('input:radio[name=rdoGenderType]:checked').val(),
        displayName: $("#txtFirstName").val(),
        dateOfBirth: $("#txtDateOfBirth").val(),
        password: this.state.isIDALogin === "false" ? $("#txtPassword").val() : "password",
        imageName: image,
        language: languageArray,
        workTime: [{ "workTimeId": "2", "workTimeName": "" }, { "workTimeId": "1", "workTimeName": "" }],
        hospitalId: $("#hospitalId").val(),
        hospitalName: $("#txtHospitalName").val(),
        hospitalAddress: $("#txtHospitalAddress").val(),
        emailId: $("#txtEmail").val(),
        doctorAddress: $("#txtAddress").val(),
        city: $("#cmbCity option:selected").text(),
        subCity: ($("#cmbSubCity").val() === "0" ? "0" : $("#cmbSubCity option:selected").text()),
        state: $("#cmbState option:selected").text(),
        country: "India",
        pincode: $("#txtPinCode").val(),
        phone: $("#txtPhone").val(),
        mobile: $("#txtMobile").val(),
        medicineBranch: this.state.isIDALogin === "false"
          ? this.state.selectedMedicineBranchId
          : "4",
        //  (this.state.isIDALogin === "false" ? this.state.medicineBranchId : "4"),
        bookAppointment: (this.state.isIDALogin === "false" ? ($("#chkInperson").is(":checked") === true ? '1' : '0') : '1'),
        bookAppointmentPrice: ($("#txtInperson").val() !== "" ? $("#txtInperson").val() : "0"),
        textChat: $("#chkText").is(":checked") === true ? '1' : '0',
        textChatCost: ($("#txtText").val() !== "" ? $("#txtText").val() : "0"),
        videoChat: $("#chkVideo").is(":checked") === true ? '1' : '0',
        videoPrice: ($("#txtVideo").val() !== "" ? $("#txtVideo").val() : "0"),
        audioChat: $("#chkAudio").is(":checked") === true ? '1' : '0',
        audioPrice: ($("#txtAudio").val() !== "" ? $("#txtAudio").val() : "0"),
        specialization: specilisationArray,
        professionalStatement: $("#txtAreaStatement").val(),
        educationDetails: educationArray,
        medicalRegNumber: $("#txtRegisterNo").val(),
        registrationState: $("#txtRegistrationState").val(),
        practiceStartYear: $("#txtStartYear").val(),
        doctorImage: imageProfile,
        hospitalHours: [
          {
            "sunFromSchedule1": $("#sh1SunFrom").val(),
            "sunToSchedule1": $("#sh1SunTo").val(),
            "sunFromSchedule2": $("#sh2SunFrom").val(),
            "sunToSchedule2": $("#sh2SunTo").val()
          },
          {
            "monFromSchedule1": $("#sh1MonFrom").val(),
            "monToSchedule1": $("#sh1MonTo").val(),
            "monFromSchedule2": $("#sh2MonFrom").val(),
            "monToSchedule2": $("#sh2MonTo").val()
          },
          {
            "tueFromSchedule1": $("#sh1TueFrom").val(),
            "tueToSchedule1": $("#sh1TueTo").val(),
            "tueFromSchedule2": $("#sh2TueFrom").val(),
            "tueToSchedule2": $("#sh2TueTo").val()
          },
          {
            "wedFromSchedule1": $("#sh1WedFrom").val(),
            "wedToSchedule1": $("#sh1WedTo").val(),
            "wedFromSchedule2": $("#sh2WedFrom").val(),
            "wedToSchedule2": $("#sh2WedTo").val()
          },
          {
            "thuFromSchedule1": $("#sh1ThurFrom").val(),
            "thuToSchedule1": $("#sh1ThurTo").val(),
            "thuFromSchedule2": $("#sh2ThurFrom").val(),
            "thuToSchedule2": $("#sh2ThurTo").val()
          },
          {
            "friFromSchedule1": $("#sh1FriFrom").val(),
            "friToSchedule1": $("#sh1FriTo").val(),
            "friFromSchedule2": $("#sh2FriFrom").val(),
            "friToSchedule2": $("#sh2FriTo").val()
          },
          {
            "satFromSchedule1": $("#sh1SatFrom").val(),
            "satToSchedule1": $("#sh1SatTo").val(),
            "satFromSchedule2": $("#sh2SatFrom").val(),
            "satToSchedule2": $("#sh2SatTo").val()
          }
        ],
        "videoHours": [
          {
            "day": "0",
            "from": $("#monFrom").val(),
            "to": $("#monTo").val()
          },
          {
            "day": "1",
            "from": $("#tuesFrom").val(),
            "to": $("#tuesTo").val()
          },
          {
            "day": "2",
            "from": $("#wedFrom").val(),
            "to": $("#wedTo").val()
          },
          {
            "day": "3",
            "from": $("#thuFrom").val(),
            "to": $("#thuTo").val()
          },
          {
            "day": "4",
            "from": $("#friFrom").val(),
            "to": $("#friTo").val()
          },
          {
            "day": "5",
            "from": $("#satFrom").val(),
            "to": $("#satTo").val()
          },
          {
            "day": "6",
            "from": $("#sunFrom").val(),
            "to": $("#sunTo").val()
          }
        ]
      })


      //?/old Code without flag edit
      // body: JSON.stringify({
      //   functionName: "doctorRegistration",
      //   siteId: this.state.pmSite,
      //   idaId: (typeof this.state.idaId === "undefined" ? "0" : this.state.idaId),
      //   firstName: $("#txtFirstName").val(),
      //   lastName: "",
      //   title: $("#doctorTitle").val(),
      //   gender: $('input:radio[name=rdoGenderType]:checked').val(),
      //   displayName: $("#txtFirstName").val(),
      //   dateOfBirth: $("#txtDateOfBirth").val(),
      //   password: this.state.isIDALogin === "false" ? $("#txtPassword").val() : "password",
      //   imageName: image,
      //   language: languageArray,
      //   workTime: [{ "workTimeId": "2", "workTimeName": "" }, { "workTimeId": "1", "workTimeName": "" }],
      //   hospitalId: $("#hospitalId").val(),
      //   hospitalName: $("#txtHospitalName").val(),
      //   hospitalAddress: $("#txtHospitalAddress").val(),
      //   emailId: $("#txtEmail").val(),
      //   doctorAddress: $("#txtAddress").val(),
      //   city: $("#cmbCity option:selected").text(),
      //   subCity: ($("#cmbSubCity").val() === "0" ? "0" : $("#cmbSubCity option:selected").text()),
      //   state: $("#cmbState option:selected").text(),
      //   country: "India",
      //   pincode: $("#txtPinCode").val(),
      //   phone: $("#txtPhone").val(),
      //   mobile: $("#txtMobile").val(),
      //   medicineBranch: (this.state.isIDALogin === "false" ? this.state.medicineBranchId : "4"),
      //   bookAppointment: (this.state.isIDALogin === "false" ? ($("#chkInperson").is(":checked") === true ? '1' : '0') : '1'),
      //   bookAppointmentPrice: ($("#txtInperson").val() !== "" ? $("#txtInperson").val() : "0"),
      //   textChat: $("#chkText").is(":checked") === true ? '1' : '0',
      //   textChatCost: ($("#txtText").val() !== "" ? $("#txtText").val() : "0"),
      //   videoChat: $("#chkVideo").is(":checked") === true ? '1' : '0',
      //   videoPrice: ($("#txtVideo").val() !== "" ? $("#txtVideo").val() : "0"),
      //   audioChat: $("#chkAudio").is(":checked") === true ? '1' : '0',
      //   audioPrice: ($("#txtAudio").val() !== "" ? $("#txtAudio").val() : "0"),
      //   specialization: specilisationArray,
      //   professionalStatement: $("#txtAreaStatement").val(),
      //  // educationDetails: educationArray,
      //   medicalRegNumber: $("#txtRegisterNo").val(),
      //   registrationState: $("#txtRegistrationState").val(),
      //   practiceStartYear: $("#txtStartYear").val(),
      //   doctorImage: imageProfile,
      //   hospitalHours: [
      //     {
      //       "sunFromSchedule1": $("#sh1SunFrom").val(),
      //       "sunToSchedule1": $("#sh1SunTo").val(),
      //       "sunFromSchedule2": $("#sh2SunFrom").val(),
      //       "sunToSchedule2": $("#sh2SunTo").val()
      //     },
      //     {
      //       "monFromSchedule1": $("#sh1MonFrom").val(),
      //       "monToSchedule1": $("#sh1MonTo").val(),
      //       "monFromSchedule2": $("#sh2MonFrom").val(),
      //       "monToSchedule2": $("#sh2MonTo").val()
      //     },
      //     {
      //       "tueFromSchedule1": $("#sh1TueFrom").val(),
      //       "tueToSchedule1": $("#sh1TueTo").val(),
      //       "tueFromSchedule2": $("#sh2TueFrom").val(),
      //       "tueToSchedule2": $("#sh2TueTo").val()
      //     },
      //     {
      //       "wedFromSchedule1": $("#sh1WedFrom").val(),
      //       "wedToSchedule1": $("#sh1WedTo").val(),
      //       "wedFromSchedule2": $("#sh2WedFrom").val(),
      //       "wedToSchedule2": $("#sh2WedTo").val()
      //     },
      //     {
      //       "thuFromSchedule1": $("#sh1ThurFrom").val(),
      //       "thuToSchedule1": $("#sh1ThurTo").val(),
      //       "thuFromSchedule2": $("#sh2ThurFrom").val(),
      //       "thuToSchedule2": $("#sh2ThurTo").val()
      //     },
      //     {
      //       "friFromSchedule1": $("#sh1FriFrom").val(),
      //       "friToSchedule1": $("#sh1FriTo").val(),
      //       "friFromSchedule2": $("#sh2FriFrom").val(),
      //       "friToSchedule2": $("#sh2FriTo").val()
      //     },
      //     {
      //       "satFromSchedule1": $("#sh1SatFrom").val(),
      //       "satToSchedule1": $("#sh1SatTo").val(),
      //       "satFromSchedule2": $("#sh2SatFrom").val(),
      //       "satToSchedule2": $("#sh2SatTo").val()
      //     }
      //   ],
      //   "videoHours": [
      //     {
      //       "day": "0",
      //       "from": $("#monFrom").val(),
      //       "to": $("#monTo").val()
      //     },
      //     {
      //       "day": "1",
      //       "from": $("#tuesFrom").val(),
      //       "to": $("#tuesTo").val()
      //     },
      //     {
      //       "day": "2",
      //       "from": $("#wedFrom").val(),
      //       "to": $("#wedTo").val()
      //     },
      //     {
      //       "day": "3",
      //       "from": $("#thuFrom").val(),
      //       "to": $("#thuTo").val()
      //     },
      //     {
      //       "day": "4",
      //       "from": $("#friFrom").val(),
      //       "to": $("#friTo").val()
      //     },
      //     {
      //       "day": "5",
      //       "from": $("#satFrom").val(),
      //       "to": $("#satTo").val()
      //     },
      //     {
      //       "day": "6",
      //       "from": $("#sunFrom").val(),
      //       "to": $("#sunTo").val()
      //     }
      //   ]
      // })
    };
    //  return
    apiCalling(apiJson).then((data) => {


      if (data.success === "1") {
        $("#image").attr("src", "");
        $("#pofileImage").attr("src", "");
        alert("Doctor registration details saved successfully")
        window.location.reload();
        $("#btnOtpRegister").hide();
        $("#btnProcessing").hide();
        $("#txtSuccess").show();
        $("#btnProcessingNotIDA").hide();
      } else {
        alert(data.errorMessage);
        $("#btnProcessing").hide();
        // $("#btnRegister").show()
        $("#btnOtpRegister").hide();
        // setTimeout(() => {
        //   $("#txtFailed").show();
        // }, 2000);

        $("#btnOtpRegister").show();
        $("#txtFailed").hide();
        $("#btnProcessingNotIDA").hide();
      }
    });
  }
  render() {
    let timeList =
      this.state.timeDropDown.length > 0 &&
      this.state.timeDropDown.map((data, i) => {
        return (
          <option key={i} value={data.val}>
            {data.text}
          </option>
        );
      }, this);

    return (
      <div className="dashboard-doctorOuter">
        <Suspense fallback={<div></div>}>
          <section>
            <Header />

            <div className="container mt-5">
              <div>
                <ul className="breadcrum-adminMangmnt">
                  <li>
                    <a href="/admin/dashboardAdmin">Dashboard</a>
                  </li>
                  <li>
                    <a href="/adminManagement/doctorManagementList/doctorManagementDashboard">
                      Doctor Management
                    </a>
                  </li>
                  <li>
                    <a href="" className="current">
                      Doctor Registration Form
                    </a>
                  </li>
                </ul>
              </div>

              <div className="">
                {this.state.doctorRegistrationAvailableCount > 0 ? (
                  <div class="container">
                    <div class="main-wrapper1 main-wrapperReg mt-0 pt-0">
                      <div class="row mt-3">
                        <div class="col-lg-12 drRegistrationForm">
                          <div class="form-head mt-4 text-center">
                            Doctor Registration
                          </div>
                          <div class="form-section row mb-4">
                            {this.state.isData === 0 ? (
                              <div className="col-md-12 p-0 loader_imgDiv">
                                <img src={loaderImg}></img>
                              </div>
                            ) : (
                              ""
                            )}
                            <div class="row row-margin col-lg-6 col-sm-12">
                              <label class="col-lg-6 col-sm-4 label-text label-text-cln doctrlabelTop">
                                Select Association{" "}
                                <span class="star-red">*</span>
                              </label>
                              <div class="col-lg-6 col-sm-8">
                                {/* <div class="col-sm-6"> */}
                                <select
                                  class="input-design"
                                  id="associationSelect"
                                  ref={this.inputRefs.associationRef}
                                  name="associationSelect"
                                  tabIndex="1"
                                  onChange={
                                    (e) => {
                                      let tempMedicineBranchData =
                                        this.state.medicineBranchData;
                                      if (e.target.value == 1) {
                                        let obj1 =
                                          this.state.medicineBranchData.filter(
                                            (o) => o.medicineBranchId === 4
                                          );
                                        this.setState(
                                          {
                                            associationId: e.target.value,
                                            isIDALogin: "true",
                                            specialization:
                                              obj1[0].specializationData,
                                            medicineBranchData: [...obj1],
                                          },
                                          () => {
                                            console.log(
                                              "medicineBranchData",
                                              this.state.medicineBranchData
                                            );
                                          }
                                        );
                                      } else
                                        this.setState({
                                          associationId: e.target.value,
                                          isIDALogin: "false",
                                          medicineBranchData: [
                                            ...tempMedicineBranchData,
                                          ],
                                        });
                                    }
                                    // let obj1 = data.result[0].medicineBranchData.filter(
                                    //   (o) => o.medicineBranchId === 4
                                    // );
                                    // console.log("")
                                    // this.setState({
                                    //   specialization: obj1[0].specializationData,
                                    // });
                                  }
                                >
                                  {" "}
                                  <option value="0">Select</option>
                                  {this.state.associationData.map((data) => (
                                    <option value={data.associationId}>
                                      {data.associationName}
                                      {` (Site Id- ${data.associationId})`}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div class="row row-margin col-lg-6 col-sm-12">
                              <label class="col-lg-6 col-sm-4 label-text label-text-cln doctrlabelTop">
                                Full Name <span class="star-red">*</span>
                              </label>
                              <div class="col-lg-6 col-sm-8">
                                {/* <div class="col-sm-6"> */}
                                <select
                                  class="input-design selecttitlewidth"
                                  ref={this.inputRefs.doctorTitleRef}
                                  id="doctorTitle"
                                  name="doctorTitle"
                                  tabIndex="1"
                                >
                                  <option value="0">Select</option>
                                  <option selected value="Dr.">
                                    Dr
                                  </option>
                                  <option value="Mr.">Mr</option>
                                  <option value="Mrs.">Mrs</option>
                                  <option value="Ms.">Ms</option>
                                </select>

                                {/* </div> */}

                                <input
                                  type="text"
                                  class="input-design inputdesignSpace ml-1"
                                  autoComplete="off"
                                  id="txtFirstName"
                                  tabIndex="2"
                                  name="txtFirstName"
                                  placeholder="Full Name"
                                />
                              </div>
                            </div>

                            {/* <div class="row row-margin col-sm-6">
                        <label class="col-sm-6 label-text label-text-cln doctrlabelTop">Last Name <span class="star-red">*</span> </label>
                        <div class="col-sm-6">
                          <input type="text" class="input-design" id="txtLastName" name="txtLastName" tabIndex="2" placeholder="Last Name" onKeyUp={() => this.displayName()} />
                        </div>
                      </div> */}

                            {/* <div class="row row-margin col-sm-6">
                        <label class="col-sm-4 label-text label-text-cln">Title <span class="star-red">*</span></label>
                        <div class="col-sm-6">
                          <select class="input-design" id="doctorTitle" name="doctorTitle">
                            <option value="0">Select Title</option>
                            {this.state.titleData.map((titleObj, i) => {
                              return (
                                <option value={titleObj.titleValue}>{titleObj.titleName}</option>
                              )
                            })}
                          </select>
    
                        </div>
                      </div> */}

                            {/* <div class="row row-margin col-sm-6">
                        <label class="col-sm-6 label-text label-text-cln doctrlabelTop">Display Name</label>
                        <div class="col-sm-6">
                          <input type="text" id="txtDisplayName" name="txtDisplayName" class="input-design" tabIndex="3" placeholder="Display Name" />
                        </div>
                      </div> */}

                            <div class="row row-margin col-lg-6 col-sm-12">
                              <label class="col-lg-6 col-sm-4 label-text label-text-cln">
                                Gender <span class="star-red">*</span>
                              </label>
                              <div class="col-lg-6 col-sm-8">
                                <div class="form-check form-check-inline">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    id="inlineRadio1"
                                    name="rdoGenderType"
                                    tabIndex="4"
                                    value="Male"
                                  />
                                  <label class="form-check-label">Male</label>
                                </div>
                                <div class="form-check form-check-inline">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    id="inlineRadio2"
                                    name="rdoGenderType"
                                    tabIndex="5"
                                    value="Female"
                                  />
                                  <label class="form-check-label">Female</label>
                                </div>
                              </div>
                            </div>
                            {this.state.isIDALogin === "false" && (
                              <div class="row row-margin col-lg-6 col-sm-12">
                                <label class="col-lg-6 col-sm-4 label-text label-text-cln doctrlabelTop">
                                  Signature (jpeg,png)
                                </label>
                                <div class="col-lg-6 col-sm-8">
                                  <button
                                    type="button"
                                    class="fileUpload-medical mr-2"
                                  >
                                    <div className="input-file-container">
                                      <input
                                        className="input-file"
                                        id="attachImg"
                                        accept="image/*"
                                        name="attachImg"
                                        type="file"
                                        onChange={(e) =>
                                          this.handleImageUpload(e, "attachImg")
                                        }
                                        tabindex="6"
                                      />
                                      <label
                                        for="attachImg"
                                        className="input-file-trigger"
                                      >
                                        Upload
                                      </label>
                                    </div>
                                  </button>
                                  <div id="imgDiv" style={{ display: "none" }}>
                                    {" "}
                                    <img
                                      height="30"
                                      width="35"
                                      id="image"
                                    ></img>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div class="row row-margin col-lg-6 col-sm-12">
                              <label class="col-lg-6 col-sm-4 label-text label-text-cln doctrlabelTop doctrlabelTopZero">
                                Doctor Profile Picture (jpeg,png)
                              </label>
                              <div class="col-lg-6 col-sm-8">
                                <button
                                  type="button"
                                  class="fileUpload-medical mr-2"
                                >
                                  <div className="input-file-container">
                                    <input
                                      className="input-file"
                                      id="attachProfilePic"
                                      accept="image/*"
                                      name="attachProfilePic"
                                      type="file"
                                      tabindex="7"
                                      onChange={(e) =>
                                        this.handleImageUpload(
                                          e,
                                          "attachProfilePic"
                                        )
                                      }
                                    />
                                    <label
                                      for="attachProfilePic"
                                      className="input-file-trigger"
                                    >
                                      Upload
                                    </label>
                                  </div>
                                </button>
                                <div
                                  id="imgProfileDiv"
                                  style={{ display: "none" }}
                                >
                                  {" "}
                                  <img
                                    height="30"
                                    width="35"
                                    id="pofileImage"
                                  ></img>
                                </div>
                              </div>
                            </div>
                            <div class="row row-margin col-lg-6 col-sm-12">
                              <label class="col-lg-6 col-sm-4 label-text label-text-cln doctrlabelTop">
                                Date of Birth{" "}
                              </label>
                              <div class="col-lg-6 col-sm-8">
                                <DatePicker
                                  placeholderText="Date of Birth"
                                  id="txtDateOfBirth"
                                  name="txtDateOfBirth"
                                  maxDate={new Date()}
                                  showMonthDropdown
                                  showYearDropdown
                                  scrollableYearDropdown
                                  yearDropdownItemNumber={100}
                                  dropdownMode="scroll"
                                  peekNextMonth
                                  disabledKeyboardNavigation
                                  selected={this.state.dateOfBirth}
                                  onChange={(date) =>
                                    this.handleChangeDate(date)
                                  }
                                  // onChange={this.handleChangeDate}
                                  dateFormat="dd/MM/yyyy"
                                  calendarClassName="react-date-picker"
                                  autoComplete="off"
                                  tabIndex="8"
                                  onKeyDown={() => this.disableDateField()}
                                />
                              </div>
                            </div>
                            <div class="row row-margin col-lg-6 col-sm-12">
                              <label class="col-lg-6 col-sm-4 label-text label-text-cln doctrlabelTop">
                                Registration State{" "}
                              </label>
                              <div class="col-lg-6 col-sm-8">
                                <input
                                  type="text"
                                  id="txtRegistrationState"
                                  autoComplete="off"
                                  name="txtRegistrationState"
                                  class="input-design"
                                  tabIndex="9"
                                  placeholder="Registration State"
                                />
                              </div>
                            </div>
                            <div class="row row-margin col-lg-6 col-sm-12">
                              <label class="col-lg-6 col-sm-4 label-text label-text-cln doctrlabelTop">
                                {" "}
                                Practice Start Year{" "}
                              </label>
                              <div class="col-lg-6 col-sm-8">
                                <input
                                  type="number"
                                  id="txtStartYear"
                                  name="txtStartYear"
                                  autoComplete="off"
                                  class="input-design"
                                  placeholder="Year"
                                  tabIndex="10"
                                />
                              </div>
                            </div>
                            <div class="row row-margin col-lg-6 col-sm-12">
                              <label class="col-lg-6 col-sm-4 label-text label-text-cln doctrlabelTop doctrlabelTopZero">
                                {this.state.isIDALogin === "false"
                                  ? "Medical Registration No. (For Non-Medical Drs put as NA)"
                                  : "Dental Council Registration No."}
                              </label>
                              <div class="col-lg-6 col-sm-8">
                                <input
                                  type="text"
                                  id="txtRegisterNo"
                                  name="txtRegisterNo"
                                  autoComplete="off"
                                  class="input-design"
                                  placeholder="Registration No."
                                  tabIndex="11"
                                />
                              </div>
                            </div>
                            {this.state.isIDALogin === "false" ? (
                              <div class="row row-margin col-lg-6 col-sm-12">
                                <label class="col-lg-6 col-sm-4 label-text label-text-cln doctrlabelTop">
                                  Password <span class="star-red">*</span>
                                </label>
                                <div class="col-lg-6 col-sm-8">
                                  <input
                                    type="password"
                                    id="txtPassword"
                                    name="txtPassword"
                                    autoComplete="off"
                                    class="input-design"
                                    placeholder="Password"
                                    tabIndex="12"
                                  />
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {/* {this.state.isIDALogin === "false" ? (
                              <div class="row row-margin col-lg-6 col-sm-12">
                                <label class="col-lg-6 col-sm-4 label-text label-text-cln doctrlabelTop">
                                  Confirm Password{" "}
                                  <span class="star-red">*</span>
                                </label>
                                <div class="col-lg-6 col-sm-8">
                                  <input
                                    type="password"
                                    id="txtConformPassword"
                                    autoComplete="off"
                                    name="txtConformPassword"
                                    class="input-design"
                                    tabIndex="13"
                                    placeholder="Confirm Password"
                                  />
                                </div>
                              </div>
                            ) : (
                              ""
                            )} */}
                            <div class="row row-margin col-lg-6 col-sm-12">
                              <label class="col-lg-6 col-sm-4 label-text label-text-cln doctrlabelTop">
                                Language <span class="star-red">*</span>
                              </label>
                              <div class="col-lg-6 col-sm-8">
                                <select
                                  multiple
                                  class="input-design"
                                  id="cmbLanguage"
                                  name="cmbLanguage"
                                  tabIndex="14"
                                >
                                  {this.state.languageData.map((LangObj, i) => {
                                    $('#cmbLanguage option[value="1"]').prop(
                                      "selected",
                                      true
                                    );
                                    return (
                                      <option
                                        id={"languageData" + i}
                                        key={LangObj.languageId}
                                        value={LangObj.languageId}
                                      >
                                        {LangObj.languageName}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>

                            {/* <div class="row row-margin col-sm-6">
                       
                      </div> */}

                            {/* <div class="row row-margin col-sm-6">
                        <label class="col-sm-6 label-text label-text-cln doctrlabelTop">Work Time Filter  <span class="star-red">*</span></label>
                        <div class="col-sm-6">
                          <select multiple class="input-design" id="cmbTimeFilter" name="cmbTimeFilter" tabIndex="15">
                            {this.state.timeFilterData.map((timeObj, i) => {
                              return (
                                <option value={timeObj.timefilterId}>{timeObj.timefilterName}</option>
                              )
                            })}
                          </select>
                        </div>
                      </div> */}
                          </div>

                          <div class="col-lg-12 px-4 py-4 bg-border pos-rel">
                            {/* <div class="reg-actn reg-plus">
                        <i class="fa fa-plus-circle mt-1"></i>
                      </div> */}
                            <div className="row formsectnWidth-clinic ">
                              <div
                                class="row row-margin col-sm-12 pr-5"
                                id="hospitalSelectDiv"
                              >
                                <label class="col-sm-4 col-lg-3  label-text label-text-cln doctrlabelTop labelcolumnaftr labelclinic-colon">
                                  Clinic / Institution Name{" "}
                                  <span class="star-red">*</span>
                                </label>
                                <div class="col-sm-8 pr-0">
                                  <input
                                    type="text"
                                    class="input-design labelWidthH input-design-widthclinic"
                                    autoComplete="off"
                                    id="cmbHospitalName"
                                    name="cmbHospitalName"
                                    tabIndex="16"
                                    placeholder="Clinic /Institution Name"
                                    onKeyUp={() => this.hospitalChange()}
                                  />
                                  {/* <span id="btnOk" onClick={() => this.hospitalNameOk()}> <img src={proceedicon} title="Proceed" alt="Proceed" className="img-fluid" /> </span> */}
                                  <ul className="hospitallistUl hospitallistUlclinic-width">
                                    {this.state.hospitalArray.map(
                                      (timeObj, i) => {
                                        return (
                                          // <li className="hosptalList" onClick={() => this.setHospitalId(timeObj.hospitalId, timeObj.hospitalName,timeObj.address)}>{timeObj.hospitalName}({timeObj.address})</li>
                                          <li
                                            className="hosptalList"
                                            onClick={() =>
                                              this.setHospitalId(
                                                timeObj.hospitalId,
                                                timeObj.hospitalName,
                                                timeObj.address
                                              )
                                            }
                                          >
                                            {timeObj.hospitalName}
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                </div>

                                <input
                                  type="hidden"
                                  id="hospitalId"
                                  name="hospitalId"
                                  className="formInputField"
                                />
                              </div>

                              {/* <div className="col-sm-4 text-left  addhosptalButn">
                          <button class="formButton cursor-pointer addhosptalButn m-0" type="button" onClick={() => this.addHospital()} id="addHospitalBtn" style={{ whiteSpace: "nowrap" }} tabIndex="17">Add New Clinic/Institution</button>
                        </div> */}
                              <div
                                class="col-sm-12 row pr-5"
                                id="newHospitalSelectDiv"
                                style={{ display: "none" }}
                              >
                                <label class="col-sm-3 label-text label-text-cln doctrlabelTop labelcolumnaftr">
                                  Clinic / Institution Name{" "}
                                  <span class="star-red">*</span>
                                </label>
                                <div class="col-sm-5">
                                  <input
                                    type="text"
                                    class="input-design txtHospitalNameWidth"
                                    id="txtHospitalName"
                                    tabIndex="18"
                                    name="txtHospitalName"
                                    placeholder="Clinic/Institution Name"
                                    autoComplete="off"
                                  />
                                  <textarea
                                    onFocus={this.setHospitalAddress}
                                    onChange={this.setHospitalAddress}
                                    class="input-design mt-3 txtHospitalNameWidth"
                                    id="txtHospitalAddress"
                                    tabIndex="19"
                                    name="txtHospitalAddress"
                                    placeholder="Clinic/Institution Address"
                                    autoComplete="off"
                                  ></textarea>
                                </div>
                                <div class="col-sm-2 mt-3 pt-0 mt-md-5 pt-md-4">
                                  <button
                                    class="formButton cursor-pointer"
                                    type="button"
                                    tabIndex="20"
                                    onClick={() => this.cancelHospital()}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-lg-5 pr-0 label-reg-padd">
                              <span style={{ display: "inline-block" }}>
                                <input type="checkbox" id="chkverifyhospital" />{" "}
                                &nbsp;
                                <label
                                  for="chkverifyhospital"
                                  style={{ color: "GREEN" }}
                                  className="label-reg-displytxt"
                                >
                                  Please confirm your Clinic / Institution Name
                                </label>
                              </span>
                            </div>
                            {this.state.isIDALogin === "true" ? (
                              <div className="purpleAppointments">
                                <hr></hr>
                                <div class="m-0 form-head text-center">
                                  {this.state.isIDALogin === "false"
                                    ? "In-Person Appointment Time"
                                    : "Appointment Time"}
                                </div>
                                <hr></hr>
                                <div className="dctr-inpersn-redtext">
                                  If Holiday do not select time{" "}
                                </div>
                                <div class="row row-margin mt-4">
                                  <label class="col-2 week-text"></label>
                                  <div class="col-5 px-2 label-text-brdr">
                                    {" "}
                                    Morning Schedule{" "}
                                  </div>
                                  <div class="col-5 px-2 label-text-brdr">
                                    {" "}
                                    Afternoon Schedule{" "}
                                  </div>
                                </div>
                                <div class="row row-margin mt-4">
                                  <label class="col-2 week-text doctrlabelTop">
                                    Monday
                                  </label>

                                  <div class="col-5 px-2">
                                    <div class="col-md-6 d-inline-block mb-2 ">
                                      <select
                                        id="sh1MonFrom"
                                        name="sh1MonFrom"
                                        class="input-design input-designBold"
                                        tabIndex="18"
                                        onChange={() =>
                                          this.loadTime(
                                            "sh1",
                                            "From",
                                            "sh1MonFrom"
                                          )
                                        }
                                        onKeyPress={() => this.timeValidation()}
                                      >
                                        <option value="0">From</option>
                                        {timeList}
                                      </select>
                                    </div>
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        id="sh1MonTo"
                                        name="sh1MonTo"
                                        class="input-design input-designBold"
                                        tabIndex="19"
                                        onChange={() =>
                                          this.loadTime("sh1", "To", "sh1MonTo")
                                        }
                                      >
                                        <option value="0">To</option>
                                        {timeList}
                                      </select>
                                    </div>
                                  </div>
                                  <div class="col-5 px-2">
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        id="sh2MonFrom"
                                        name="sh2MonFrom"
                                        class="input-design input-designBold "
                                        tabIndex="20"
                                        onChange={() =>
                                          this.loadTime(
                                            "sh2",
                                            "From",
                                            "sh2MonFrom"
                                          )
                                        }
                                      >
                                        <option value="0">From</option>
                                        {timeList}
                                      </select>
                                    </div>
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        id="sh2MonTo"
                                        name="sh2MonTo"
                                        class="input-design input-designBold"
                                        tabIndex="21"
                                        onChange={() =>
                                          this.loadTime("sh2", "To", "sh2MonTo")
                                        }
                                      >
                                        <option value="0">To</option>
                                        {timeList}
                                      </select>
                                    </div>
                                  </div>
                                </div>

                                <div class="row row-margin">
                                  <label class="col-2 week-text doctrlabelTop">
                                    Tuesday
                                  </label>
                                  <div class="col-5 px-2">
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        id="sh1TueFrom"
                                        name="sh1TueFrom"
                                        class="input-design input-designBold"
                                        tabIndex="22"
                                      >
                                        <option value="0">From</option>
                                        {timeList}
                                      </select>
                                    </div>
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh1TueTo"
                                        name="sh1TueTo"
                                        tabIndex="23"
                                      >
                                        <option value="0">To</option>
                                        {timeList}
                                      </select>
                                    </div>
                                  </div>
                                  <div class="col-5 px-2">
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh2TueFrom"
                                        name="sh2TueFrom"
                                        tabIndex="24"
                                      >
                                        <option value="0">From</option>
                                        {timeList}
                                      </select>
                                    </div>
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh2TueTo"
                                        name="sh2TueTo"
                                        tabIndex="25"
                                      >
                                        <option value="0">To</option>
                                        {timeList}
                                      </select>
                                    </div>
                                  </div>
                                </div>

                                <div class="row row-margin">
                                  <label class="col-2 week-text doctrlabelTop">
                                    Wednesday
                                  </label>
                                  <div class="col-5 px-2">
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh1WedFrom"
                                        name="sh1WedFrom"
                                        tabIndex="26"
                                      >
                                        <option value="0">From</option>
                                        {timeList}
                                      </select>
                                    </div>
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh1WedTo"
                                        name="sh1WedTo"
                                        tabIndex="27"
                                      >
                                        <option value="0">To</option>
                                        {timeList}
                                      </select>
                                    </div>
                                  </div>
                                  <div class="col-5 px-2">
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh2WedFrom"
                                        name="sh2WedFrom"
                                        tabIndex="28"
                                      >
                                        <option value="0">From</option>
                                        {timeList}
                                      </select>
                                    </div>
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh2WedTo"
                                        name="sh2WedTo"
                                        tabIndex="29"
                                      >
                                        <option value="0">To</option>
                                        {timeList}
                                      </select>
                                    </div>
                                  </div>
                                </div>

                                <div class="row row-margin">
                                  <label class="col-2 week-text doctrlabelTop">
                                    Thursday
                                  </label>
                                  <div class="col-5 px-2">
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh1ThurFrom"
                                        name="sh1ThurFrom"
                                        tabIndex="30"
                                      >
                                        <option value="0">From</option>
                                        {timeList}
                                      </select>
                                    </div>
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh1ThurTo"
                                        name="sh1ThurTo"
                                        tabIndex="31"
                                      >
                                        <option value="0">To</option>
                                        {timeList}
                                      </select>
                                    </div>
                                  </div>
                                  <div class="col-5 px-2">
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh2ThurFrom"
                                        name="sh2ThurFrom"
                                        tabIndex="32"
                                      >
                                        <option value="0">From</option>
                                        {timeList}
                                      </select>
                                    </div>
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh2ThurTo"
                                        name="sh2ThurTo"
                                        tabIndex="33"
                                      >
                                        <option value="0">To</option>
                                        {timeList}
                                      </select>
                                    </div>
                                  </div>
                                </div>

                                <div class="row row-margin">
                                  <label class="col-2 week-text doctrlabelTop">
                                    Friday
                                  </label>
                                  <div class="col-5 px-2">
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh1FriFrom"
                                        name="sh1FriFrom"
                                        tabIndex="34"
                                      >
                                        <option value="0">From</option>
                                        {timeList}
                                      </select>
                                    </div>
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh1FriTo"
                                        name="sh1FriTo"
                                        tabIndex="35"
                                      >
                                        <option value="0">To</option>
                                        {timeList}
                                      </select>
                                    </div>
                                  </div>
                                  <div class="col-5 px-2">
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh2FriFrom"
                                        name="sh2FriFrom"
                                        tabIndex="36"
                                      >
                                        <option value="0">From</option>
                                        {timeList}
                                      </select>
                                    </div>
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh2FriTo"
                                        name="sh2FriTo"
                                        tabIndex="37"
                                      >
                                        <option value="0">To</option>
                                        {timeList}
                                      </select>
                                    </div>
                                  </div>
                                </div>

                                <div class="row row-margin">
                                  <label class="col-2 week-text doctrlabelTop">
                                    Saturday
                                  </label>
                                  <div class="col-5 px-2">
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh1SatFrom"
                                        name="sh1SatFrom"
                                        tabIndex="38"
                                      >
                                        <option value="0">From</option>
                                        {timeList}
                                      </select>
                                    </div>
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh1SatTo"
                                        name="sh1SatTo"
                                        tabIndex="39"
                                      >
                                        <option value="0">To</option>
                                        {timeList}
                                      </select>
                                    </div>
                                  </div>
                                  <div class="col-5 px-2">
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh2SatFrom"
                                        name="sh2SatFrom"
                                        tabIndex="40"
                                      >
                                        <option value="0">From</option>
                                        {timeList}
                                      </select>
                                    </div>
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh2SatTo"
                                        name="sh2SatTo"
                                        tabIndex="41"
                                      >
                                        <option value="0">To</option>
                                        {timeList}
                                      </select>
                                    </div>
                                  </div>
                                </div>

                                <div class="row row-margin">
                                  <label class="col-2 week-text doctrlabelTop">
                                    Sunday
                                  </label>
                                  <div class="col-5 px-2">
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh1SunFrom"
                                        name="sh1SunFrom"
                                        tabIndex="42"
                                      >
                                        <option value="0">From</option>
                                        {timeList}
                                      </select>
                                    </div>
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh1SunTo"
                                        name="sh1SunTo"
                                        tabIndex="43"
                                      >
                                        <option value="0">To</option>
                                        {timeList}
                                      </select>
                                    </div>
                                  </div>
                                  <div class="col-5 px-2">
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh2SunFrom"
                                        name="sh2SunFrom"
                                        tabIndex="44"
                                      >
                                        <option value="0">From</option>
                                        {timeList}
                                      </select>
                                    </div>
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh2SunTo"
                                        name="sh2SunTo"
                                        tabIndex="45"
                                      >
                                        <option value="0">To</option>
                                        {timeList}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="p-3"></div>
                          <div class="form-section  formsectnWidth formsectnWidthAdrs row mt-4">
                            <div class="row row-margin col-sm-12 col-lg-6">
                              <label class="col-sm-4 label-text label-text-cln doctrlabelTop">
                                Doctor Email <span class="star-red">*</span>
                              </label>
                              <div class="col-sm-6">
                                <input
                                  type="text"
                                  class="input-design"
                                  autoComplete="off"
                                  id="txtEmail"
                                  name="txtEmail"
                                  tabIndex="46"
                                  placeholder="Doctor Email"
                                />
                              </div>
                            </div>
                            <div class="row row-margin col-sm-12 col-lg-6">
                              <label class="col-sm-4 label-text label-text-cln doctrlabelTop">
                                Clinic Email<span class="star-red">*</span>
                              </label>
                              <div class="col-sm-6">
                                <input
                                  type="text"
                                  class="input-design"
                                  autoComplete="off"
                                  id="txtClinicEmailId"
                                  name="txtClinicEmailId"
                                  tabIndex="47"
                                  placeholder="Clinic Email Id"
                                // onChange={this.changeHospitalName}
                                />
                              </div>
                            </div>
                            <div class="row row-margin col-sm-12 col-lg-6">
                              <label class="col-sm-4 label-text label-text-cln doctrlabelTop">
                                Clinic Address <span class="star-red">*</span>
                              </label>
                              <div class="col-sm-6">
                                <input
                                  type="text"
                                  class="input-design"
                                  autoComplete="off"
                                  id="txtAddress"
                                  name="txtAddress"
                                  tabIndex="48"
                                  placeholder="Clinic Address"
                                  onChange={this.changeHospitalName}
                                />
                              </div>
                            </div>
                            <div class="row row-margin col-sm-12 col-lg-6">
                              <label class="col-sm-4 label-text label-text-cln doctrlabelTop">
                                State<span class="star-red">*</span>
                              </label>
                              <div class="col-sm-6">
                                <select
                                  class="input-design"
                                  id="cmbState"
                                  name="cmbState"
                                  tabIndex="49"
                                  onChange={(e) => this.cityAgainstState(e)}
                                >
                                  <option value="">Select</option>
                                  {this.state.stateData.map((stateObj, i) => {
                                    return (
                                      <option
                                        key={stateObj.stateId}
                                        value={stateObj.stateName}
                                        data-id={stateObj.stateId}
                                      >
                                        {stateObj.stateName}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>

                            <div class="row row-margin col-sm-12 col-lg-6">
                              <label class="col-sm-4 label-text label-text-cln doctrlabelTop">
                                {this.state.isIDALogin === "false"
                                  ? "City"
                                  : "District"}{" "}
                                <span class="star-red">*</span>
                              </label>
                              <div class="col-sm-6">
                                <select
                                  class="input-design"
                                  id="cmbCity"
                                  tabIndex="50"
                                  name="cmbCity"
                                  onChange={(e) => this.subCityAgainstCity(e)}
                                >
                                  <option value="">Select</option>
                                  {this.state.cityArray.map((stateObj, i) => {
                                    return (
                                      <option value={stateObj.cityName} data-id={stateObj.cityId}>
                                        {stateObj.cityName}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>

                            <div class="row row-margin col-sm-12 col-lg-6">
                              <label class="col-sm-4 label-text label-text-cln doctrlabelTop">
                                {this.state.isIDALogin === "false"
                                  ? "Location"
                                  : "Place"}
                              </label>
                              <div class="col-sm-6">
                                {/* <select class="input-design" id="cmbSubCity" name="cmbSubCity" tabIndex="50">
                              <option value="">Select</option>
                              {this.state.subCityArray.map((stateObj, i) => {
                                return (
                                  <option value={stateObj.subcityName}>{stateObj.subcityName}</option>
                                )
                              })}
                            </select> */}
                                <input
                                  type="text"
                                  class="input-design"
                                  autoComplete="off"
                                  id="cmbSubCity"
                                  name="cmbSubCity"
                                  tabIndex="51"
                                  placeholder={
                                    this.state.isIDALogin === "false"
                                      ? "Location"
                                      : "Place"
                                  }
                                />
                              </div>
                            </div>

                            {/* <div class="row row-margin col-sm-6">
                        <label class="col-sm-4 label-text label-text-cln doctrlabelTop">Country<span class="star-red">*</span></label>
                        <div class="col-sm-6">
                          <select class="input-design" id="cmbCountry" name="cmbCountry" tabIndex="51">
                            <option value="0">Select</option>
                            {this.state.countryArray.map((LangObj, i) => {
                              return (
                                <option value={LangObj.countryId}>{LangObj.countryName}</option>
                              )
                            })}
                          </select>
                        </div>
                      </div> */}

                            <div class="row row-margin col-sm-12 col-lg-6">
                              <label class="col-sm-4 label-text label-text-cln doctrlabelTop">
                                Pin Code <span class="star-red">*</span>
                              </label>
                              <div class="col-sm-6">
                                <input
                                  type="text"
                                  class="input-design"
                                  autoComplete="off"
                                  id="txtPinCode"
                                  tabIndex="52"
                                  name="txtPinCode"
                                  placeholder="Pin Code"
                                  onKeyUp={this.handleChange}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>

                            <div class="row row-margin col-sm-12 col-lg-6">
                              <label class="col-sm-4 label-text label-text-cln doctrlabelTop">
                                Clinic Mobile No.
                                <label>
                                  <span class="star-red">*</span>
                                </label>{" "}
                              </label>
                              <div class="col-sm-6">
                                <input
                                  type="text"
                                  class="input-design"
                                  autoComplete="off"
                                  id="txtPhone"
                                  name="txtPhone"
                                  maxLength={10}
                                  tabIndex="53"
                                  placeholder="Clinic Mobile No."
                                  onKeyUp={this.handleChange}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>

                            <div class="row row-margin col-sm-12 col-lg-6">
                              <label class="col-sm-4 label-text label-text-cln doctrlabelTop doctrlabelTopZero">
                                Doctor Mobile No.<span class="star-red">*</span>
                              </label>
                              <div class="col-sm-6">
                                <input
                                  type="text"
                                  class="input-design"
                                  autoComplete="off"
                                  id="txtMobile"
                                  maxLength="10"
                                  tabIndex="54"
                                  name="txtMobile"
                                  placeholder="Doctor mobile number "
                                  onKeyUp={this.handleChange}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                          </div>

                          <hr></hr>

                          <div class="form-section formsectnWidth row">
                            {this.state.isIDALogin === "false" ? (
                              <div class="row row-margin col-lg-6 col-md-8">
                                <label class="col-sm-4 col-md-5 col-lg-4 label-text label-text-cln doctrlabelTop text-left">
                                  Medicine branch{" "}
                                  <span class="star-red">*</span>
                                </label>
                                <div class="col-sm-6">
                                  <select
                                    class="input-design"
                                    id="cmbMedicineBranch"
                                    name="cmbMedicineBranch"
                                    tabIndex="55"
                                    onChange={() => this.getSpecialisation()}
                                  >
                                    <option value="">Select</option>
                                    {this.state.medicineBranchData.map(
                                      (LangObj, i) => {
                                        return (
                                          <option
                                            value={
                                              i + "-" + LangObj.medicineBranchId
                                            }
                                          >
                                            {LangObj.medicineBranchName}
                                          </option>
                                        );
                                      }
                                    )}
                                  </select>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="col-md-12">
                              <div class="form-head mt-4">
                                Treatment Specialities
                                {this.state.isIDALogin === "false" ? (
                                  <span className="star-red">
                                    {" "}
                                    (Choose atleast one Primary Specialisation)
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            {this.state.specialization.length > 0 ? (
                              <div className="col-md-12">
                                <div class="table-bordered mainBx" border="1">
                                  <div>
                                    {this.state.specialization.map(
                                      (speObj, s) => {
                                        return this.state.isIDALogin ===
                                          "false" ? (
                                          <div class="subBx">
                                            <div class="spanLeft">
                                              <input
                                                defaultChecked={this.state.selspecializationId.includes(
                                                  speObj.specialityId
                                                )}
                                                onClick={() =>
                                                  this.specialityClick(
                                                    speObj.specialityId,
                                                    s
                                                  )
                                                }
                                                type="checkbox"
                                                value={speObj.specialityId}
                                                id={"chkSpecialisation" + s}
                                              />
                                              <span class="tdtxt">
                                                {speObj.speciality}
                                              </span>
                                            </div>
                                            <input
                                              type="hidden"
                                              value={speObj.specialityId}
                                            />

                                            <div class="spanRight">
                                              <input
                                                type="checkbox"
                                                class="radiobtnindex3 spl0"
                                                id={
                                                  "chkPrimarySpecialisation" + s
                                                }
                                                onClick={() =>
                                                  this.primarySpecialisationValidation(
                                                    s
                                                  )
                                                }
                                              />
                                              <span class="tdtxt">
                                                Primary Specialization
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <div class="subBx">
                                            <div class="spanLeft">
                                              <input
                                                onClick={() =>
                                                  this.specialityClick(
                                                    speObj.specialityId,
                                                    s
                                                  )
                                                }
                                                readOnly={
                                                  speObj.specialityId === 479
                                                    ? true
                                                    : false
                                                }
                                                defaultChecked={this.state.selspecializationId.includes(
                                                  speObj.specialityId
                                                )}
                                                type="checkbox"
                                                value={speObj.specialityId}
                                                id={"chkSpecialisation" + s}
                                              />
                                              <span class="tdtxt">
                                                {speObj.speciality}
                                              </span>
                                            </div>
                                            <input
                                              type="hidden"
                                              value={speObj.specialityId}
                                            />
                                            {speObj.specialityId === 479 ? (
                                              <div class="spanRight">
                                                <input
                                                  readOnly={true}
                                                  checked={true}
                                                  type="checkbox"
                                                  class="radiobtnindex3 spl0"
                                                  id={
                                                    "chkPrimarySpecialisation" +
                                                    s
                                                  }
                                                  onClick={() =>
                                                    this.primarySpecialisationValidation(
                                                      s
                                                    )
                                                  }
                                                />
                                                <span class="tdtxt">
                                                  Primary Specialization
                                                </span>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            <div class="row row-margin col-sm-12 mt-3">
                              <label class="col-sm-12 label-text text-left">
                                Brief Description about You{" "}
                                <span class="star-red">*</span>
                              </label>
                              <div class="col-sm-12">
                                <textarea
                                  class="input-design prfsnlStatemntWidth"
                                  autoComplete="off"
                                  id="txtAreaStatement"
                                  name="txtAreaStatement"
                                  tabIndex="56"
                                  placeholder="A Short Professional Statement about you"
                                ></textarea>
                              </div>
                            </div>
                            <div class="row row-margin col-sm-12 mt-3">
                              <label class="col-sm-12 label-text text-left">
                                Service Offered
                              </label>
                              <div class="col-sm-12">
                                <textarea
                                  class="input-design prfsnlStatemntWidth"
                                  autoComplete="off"
                                  id="txtAreaService"
                                  name="txtAreaService"
                                  tabIndex="57"
                                  placeholder="Services Offered"
                                ></textarea>
                              </div>
                            </div>
                            <div class="row row-margin col-sm-12 mt-3" id="hospitalProfessionalStatementDiv" style={{ display: "none" }}>
                              <label class="col-sm-12 label-text text-left">
                                Hospital Professional Statement
                              </label>
                              <div class="col-sm-12">
                                <textarea
                                  class="input-design prfsnlStatemntWidth"
                                  autoComplete="off"
                                  id="hospitalProfessionalStatement"
                                  name="hospitalProfessionalStatement"
                                  tabIndex="58"
                                  placeholder="Hospital Professionale Statement"
                                ></textarea>
                              </div>
                            </div>
                            <div class="row row-margin col-sm-12 mt-3" id="hospitalserviceOfferedDiv" style={{ display: "none" }}>
                              <label class="col-sm-12 label-text text-left">
                                Hospital Service Offered
                              </label>
                              <div class="col-sm-12">
                                <textarea
                                  class="input-design prfsnlStatemntWidth"
                                  autoComplete="off"
                                  id="hospitalserviceOffered"
                                  name="hospitalserviceOffered"
                                  tabIndex="59"
                                  placeholder="Hospital Services Offered"
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 form-head pl-0">
                            {" "}
                            Education Details{" "}
                          </div>
                          {this.state.educationDetailsArray.map((eduObj, i) => {
                            return (
                              <div class="form-section-bgcolor row mx-0 mb-4 pos-rel">
                                <div class="row row-margin col-sm-12 col-lg-5">
                                  <label class="col-sm-4 col-lg-4 label-text label-text-cln marginAutoLabel labelcolumnaftr mt-1 ">
                                    Education<span class="star-red">*</span>{" "}
                                  </label>
                                  <div class="col-sm-7 col-lg-8">
                                    <select
                                      class="input-design mb-0"
                                      id={"cmbEducation" + i}
                                      name={"cmbEducation" + i}
                                      tabIndex="60"
                                      value={eduObj.Id}
                                      onChange={() =>
                                        this.setEducationData(i, "cmbEducation")
                                      }
                                    >
                                      <option value="0">Select</option>
                                      {this.state.educationData.length > 0
                                        ? this.state.educationData.map(
                                          (eduObj, s) => {
                                            return (
                                              <option
                                                value={
                                                  eduObj.educationCategoryId
                                                }
                                              >
                                                {eduObj.educationCategory}
                                              </option>
                                            );
                                          }
                                        )
                                        : ""}
                                    </select>
                                  </div>
                                </div>
                                <div class="row row-margin col-sm-12 col-lg-3">
                                  <label class="col-sm-4 col-lg-5 label-text label-text-cln marginAutoLabel labelcolumnaftr mt-1 ">
                                    Year{" "}
                                  </label>
                                  <div class="col-sm-7">
                                    <select
                                      class="input-design mb-0"
                                      id={"cmbYear" + i}
                                      name={"cmbYear" + i}
                                      tabIndex="61"
                                      defaultValue={eduObj.year}
                                      onChange={() =>
                                        this.setEducationData(i, "cmbYear")
                                      }
                                    >
                                      {/* <option value={eduObj.year}>{eduObj.year}</option> */}
                                    </select>
                                  </div>
                                </div>
                                <div class="row row-margin col-sm-12 col-lg-4">
                                  <label class="col-sm-4 col-lg-5 label-text label-text-cln marginAutoLabel labelcolumnaftr mt-1 ">
                                    College
                                  </label>
                                  <div class="col-sm-7">
                                    <input
                                      type="text"
                                      class="input-design mb-0"
                                      autoComplete="off"
                                      id={"txtCollege" + i}
                                      name="txtCollege"
                                      tabIndex="62"
                                      placeholder="College"
                                      onChange={() =>
                                        this.setEducationData(i, "txtCollege")
                                      }
                                      defaultValue={eduObj.college}
                                    />
                                  </div>
                                </div>
                                {this.state.educationDetailsArray.length - 1 ===
                                  i ? (
                                  <div class="reg-actn reg-plus edu-icon-size">
                                    <button
                                      class="fa fa-plus-circle mt-1 pluiconbutnborder"
                                      tabIndex="63"
                                      onClick={() => this.educationDetails(i)}
                                    ></button>
                                  </div>
                                ) : (
                                  <div class="reg-actn reg-close edu-icon-size">
                                    <i
                                      class="fa fa-times-circle mt-1"
                                      onClick={() =>
                                        this.removeEducationDetails(
                                          eduObj.Id,
                                          i
                                        )
                                      }
                                    ></i>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                          {/* <div class="col-md-12 form-head"> Achievements Details </div>
                    <div class="form-section-bgcolor row mx-0 mb-4 pos-rel">
                      <div class="row row-margin col-sm-5">
                        <label class="col-sm-5 label-text label-text-cln">Achievements</label>
                        <div class="col-sm-6">
                          <select class="input-design" >
                            <option value="1">Select</option>
                            {this.state.achievementTypeData.map((achiveObj, s) => {
                              return (
                                <option value={achiveObj.achievementTypeId}>{achiveObj.achievementTypeName}</option>
                              )
                            })}
    
                          </select>
                        </div>
                      </div>
                      <div class="row row-margin col-sm-5">
                        <label class="col-sm-5 label-text label-text-cln">Description </label>
                        <div class="col-sm-6">
                          <input type="text" class="input-design" />
                        </div>
                      </div>
                      <div class="reg-actn reg-plus">
                        <i class="fa fa-plus-circle mt-1"></i>
                      </div>
                    </div>
    
                    <div class="form-section-bgcolor row mx-0 mb-4 pos-rel">
                      <div class="row row-margin col-sm-5">
                        <label class="col-sm-5 label-text label-text-cln">Achievements </label>
                        <div class="col-sm-6">
                          <select class="input-design" >
                            <option value="1">Select</option>
                          </select>
                        </div>
                      </div>
                      <div class="row row-margin col-sm-5">
                        <label class="col-sm-5 label-text label-text-cln">Description </label>
                        <div class="col-sm-6">
                          <input type="text" class="input-design" />
                        </div>
                      </div>
                      <div class="reg-actn reg-close">
                        <i class="fa fa-times-circle mt-1"></i>
                      </div>
                    </div> */}

                          {/* <div class="row row-margin col-sm-12 my-3">
                      <label class="col-sm-12 label-text text-left">Search Keyword</label>
                      <div class="col-sm-12">
                        <textarea class="input-design" id="txtAreaSearchKeyword" name="txtAreaSearchKeyword" placeholder="Search Keyword" ></textarea>
                      </div>
                    </div> */}
                          {this.state.isIDALogin === "false" ? (
                            <div>
                              <div class="col-md-12 form-head pl-0">
                                {" "}
                                Services{" "}
                              </div>

                              <div class="col-md-12 mt-2 pl-0">
                                <input
                                  type="checkbox"
                                  name="chkInperson"
                                  id="chkInperson"
                                  tabindex="64"
                                  onClick={() => this.textBoxEneble("Inperson")}
                                />
                                <label class="col-sm-4 col-lg-3 label-text label-text-cln textleftAlign">
                                  In-Person Appointment
                                </label>
                                <input
                                  type="text"
                                  class="input-design col-sm-2"
                                  autoComplete="off"
                                  name="txtInpersonPrice"
                                  id="txtInperson"
                                  placeholder="INR"
                                  style={{ display: "none" }}
                                  onKeyUp={this.handleChange}
                                  onChange={this.handleChange}
                                />
                              </div>

                              <div class="col-md-12 mt-2 pl-0">
                                <input
                                  type="checkbox"
                                  name="chkVideo"
                                  id="chkVideo"
                                  tabindex="65"
                                  onClick={() => this.textBoxEneble("Video")}
                                />
                                <label class="col-sm-4 col-lg-3 label-text label-text-cln textleftAlign">
                                  Video Chat
                                </label>
                                <input
                                  type="text"
                                  class="input-design col-sm-2"
                                  autoComplete="off"
                                  name="txtVideoPrice"
                                  id="txtVideo"
                                  placeholder="INR"
                                  style={{ display: "none" }}
                                  onKeyUp={this.handleChange}
                                  onChange={this.handleChange}
                                />
                              </div>

                              <div class="col-md-12 mt-2 pl-0">
                                <input
                                  type="checkbox"
                                  name="chkAudio"
                                  id="chkAudio"
                                  tabindex="66"
                                  onClick={() => this.textBoxEneble("Audio")}
                                />
                                <label class="col-sm-4 col-lg-3 label-text label-text-cln textleftAlign">
                                  Audio Chat
                                </label>
                                <input
                                  type="text"
                                  class="input-design col-sm-2"
                                  autoComplete="off"
                                  name="txtAudioPrice"
                                  id="txtAudio"
                                  placeholder="INR"
                                  style={{ display: "none" }}
                                  onKeyUp={this.handleChange}
                                  onChange={this.handleChange}
                                />
                              </div>

                              <div class="col-md-12 mt-2 pl-0">
                                <input
                                  type="checkbox"
                                  name="chkText"
                                  id="chkText"
                                  tabindex="67"
                                  onClick={() => this.textBoxEneble("Text")}
                                />
                                <label class="col-sm-4 col-lg-3 label-text label-text-cln textleftAlign">
                                  Text Chat
                                </label>
                                <input
                                  type="text"
                                  class="input-design col-sm-2"
                                  autoComplete="off"
                                  name="txtTextChatPrice"
                                  id="txtText"
                                  placeholder="INR"
                                  style={{ display: "none" }}
                                  onKeyUp={this.handleChange}
                                  onChange={this.handleChange}
                                />
                              </div>
                              <div
                                id="hiddenInpersonTime"
                                style={{ display: "none" }}
                                class="col-lg-12 px-4 mt-3 py-4 bg-border pos-rel"
                              >
                                <div class="m-0 form-head text-center">
                                  {" "}
                                  Inperson Appointment Time
                                </div>
                                <hr></hr>
                                <div class="row row-margin mt-4">
                                  <label class="col-2 week-text"></label>
                                  <div class="col-5 px-2 label-text-brdr">
                                    {" "}
                                    Schedule 1{" "}
                                  </div>
                                  <div class="col-5 px-2 label-text-brdr">
                                    {" "}
                                    Schedule 2{" "}
                                  </div>
                                </div>
                                <div class="row row-margin mt-4">
                                  <label class="col-2 week-text doctrlabelTop">
                                    Monday
                                  </label>

                                  <div class="col-5 px-2">
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        id="sh1MonFrom"
                                        name="sh1MonFrom"
                                        class="input-design input-designBold"
                                        tabIndex="18"
                                        onChange={() =>
                                          this.loadTime(
                                            "sh1",
                                            "From",
                                            "sh1MonFrom"
                                          )
                                        }
                                        onKeyPress={() => this.timeValidation()}
                                      >
                                        <option value="0">From</option>
                                        {timeList}
                                      </select>
                                    </div>
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        id="sh1MonTo"
                                        name="sh1MonTo"
                                        class="input-design input-designBold"
                                        tabIndex="19"
                                        onChange={() =>
                                          this.loadTime("sh1", "To", "sh1MonTo")
                                        }
                                      >
                                        <option value="0">To</option>
                                        {timeList}
                                      </select>
                                    </div>
                                  </div>
                                  <div class="col-5 px-2">
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        id="sh2MonFrom"
                                        name="sh2MonFrom"
                                        class="input-design input-designBold"
                                        tabIndex="20"
                                        onChange={() =>
                                          this.loadTime(
                                            "sh2",
                                            "From",
                                            "sh2MonFrom"
                                          )
                                        }
                                      >
                                        <option value="0">From</option>
                                        {timeList}
                                      </select>
                                    </div>
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        id="sh2MonTo"
                                        name="sh2MonTo"
                                        class="input-design input-designBold"
                                        tabIndex="21"
                                        onChange={() =>
                                          this.loadTime("sh2", "To", "sh2MonTo")
                                        }
                                      >
                                        <option value="0">To</option>
                                        {timeList}
                                      </select>
                                    </div>
                                  </div>
                                </div>

                                <div class="row row-margin">
                                  <label class="col-2 week-text doctrlabelTop">
                                    Tuesday
                                  </label>
                                  <div class="col-5 px-2">
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        id="sh1TueFrom"
                                        name="sh1TueFrom"
                                        class="input-design input-designBold"
                                        tabIndex="22"
                                      >
                                        <option value="0">From</option>
                                        {timeList}
                                      </select>
                                    </div>
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh1TueTo"
                                        name="sh1TueTo"
                                        tabIndex="23"
                                      >
                                        <option value="0">To</option>
                                        {timeList}
                                      </select>
                                    </div>
                                  </div>
                                  <div class="col-5 px-2">
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh2TueFrom"
                                        name="sh2TueFrom"
                                        tabIndex="24"
                                      >
                                        <option value="0">From</option>
                                        {timeList}
                                      </select>
                                    </div>
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh2TueTo"
                                        name="sh2TueTo"
                                        tabIndex="25"
                                      >
                                        <option value="0">To</option>
                                        {timeList}
                                      </select>
                                    </div>
                                  </div>
                                </div>

                                <div class="row row-margin">
                                  <label class="col-2 week-text doctrlabelTop">
                                    Wednesday
                                  </label>
                                  <div class="col-5 px-2">
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh1WedFrom"
                                        name="sh1WedFrom"
                                        tabIndex="26"
                                      >
                                        <option value="0">From</option>
                                        {timeList}
                                      </select>
                                    </div>
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh1WedTo"
                                        name="sh1WedTo"
                                        tabIndex="27"
                                      >
                                        <option value="0">To</option>
                                        {timeList}
                                      </select>
                                    </div>
                                  </div>
                                  <div class="col-5 px-2">
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh2WedFrom"
                                        name="sh2WedFrom"
                                        tabIndex="28"
                                      >
                                        <option value="0">From</option>
                                        {timeList}
                                      </select>
                                    </div>
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh2WedTo"
                                        name="sh2WedTo"
                                        tabIndex="29"
                                      >
                                        <option value="0">To</option>
                                        {timeList}
                                      </select>
                                    </div>
                                  </div>
                                </div>

                                <div class="row row-margin">
                                  <label class="col-2 week-text doctrlabelTop">
                                    Thursday
                                  </label>
                                  <div class="col-5 px-2">
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh1ThurFrom"
                                        name="sh1ThurFrom"
                                        tabIndex="30"
                                      >
                                        <option value="0">From</option>
                                        {timeList}
                                      </select>
                                    </div>
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh1ThurTo"
                                        name="sh1ThurTo"
                                        tabIndex="31"
                                      >
                                        <option value="0">To</option>
                                        {timeList}
                                      </select>
                                    </div>
                                  </div>
                                  <div class="col-5 px-2">
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh2ThurFrom"
                                        name="sh2ThurFrom"
                                        tabIndex="32"
                                      >
                                        <option value="0">From</option>
                                        {timeList}
                                      </select>
                                    </div>
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh2ThurTo"
                                        name="sh2ThurTo"
                                        tabIndex="33"
                                      >
                                        <option value="0">To</option>
                                        {timeList}
                                      </select>
                                    </div>
                                  </div>
                                </div>

                                <div class="row row-margin">
                                  <label class="col-2 week-text doctrlabelTop">
                                    Friday
                                  </label>
                                  <div class="col-5 px-2">
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh1FriFrom"
                                        name="sh1FriFrom"
                                        tabIndex="34"
                                      >
                                        <option value="0">From</option>
                                        {timeList}
                                      </select>
                                    </div>
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh1FriTo"
                                        name="sh1FriTo"
                                        tabIndex="35"
                                      >
                                        <option value="0">To</option>
                                        {timeList}
                                      </select>
                                    </div>
                                  </div>
                                  <div class="col-5 px-2">
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh2FriFrom"
                                        name="sh2FriFrom"
                                        tabIndex="36"
                                      >
                                        <option value="0">From</option>
                                        {timeList}
                                      </select>
                                    </div>
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh2FriTo"
                                        name="sh2FriTo"
                                        tabIndex="37"
                                      >
                                        <option value="0">To</option>
                                        {timeList}
                                      </select>
                                    </div>
                                  </div>
                                </div>

                                <div class="row row-margin">
                                  <label class="col-2 week-text doctrlabelTop">
                                    Saturday
                                  </label>
                                  <div class="col-5 px-2">
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh1SatFrom"
                                        name="sh1SatFrom"
                                        tabIndex="38"
                                      >
                                        <option value="0">From</option>
                                        {timeList}
                                      </select>
                                    </div>
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh1SatTo"
                                        name="sh1SatTo"
                                        tabIndex="39"
                                      >
                                        <option value="0">To</option>
                                        {timeList}
                                      </select>
                                    </div>
                                  </div>
                                  <div class="col-5 px-2">
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh2SatFrom"
                                        name="sh2SatFrom"
                                        tabIndex="40"
                                      >
                                        <option value="0">From</option>
                                        {timeList}
                                      </select>
                                    </div>
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh2SatTo"
                                        name="sh2SatTo"
                                        tabIndex="41"
                                      >
                                        <option value="0">To</option>
                                        {timeList}
                                      </select>
                                    </div>
                                  </div>
                                </div>

                                <div class="row row-margin">
                                  <label class="col-2 week-text doctrlabelTop">
                                    Sunday
                                  </label>
                                  <div class="col-5 px-2">
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh1SunFrom"
                                        name="sh1SunFrom"
                                        tabIndex="42"
                                      >
                                        <option value="0">From</option>
                                        {timeList}
                                      </select>
                                    </div>
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh1SunTo"
                                        name="sh1SunTo"
                                        tabIndex="43"
                                      >
                                        <option value="0">To</option>
                                        {timeList}
                                      </select>
                                    </div>
                                  </div>
                                  <div class="col-5 px-2">
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh2SunFrom"
                                        name="sh2SunFrom"
                                        tabIndex="44"
                                      >
                                        <option value="0">From</option>
                                        {timeList}
                                      </select>
                                    </div>
                                    <div class="col-md-6 d-inline-block mb-2">
                                      <select
                                        class="input-design input-designBold"
                                        id="sh2SunTo"
                                        name="sh2SunTo"
                                        tabIndex="45"
                                      >
                                        <option value="0">To</option>
                                        {timeList}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                id="audioVideoAppointmentDiv"
                                style={{ display: "none" }}
                              >
                                <div class="col-md-12 form-head pl-0 mt-4">
                                  {" "}
                                  Video / Audio Appointment Time{" "}
                                </div>
                                <div class="col-lg-12  py-4 AppintmntTablewidth pl-0  pos-rel">
                                  <table class="table table-small AppointmentTimeTable">
                                    <thead>
                                      <tr className="bg-gray">
                                        <th>Day</th>
                                        <th>From Time</th>
                                        <th>To Time</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>Sunday</td>
                                        <td>
                                          <TimeComponent
                                            idValue="sunFrom"
                                            nameValue="sunFrom"
                                            timeType="From"
                                          ></TimeComponent>
                                        </td>
                                        <td>
                                          <TimeComponent
                                            idValue="sunTo"
                                            nameValue="sunTo"
                                            timeType="To"
                                          ></TimeComponent>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Monday</td>
                                        <td>
                                          <TimeComponent
                                            idValue="monFrom"
                                            nameValue="monFrom"
                                            timeType="From"
                                            testMainFrom="testMainFrom"
                                          ></TimeComponent>
                                        </td>
                                        <td>
                                          <TimeComponent
                                            idValue="monTo"
                                            nameValue="monTo"
                                            timeType="To"
                                            testMainTo="testMainTo"
                                          ></TimeComponent>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>Tuesday</td>
                                        <td>
                                          <TimeComponent
                                            idValue="tuesFrom"
                                            nameValue="tuesFrom"
                                            timeType="From"
                                            testMainFrom="testMainFrom"
                                          ></TimeComponent>
                                        </td>
                                        <td>
                                          <TimeComponent
                                            idValue="tuesTo"
                                            nameValue="tuesTo"
                                            timeType="To"
                                            testMainTo="testMainTo"
                                          ></TimeComponent>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>Wednesday</td>
                                        <td>
                                          <TimeComponent
                                            idValue="wedFrom"
                                            nameValue="wedFrom"
                                            timeType="From"
                                            testMainFrom="testMainFrom"
                                          ></TimeComponent>
                                        </td>
                                        <td>
                                          <TimeComponent
                                            idValue="wedTo"
                                            nameValue="wedTo"
                                            timeType="To"
                                            testMainTo="testMainTo"
                                          ></TimeComponent>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>Thursday</td>
                                        <td>
                                          <TimeComponent
                                            idValue="thuFrom"
                                            nameValue="thuFrom"
                                            timeType="From"
                                            testMainFrom="testMainFrom"
                                          ></TimeComponent>
                                        </td>
                                        <td>
                                          <TimeComponent
                                            idValue="thuTo"
                                            nameValue="thuTo"
                                            timeType="To"
                                            testMainTo="testMainTo"
                                          ></TimeComponent>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>Friday</td>
                                        <td>
                                          <TimeComponent
                                            idValue="friFrom"
                                            nameValue="friFrom"
                                            timeType="From"
                                            testMainFrom="testMainFrom"
                                          ></TimeComponent>
                                        </td>
                                        <td>
                                          <TimeComponent
                                            idValue="friTo"
                                            nameValue="friTo"
                                            timeType="To"
                                            testMainTo="testMainTo"
                                          ></TimeComponent>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>Saturday</td>
                                        <td>
                                          <TimeComponent
                                            idValue="satFrom"
                                            nameValue="satFrom"
                                            timeType="From"
                                            testMainFrom="testMainFrom"
                                          ></TimeComponent>
                                        </td>
                                        <td>
                                          <TimeComponent
                                            idValue="satTo"
                                            nameValue="satTo"
                                            timeType="To"
                                            testMainTo="testMainTo"
                                          ></TimeComponent>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div
                            className="col-12 pl-0"
                            style={{ display: "none" }}
                          >
                            <label class="col-sm-12 col-lg-12 label-text label-text-cln textleftAlign labelcolorRed pl-0">
                              Text Demo Text Demo Text Demo Text Demo Text Demo
                              Text Demo
                            </label>
                          </div>

                          <div className="col-12 pl-0">
                            {/* {this.state.isIDALogin === "false" ? */}
                            <div>
                              {/* <input
                                type="text"
                                class="input-design col-lg-2 col-sm-4 col-md-4 labelwidthMob"
                                id="txtOtp"
                                placeholder="Enter OTP"
                                style={{ display: "none" }}
                              /> */}

                              {/* <button
                                className="formButton formButtonBold mt-4"
                                id="btnGetOtp"
                                tabindex="65"
                                onClick={() => this.sentOtp("")}
                              >
                                Get OTP{" "}
                              </button>
                              <button
                                className="formButton formButtonBold mt-4"
                                style={{ display: "none" }}
                                id="btnResendOtp"
                                onClick={() => this.sentOtp("Resend")}
                              >
                                Resend OTP{" "}
                              </button> */}
                              <button
                                className="formButton formButtonBold mt-4"
                                id="btnOtpRegister"
                                onClick={() => this.saveIDA()}
                              >
                                Register
                              </button>
                              <input
                                type="button"
                                value="Processing..."
                                className="formButton formButtonBold mt-4"
                                id="btnProcessingNotIDA"
                                style={{ display: "none" }}
                              ></input>
                            </div>
                            {/* :
                        <div>
                          <input type="button" value="Register" className="formButton formButtonBold mt-4" id="btnRegister" name="btnRegister" tabIndex="60" onClick={() => this.saveIDA()} ></input>
                        </div>
                      } */}

                            {/* <div>
                          <input type="button" value="Register" className="formButton formButtonBold mt-4" id="btnRegister" name="btnRegister" tabIndex="60" onClick={() => this.saveIDA()} ></input>
                        </div>
                      <input type="button" value="Processing..." className="formButton formButtonBold mt-4" id="btnProcessing" style={{ display: 'none' }}  ></input> */}

                            <input
                              type="button"
                              value="Saved Successfully"
                              className="formButton formButtonBold mt-4"
                              id="txtSuccess"
                              style={{ display: "none" }}
                            ></input>
                            <input
                              type="button"
                              value="Something went wrong while saving"
                              className="formButton formButtonBold mt-4"
                              id="txtFailed"
                              style={{ display: "none" }}
                            ></input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <ErrorPage></ErrorPage>
                )}
              </div>
            </div>
          </section>
        </Suspense>
        <Suspense fallback={<div></div>}>
          <section>
            <Footer />
          </section>
        </Suspense>
      </div>
    );
  }
}
