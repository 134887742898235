import axios from "axios";
export function sendMailOrder(postData) {
   
    return axios.post('https://2yhrka7axi.execute-api.us-west-2.amazonaws.com/prod/contactus', postData)
    // return fetch(awsLambdUrl+'/sendmail?subject='+subject+'&mail_text='+mail_text+'&to='+to, {
    //method: 'POST',
}
//! Image Upload URL
const imageUploadURL =
    "https://75vfqs60v3.execute-api.ap-south-1.amazonaws.com/";
    export function UploadImage(image, path, bucket) {
        const url = `${imageUploadURL}newUpload?bucket=${bucket}&bucketPath=${path}`;
        return axios
          .post(url, image)
          .then((res) => res.data) // Return the response data directly
          .catch((err) => {
            console.error("Error uploading image:", err); // Log the error
            throw err; // Rethrow the error so the calling function can handle it
          });
      }
