import React, { Component } from "react";
import { apiCalling, awsAPIUrlcommon } from "../apiService";
import { ToastContainer, toast } from "react-toastify";

export default class WhatsappPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: window.sessionStorage.getItem("doctorNumber") || "",
    };
  }

  componentDidMount() {
    const doctorNumber = window.sessionStorage.getItem("doctorNumber") || "";
    this.setState({ phoneNumber: doctorNumber });
  }

  handleInputChange = (e) => {
    this.setState({ phoneNumber: e.target.value });
  };

  handleClose = () => {
    this.setState(
      {
        phoneNumber: "",
      },
      () => {
        this.props.onClose();
      }
    );
  };

  send = () => {
    const { phoneNumber } = this.state;

    // Validate phone number
    const regex = /^[0-9]{10,15}$/;
    if (!regex.test(phoneNumber)) {
      toast.error("Please enter a valid phone number.");
      return;
    }

    if (this.props.sendPdfUrl) {
      let WhatsappData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "sendWhatsAppMessage",
          fromPageType: "phDashboard",
          mobileNumber: phoneNumber,
          doctorId: window.sessionStorage.getItem("doctorDashBId"),
          siteId: window.sessionStorage.getItem("doctor-reportSiteId"),
          filePath: this.props.sendPdfUrl,
        }),
      };

      awsAPIUrlcommon(WhatsappData)
        .then((data) => {
          if (data.success === "1") {
            toast.success("Successfully sent.");
            setTimeout(() => {
              this.props.onClose();
            }, 2000);
          } else {
            toast.error("Failed to send message.");
          }
        })
        .catch(() => {
          toast.error("An error occurred while sending details.");
        });
    }
  };

  render() {
    const { isOpen } = this.props;
    const { phoneNumber } = this.state;

    if (!isOpen) return null;

    return (
      <div className="popup-overlay" style={{ display: "block" }}>
        <ToastContainer />
        <div
          className="modal-dialog"
          style={{ maxWidth: "400px", minHeight: "450px" }}
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-popup">
              <h5 className="modal-title">Send PDF</h5>
            </div>

            <div className="content p-4">
              <div className="row">
                <div className="col-12 mb-2">
                  <label className="label-text" htmlFor="phoneNumber">
                    Number
                  </label>
                  <input
                    type="text"
                    id="phoneNumber"
                    maxLength={15}
                    className="input-design"
                    value={phoneNumber}
                    name="phoneNumber"
                    onChange={this.handleInputChange}
                    required
                  />
                </div>
              </div>

              <div className="text-center mt-2">
                <button
                  type="submit"
                  className="sitesetng-svebtn"
                  onClick={this.send}
                >
                  Send
                </button>
                <button
                  type="button"
                  className="sitesetng-cancelbtn"
                  onClick={this.handleClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
