import React, { useEffect, useState, Suspense } from "react";
import Pagination from "react-js-pagination";
import { RiWhatsappFill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { apiCalling, awsAPIUrlcommon } from "../apiService";
import AWS from "aws-sdk";
import { method } from "lodash";
import * as XLSX from "xlsx";
import moment from "moment";
import $ from "jquery";
import { toast } from "react-toastify";

const DoctorPanelSearch = ({
  siteName,
  setSiteName = () => {},
  setSiteId = () => {},
  setDoctorName = () => {},
  setStartDate = () => {},
  setEndDate = () => {},
  setSiteWiseDataList = () => {},
  setIsChecked = () => {},
}) => {
  const [siteList, setSiteList] = useState([]);
  const [filterSiteList, setFilterSiteList] = useState([]);
  const [filterSiteFlag, setFilterSiteFlag] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSiteDetails();
  }, []);
  const getSiteDetails = () => {
    // $(".loader").show();
    setLoading(true);
    let postData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getAllSiteDetails",
      }),
    };
    apiCalling(postData)
      .then((data) => {
        if (data.success === "1") {
          setSiteList(data.siteData);
        } else {
          setSiteList([]);
        }
        // $(".loader").hide();
        setLoading(false);
      })
      .catch((error) => {
        // $(".loader").hide();
        setLoading(false);
        toast.error("An error occurred while Getting Details");
      });
  };

  const handleInputChange = (event) => {
    setDoctorName("");
    setStartDate("");
    setEndDate("");
    setSiteWiseDataList([]);
    setIsChecked(false);
    const inputValue = event.target.value.toLowerCase();
    setSiteName(inputValue);
    const filteredResult = siteList?.filter((item) => {
      return item.siteName.toLowerCase().includes(inputValue);
    });
    if (filteredResult.length > 0) {
      window.sessionStorage.setItem("docDashSiteId", filteredResult[0].siteId);
    }
    setFilterSiteList(filteredResult);
    setFilterSiteFlag(true);
  };

  const selectSiteData = (site) => {
    setSiteId(site.siteId);
    setSiteName(site.siteName);
    setFilterSiteFlag(false);
  };
  return (
    <>
      {loading && <div className="loader d-block"></div>}
      <div className="col-md-6 dctrpanel-headinput mb-1 mb-md-0 pl-0">
        <input
          value={siteName}
          onChange={handleInputChange}
          type="text"
          placeholder="Site List..."
          className="hspInfofieldinput w-100"
        />
        {filterSiteFlag && siteName && (
          <ul
            className="input-design-journal"
            style={{ width: "94%", left: "0" }}
          >
            {filterSiteList.map((data, index) => (
              <li className="py-0" key={data.siteId}>
                <button
                  className="btn input-design-btn pl-1"
                  onClick={() => selectSiteData(data)}
                >
                  {data.siteName}
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default DoctorPanelSearch;
